import { tour } from "./tour";

export const drawings = {
  // general tour
  ...tour,
  moduleElement: "Drawings",
  exportButtonTour: "Download",
  reviewAndPublishButtonTour: "Review and Publish",
  // ADD - guide me steps
  step1_addDrawing_title: "1. Add",
  step1_addDrawing_text: "Click on the + icon to add a new drawing.",
  step2_addDrawing_title: "2. Add file",
  step2_addDrawing_text:
    "Attach the file, or drag and drop it inside the dotted box.",
  step3_addDrawing_title: "3. Name of the set",
  step3_addDrawing_text: "Either select or enter the name of the set.",
  step4_addDrawing_title: "4. Date of the drawing",
  step4_addDrawing_text: "Select the date of the drawing.",
  step5_addDrawing_title: "5. Reception date of the drawing",
  step5_addDrawing_text: "Select the reception date of the drawing.",
  step6_addDrawing_title: "6. Save",
  step6_addDrawing_text: "Click here to save the drawing.",
  step_final_addDrawing: "You have learned how to add a drawing.",
  // REVIEW & PUBLISH - guide me steps
  step1_reviewAndPublishDrawing_title: "1. More options",
  step1_reviewAndPublishDrawing_text: "Click here to see more options.",
  step2_reviewAndPublishDrawing_title: "2. Review drawings.",
  step2_reviewAndPublishDrawing_text:
    "Click here to open the section of drawings pending to review.",
  step3_reviewAndPublishDrawing_title: "3. Approve",
  step3_reviewAndPublishDrawing_text:
    "Click here to see the information of the drawing.",
  step4_reviewAndPublishDrawing_title: "4. Modify information",
  step4_reviewAndPublishDrawing_text: "Make the changes you need",
  step5_reviewAndPublishDrawing_title: "5. Approve",
  step5_reviewAndPublishDrawing_text: "Click here to approve the drawing.",
  step6_reviewAndPublishDrawing_title: "6. Tab to Publish",
  step6_reviewAndPublishDrawing_text:
    "Click here to see the list of drawings pending to be published.",
  step7_reviewAndPublishDrawing_title: "7. Select the drawings",
  step7_reviewAndPublishDrawing_text:
    "Select the drawings you want to publish.",
  step8_reviewAndPublishDrawing_title: "8. Publish",
  step8_reviewAndPublishDrawing_text:
    "Click here to publish the selected drawings.",
  step_final_reviewAndPublishDrawing:
    "You have learned how to both review and publish a drawing.",
  // VIEW - guide me steps
  step1_viewDrawing_title: "1. View",
  step1_viewDrawing_text: "Click here to view the drawing.",
  step2_viewDrawing_title: "2. Revision",
  step2_viewDrawing_text: "Select the number of review.",
  step3_viewDrawing_title: "3. Drawing",
  step3_viewDrawing_text: "Select if you need to change the drawing.",
  step4_viewDrawing_title: "4. Annotations",
  step4_viewDrawing_text: "Click here to add annotations to the drawing.",
  step5_viewDrawing_title: "5. Show/Hide annotations",
  step5_viewDrawing_text: "Click here to either show or hide annotations",
  step_final_viewDrawing: "You have learned how to view a drawing.",
  // EDIT - guide me steps
  step1_editDrawing_title: "1. Edit",
  step1_editDrawing_text: "Click here to edit a drawing.",
  step2_editDrawing_title: "2. Drawing's name",
  step2_editDrawing_text: "Edit drawing's name.",
  step3_editDrawing_title: "3. Name of the set",
  step3_editDrawing_text: "Either select or edit the name of the set.",
  step4_editDrawing_title: "4. Discipline",
  step4_editDrawing_text: "Select the discipline.",
  step5_editDrawing_title: "5. Privacy",
  step5_editDrawing_text:
    "Select if the drawing will be visible to everyone or not.",
  step6_editDrawing_title: "6. Privacy Level",
  step6_editDrawing_text:
    "If activated, access to certain companies and individuals will be restricted.",
  step7_editDrawing_title: "7. Save",
  step7_editDrawing_text: "Click here to save the changes.",
  step_final_editDrawing: "You have learned how to edit a drawing.",
  // RECYCLE - guide me steps
  step1_recycleDrawing_title: "1. More options",
  step1_recycleDrawing_text: "Click here to see more options.",
  step2_recycleDrawing_title: "2. Drawings Recycle Bin",
  step2_recycleDrawing_text:
    "Click here to visualize the drawings recycle bin.",
  step3_recycleDrawing_title: "3. Select drawings",
  step3_recycleDrawing_text: "Select the drawings you want to restore.",
  step4_recycleDrawing_title: "4. Restore",
  step4_recycleDrawing_text: "Click here to restore the selected drawings.",
  step_final_recycleDrawing: "You have learned how to restore a drawing.",
  // DOWNLOAD - guide me steps
  step1_exportDrawing_title: "1. More actions",
  step1_exportDrawing_text:
    "Click on the 3 dots to open a menu with more actions.",
  step2_exportDrawing_title: "2. Downloads",
  step2_exportDrawing_text: "Click here to download the drawing.",
  step3_exportDrawing_title: "3. Alternative download",
  step3_exportDrawing_text:
    "You can also place the cursor over the column #Rev to visualize the list of revisions.",
  step4_exportDrawing_title: "4. Download the revision",
  step4_exportDrawing_text: "Click to dowload any file you want to download.",
  step_final_exportDrawing: "You have learned how to download a drawing.",
  // SETTINGS - guide me steps
  step1_settingsDrawing_title: "1. More options",
  step1_settingsDrawing_text: "Click here to see more options.",
  step2_settingsDrawing_title: "2. Settings",
  step2_settingsDrawing_text: "Click here to open the settings modal.",
  step3_settingsDrawing_title: "3. Distribution list",
  step3_settingsDrawing_text:
    "Add or remove people who will have default access to the drawings module.",
  step4_settingsDrawing_title: "4. Disciplines",
  step4_settingsDrawing_text: "Click here to access to disciplines tab.",
  step5_settingsDrawing_title: "5. Add discipline",
  step5_settingsDrawing_text: "Enter the name of the discipline to be added.",
  step_final_settingsDrawing:
    "You have learned how to configure the module drawings.",

  addDrawing: "Add Drawing",
  reviewDrawings: "Review drawings",
  drawingsToCheck: "To Check",
  drawingsToPublish: "To Publish",
  discipline: "Discipline",
  disciplines: "Disciplines",
  addNewSet: "Add new set",
  setName: "Set name",
  drawingDate: "Drawing date",
  deletingDrawingMessage:
    "Are you sure you want to delete this drawing(s)? This action can't be undone",
  deleteDrawing: "Delete Drawing",
  drawingsDeletedMessage: "Drawings deleted successfully.",
  drawingsPublishedMessage: "Drawings published successfully.",
  drawingsRestoredMessage: "Drawings restored successfully.",
  recycleBin: "Drawings recycle bin",
  drawingName: "Drawing Name",
  deleteDrawingsMessage: "Are you sure you want to delete these drawings?",
  deleteLastRevision: "Delete last revision",
  revision: "Revision",
  noDrawingsCreated: "No drawings created",
  addDrawingsValidation:
    "If you want to add one, we invite you to click on the Add button to start the process",
  line: "Line",
  rectangle: "Rectangle",
  ellipse: "Ellipse",
  arrow: "Arrow",
  polyline: "Polyline",
  annotationPublishedMessage: "Annotations published successfully.",
  freetext: "Free Text",
  annotationAssignedMessage: "Annotation assigned successfully.",
  annotationLinkedMessage: "Annotation linked successfully.",
  approveDrawing: "Approve drawing",
  drawingId: "Drawing ID",
  approve: "Approve",
  publishAnnotations: "Publish annotations",
  deleteAnnotations: "Delete annotations",
  deleteAnnotationsContent: "Are you sure you want to delete ${1} annotations?",
  annotationDeletedMessage: "Annotations deleted successfully.",
  drawingsAddedTitle: "Drawings set added",
  drawingsAddedContent:
    "Drawings have been successfully added. We will notify you when they are uploaded to the system.",
  reviseDrawings: "Revise drawings",
  area: "Area",
  calibrate: "Calibrate",
  distance: "Distance",
  includeAnnotations: "Include annotations",
  excludeAnnotations: "Exclude annotations",
  editDrawing: "Edit drawing",
  addDiscipline: "Add discipline",
  editDrawingId: "Edit drawing ID",
  editedDrawingMessage: "Drawing updated successfully.",
  compareWith: "Compare with",
  selectDrawgingsToCompare: "Select drawings to compare",
  revisionsDeletedSuccessfully: "Revisions deleted successfully",
  links: "Links",
  showAnnotations: "Show annotations",
  hideAnnotations: "Hide annotations",
  measure: "Measure",
  generated: "Generated",
  comparisionGeneratedSuccessfully: "Comparison generated successfully",
  setCalibration: "Set calibration",
  calibrateDescription:
    "All of the measurements on this drawing will reference the calibration below.",
  calibrationNote: "Note: These measurements are not exact.",
  calibrateInstructions: "Select 2 points on the drawing to calibrate",
  drawingsMaxSize: "Tamaño máximo de archivos: 10GB (total)",
  fileSizeLimit: "Maximum file size: 10GB (total)",
  uploadingQtyDrawings: "Uploading {{qty}} drawings",
  uploadDrawings: "Upload drawings",
  comment: "Comment",
  commentDrawing: "Add comment to drawing",
  commentPlaceholder: "Write a comment about the drawing",
  drawingViewer: "Drawing Viewer",
  uploadedMessage:
    "Drawings uploaded successfully. Files will be processed and available soon.",
  dwgUploadedTitle: "Upload in progress",
  dwgUploadedMessage:
    "Your DWG files are being converted to PDF at this moment, they will be published shortly for editing.",
  dwgUploadedAction: "Continue Browsing",
};
