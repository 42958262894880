import { ContentCopy, MoreHoriz, PictureAsPdf } from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { TooltipIconButton } from "../../../shared";
import { useDuplicateDesignTemplateMutation } from "../../../../features/designTemplates";
import moment from "moment";

function RowActions({ row }) {
  const { t: tGeneral } = useTranslation("general");
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const [duplicate] = useDuplicateDesignTemplateMutation();

  const handleDuplicate = async () => {
    await duplicate(row.original.id);
    setAnchorEl(null);
  };

  return (
    <>
      <TooltipIconButton
        icon={<MoreHoriz />}
        color="#8E8E8E"
        labe={tGeneral("more")}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleDuplicate}>
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText>{tGeneral("duplicate")}</ListItemText>{" "}
        </MenuItem>
        <MenuItem onClick={() => navigate(`form/${row.original.id}`)}>
          <ListItemIcon>
            <PictureAsPdf />
          </ListItemIcon>
          <ListItemText>{tGeneral("edit")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

function DesignTemplatesTable({ data, isLoading }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const columns = [
    {
      accessorKey: "title",
      header: tGeneral("name"),
      muiTableBodyCellProps: {
        align: "left",
      },
      muiTableHeadCellProps: {
        align: "left",
      },
      size: 100,
    },
    {
      accessorKey: "dateUpdated",
      header: tGeneral("updated"),
      size: 50,
      Cell: ({ cell, row }) => (
        <Typography>
          {moment(
            cell.getValue() ? cell.getValue() : row.original.dateCreated,
          ).format("DD/MM/YYYY")}
        </Typography>
      ),
    },
    {
      accessorKey: "updatedBy",
      header: tGeneral("updatedBy"),
      Cell: ({ cell, row }) =>
        cell.getValue() ? (
          <Typography>{`${cell.getValue()?.firstName} ${
            cell.getValue()?.lastName
          }`}</Typography>
        ) : (
          <Typography>{`${row.original.createdBy?.firstName} ${row.original.createdBy?.lastName}`}</Typography>
        ),
      size: 50,
    },
    {
      accessorKey: "items",
      header: tGeneral("items"),
      size: 20,
    },
    {
      accessorKey: "disciplines",
      header: tDrawings("disciplines"),
      size: 50,
    },
  ];
  return (
    <MaterialReactTable
      state={{
        isLoading,
      }}
      columns={columns}
      data={data}
      enableHiding={false}
      enableStickyHeader
      enableDensityToggle={false}
      enableColumnActions={false}
      enableFilters={false}
      positionActionsColumn="last"
      layoutMode="grid"
      icons={{ SortIcon: null }}
      defaultColumn={{
        size: 0,
        minSize: 20,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      }}
      enableRowActions
      renderRowActions={({ row }) => <RowActions row={row} />}
      displayColumnDefOptions={{
        "mrt-row-select": {
          size: 0,
        },
        "mrt-row-actions": {
          size: 50,
          header: tGeneral("actions"),
        },
        "mrt-row-expand": {
          size: 0,
        },
      }}
    />
  );
}

export { DesignTemplatesTable };
