import { tour } from "./tour";

export const drawings = {
  // general tour
  ...tour,
  moduleElement: "Desenhos",
  exportButtonTour: "Download",
  reviewAndPublishButtonTour: "Revisar e Publicar",
  // ADD - guide me steps
  step1_addDrawing_title: "1. Adicionar",
  step1_addDrawing_text: "Clique no ícone + para adicionar um novo desenho.",
  step2_addDrawing_title: "2. Adicionar arquivo",
  step2_addDrawing_text:
    "Anexe o arquivo ou arraste e solte-o na caixa pontilhada.",
  step3_addDrawing_title: "3. Nome do conjunto",
  step3_addDrawing_text: "Selecione ou digite o nome do conjunto.",
  step4_addDrawing_title: "4. Data do desenho",
  step4_addDrawing_text: "Selecione a data do desenho.",
  step5_addDrawing_title: "5. Data de recebimento do desenho",
  step5_addDrawing_text: "Selecione a data de recebimento do desenho.",
  step6_addDrawing_title: "6. Salvar",
  step6_addDrawing_text: "Clique aqui para salvar o desenho.",
  step_final_addDrawing: "Você aprendeu como adicionar um desenho.",
  // REVIEW & PUBLISH - guide me steps
  step1_reviewAndPublishDrawing_title: "1. Mais opções",
  step1_reviewAndPublishDrawing_text: "Clique aqui para ver mais opções.",
  step2_reviewAndPublishDrawing_title: "2. Revisar desenhos",
  step2_reviewAndPublishDrawing_text:
    "Clique aqui para abrir a seção de desenhos pendentes de revisão.",
  step3_reviewAndPublishDrawing_title: "3. Aprovar",
  step3_reviewAndPublishDrawing_text:
    "Clique aqui para ver as informações do desenho.",
  step4_reviewAndPublishDrawing_title: "4. Modificar informações",
  step4_reviewAndPublishDrawing_text: "Faça as alterações necessárias",
  step5_reviewAndPublishDrawing_title: "5. Aprovar",
  step5_reviewAndPublishDrawing_text: "Clique aqui para aprovar o desenho.",
  step6_reviewAndPublishDrawing_title: "6. Guia para Publicar",
  step6_reviewAndPublishDrawing_text:
    "Clique aqui para ver a lista de desenhos pendentes de publicação.",
  step7_reviewAndPublishDrawing_title: "7. Selecionar os desenhos",
  step7_reviewAndPublishDrawing_text:
    "Selecione os desenhos que você deseja publicar.",
  step8_reviewAndPublishDrawing_title: "8. Publicar",
  step8_reviewAndPublishDrawing_text:
    "Clique aqui para publicar os desenhos selecionados.",
  step_final_reviewAndPublishDrawing:
    "Você aprendeu como revisar e publicar um desenho.",
  // VIEW - guide me steps
  step1_viewDrawing_title: "1. Visualizar",
  step1_viewDrawing_text: "Clique aqui para visualizar o desenho.",
  step2_viewDrawing_title: "2. Revisão",
  step2_viewDrawing_text: "Selecione o número da revisão.",
  step3_viewDrawing_title: "3. Desenho",
  step3_viewDrawing_text: "Selecione se você precisa alterar o desenho.",
  step4_viewDrawing_title: "4. Anotações",
  step4_viewDrawing_text: "Clique aqui para adicionar anotações ao desenho.",
  step5_viewDrawing_title: "5. Mostrar/Ocultar anotações",
  step5_viewDrawing_text: "Clique aqui para mostrar ou ocultar as anotações",
  step_final_viewDrawing: "Você aprendeu como visualizar um desenho.",
  // EDIT - guide me steps
  step1_editDrawing_title: "1. Editar",
  step1_editDrawing_text: "Clique aqui para editar um desenho.",
  step2_editDrawing_title: "2. Nome do desenho",
  step2_editDrawing_text: "Edite o nome do desenho.",
  step3_editDrawing_title: "3. Nome do conjunto",
  step3_editDrawing_text: "Selecione ou edite o nome do conjunto.",
  step4_editDrawing_title: "4. Disciplina",
  step4_editDrawing_text: "Selecione a disciplina.",
  step5_editDrawing_title: "5. Privacidade",
  step5_editDrawing_text:
    "Selecione se o desenho será visível para todos ou não.",
  step6_editDrawing_title: "6. Nível de Privacidade",
  step6_editDrawing_text:
    "Se ativado, o acesso a determinadas empresas e indivíduos será restrito.",
  step7_editDrawing_title: "7. Salvar",
  step7_editDrawing_text: "Clique aqui para salvar as alterações.",
  step_final_editDrawing: "Você aprendeu como editar um desenho.",
  // RECYCLE - guide me steps
  step1_recycleDrawing_title: "1. Mais opções",
  step1_recycleDrawing_text: "Clique aqui para ver mais opções.",
  step2_recycleDrawing_title: "2. Lixeira de Desenhos",
  step2_recycleDrawing_text:
    "Clique aqui para visualizar a lixeira de desenhos.",
  step3_recycleDrawing_title: "3. Selecionar desenhos",
  step3_recycleDrawing_text: "Selecione os desenhos que você deseja restaurar.",
  step4_recycleDrawing_title: "4. Restaurar",
  step4_recycleDrawing_text:
    "Clique aqui para restaurar os desenhos selecionados.",
  step_final_recycleDrawing: "Você aprendeu como restaurar um desenho.",
  // DOWNLOAD - guide me steps
  step1_exportDrawing_title: "1. Mais ações",
  step1_exportDrawing_text:
    "Clique nos 3 pontos para abrir um menu com mais ações.",
  step2_exportDrawing_title: "2. Downloads",
  step2_exportDrawing_text: "Clique aqui para baixar o desenho.",
  step3_exportDrawing_title: "3. Download alternativo",
  step3_exportDrawing_text:
    "Você também pode colocar o cursor sobre a coluna #Rev para visualizar a lista de revisões.",
  step4_exportDrawing_title: "4. Baixar a revisão",
  step4_exportDrawing_text:
    "Clique para baixar qualquer arquivo que você deseja baixar.",
  step_final_exportDrawing: "Você aprendeu como baixar um desenho.",
  // SETTINGS - guide me steps
  step1_settingsDrawing_title: "1. Mais opções",
  step1_settingsDrawing_text: "Clique aqui para ver mais opções.",
  step2_settingsDrawing_title: "2. Configurações",
  step2_settingsDrawing_text:
    "Clique aqui para abrir o modal de configurações.",
  step3_settingsDrawing_title: "3. Lista de distribuição",
  step3_settingsDrawing_text:
    "Adicione ou remova pessoas que terão acesso padrão ao módulo de desenhos.",
  step4_settingsDrawing_title: "4. Disciplinas",
  step4_settingsDrawing_text: "Clique aqui para acessar a guia de disciplinas.",
  step5_settingsDrawing_title: "5. Adicionar disciplina",
  step5_settingsDrawing_text: "Digite o nome da disciplina a ser adicionada.",
  step_final_settingsDrawing:
    "Você aprendeu como configurar o módulo de desenhos.",

  addDrawing: "Adicionar Desenho",
  reviewDrawings: "Revisar desenhos",
  drawingsToCheck: "Para Verificar",
  drawingsToPublish: "Para Publicar",
  discipline: "Disciplina",
  disciplines: "Disciplinas",
  addNewSet: "Adicionar novo conjunto",
  setName: "Nome do conjunto",
  drawingDate: "Data do desenho",
  deletingDrawingMessage:
    "Tem certeza de que deseja excluir este(s) desenho(s)? Essa ação não pode ser desfeita",
  deleteDrawing: "Excluir Desenho",
  drawingsDeletedMessage: "Desenhos excluídos com sucesso.",
  drawingsPublishedMessage: "Desenhos publicados com sucesso.",
  drawingsRestoredMessage: "Desenhos restaurados com sucesso.",
  recycleBin: "Lixeira de Desenhos",
  drawingName: "Nome do Desenho",
  deleteDrawingsMessage: "Tem certeza de que deseja excluir estes desenhos?",
  deleteLastRevision: "Excluir última revisão",
  revision: "Revisão",
  noDrawingsCreated: "Nenhum desenho criado",
  addDrawingsValidation:
    "Se você deseja adicionar um, convidamos você a clicar no botão Adicionar para iniciar o processo",
  line: "Linha",
  rectangle: "Retângulo",
  ellipse: "Elipse",
  arrow: "Seta",
  polyline: "Polilinha",
  annotationPublishedMessage: "Anotações publicadas com sucesso.",
  freetext: "Texto Livre",
  annotationAssignedMessage: "Anotação atribuída com sucesso.",
  annotationLinkedMessage: "Anotação vinculada com sucesso.",
  approveDrawing: "Aprovar desenho",
  drawingId: "ID do Desenho",
  approve: "Aprovar",
  publishAnnotations: "Publicar anotações",
  deleteAnnotations: "Excluir anotações",
  deleteAnnotationsContent: "Tem certeza de que deseja excluir ${1} anotações?",
  annotationDeletedMessage: "Anotações excluídas com sucesso.",
  drawingsAddedTitle: "Conjunto de desenhos adicionado",
  drawingsAddedContent:
    "Os desenhos foram adicionados com sucesso. Notificaremos você quando eles forem carregados no sistema.",
  reviseDrawings: "Revisar desenhos",
  area: "Área",
  calibrate: "Calibrar",
  distance: "Distância",
  includeAnnotations: "Incluir anotações",
  excludeAnnotations: "Excluir anotações",
  editDrawing: "Editar desenho",
  addDiscipline: "Adicionar disciplina",
  editDrawingId: "Editar ID do desenho",
  editedDrawingMessage: "Desenho atualizado com sucesso.",
  compareWith: "Comparar com",
  selectDrawgingsToCompare: "Selecionar desenhos para comparar",
  revisionsDeletedSuccessfully: "Revisões excluídas com sucesso",
  links: "Links",
  showAnnotations: "Mostrar anotações",
  hideAnnotations: "Esconder anotações",
  measure: "Medir",
  generated: "Gerado",
  comparisionGeneratedSuccessfully: "Comparação gerada com sucesso",
  setCalibration: "Ajustar calibração",
  calibrateDescription:
    "Todas as medidas neste plano referem-se à calibração indicada abaixo.",
  calibrationNote: "Nota: Estas medidas não são exatas.",
  calibrateInstructions: "Selecione 2 pontos no plano para calibrar",
  fileSizeLimit: "Tamanho máximo de arquivos: 10GB (total)",
  ploadingQtyDrawings: "Carregando {{qty}} desenhos",
  uploadDrawings: "Carregar desenhos",
  comment: "Comentar",
  commentDrawing: "Adicionar comentário ao desenho",
  commentPlaceholder: "Escreva um comentário sobre o desenho",
  drawingViewer: "Visualizador de Desenhos",
  uploadedMessage:
    "Os planos foram carregados com sucesso. Os arquivos serão processados e estarão disponíveis em breve.",
  dwgUploadedTitle: "Upload em Progresso",
  dwgUploadedMessage:
    "Seus arquivos DWG estão sendo convertidos para PDF neste momento, eles serão publicados em breve para edição",
  dwgUploadedAction: "Continuar Navegando",
};
