const templates = {
  createTemplates: "テンプレートを作成する",
  createTemplate: "テンプレートを作成する",
  templateInstructions: "始めるために次の手順を完了してください",
  templateTitle: "テンプレートのタイトル",
  selectDisciplines: "専門分野を追加",
  saveTemplate: "テンプレートを保存する",
  weighting: "重み付け",
  checklist: "チェックリスト",
  designTemplates: "デザインテンプレート",
  addSubitem: "サブ項目を追加する",
  designDescription: "デザイン部門を追跡するためのテンプレートを作成する",
  selectModule: "テンプレートを作成するためにモジュールを選択する",
  templates: "テンプレート",
};

export { templates };
