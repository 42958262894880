const templates = {
  createTemplates: "Crear plantillas",
  createTemplate: "Crear plantilla",
  templateInstructions: "Completa los siguientes pasos para comenzar",
  templateTitle: "Título de plantilla",
  selectDisciplines: "Agrega disciplinas",
  saveTemplate: "Guardar plantilla",
  weighting: "Ponderación",
  checklist: "Checklist",
  designTemplates: "Plantillas de diseño",
  addSubitem: "Agregar sublistado",
  designDescription:
    "Crea plantillas para llevar un seguimiento del departamento de diseño",
  selectModule: "Selecciona un módulo para crear tu plantilla",
  templates: "Plantillas",
};

export { templates };
