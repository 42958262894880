// @ts-check
/* eslint react/destructuring-assignment: 0 */
/* eslint react/prop-types: 0 */

import AddBoxIcon from "@mui/icons-material/AddBox";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ScienceIcon from "@mui/icons-material/Science";
import { Avatar, AvatarGroup, Box, Checkbox, Tooltip } from "@mui/material";
import { L10n, loadCldr, setCulture } from "@syncfusion/ej2-base";
import { DayMarkers, GanttComponent, Inject } from "@syncfusion/ej2-react-gantt";
// eslint-disable-next-line no-unused-vars
import React, { forwardRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import {
  buildColor,
  computeSplitterSettings,
  computeTimelineSettings,
  confirm,
  isFirst,
  isLast,
  queryTaskbarInfo,
  renderCurrency,
  renderDate,
  renderPercent,
  rowDataBound,
} from "../../../utils/projectSchedule";
import Activity from "./Menus/Activity";
import Group from "./Menus/Group";
import Milestone from "./Menus/Milestone";

const Gantt = forwardRef((props, ref) => {
  const { i18n, t: tProjectSchedule } = useTranslation("projectSchedule");

  const { language } = i18n;

  const folder = ((language) => {
    switch (language) {
      case "en":
        return "en-US-POSIX";
      case "es":
        return "es-MX";
      case "ja":
        return "ja";
      case "ko":
        return "ko";
      case "pt":
        return "pt-PT";
      case "zh":
        return "zh-Hans";
      case "off":
        return "es-MX";
      default:
        return "en-US-POSIX";
    }
  })(language);

  const locale = ((language) => {
    switch (language) {
      case "en":
        return "en-US-POSIX";
      case "es":
        return "es-MX";
      case "ja":
        return "ja";
      case "ko":
        return "ko";
      case "pt":
        return "pt-PT";
      case "zh":
        return "zh-Hans";
      case "off":
        return "es-MX";
      default:
        return "en-US-POSIX";
    }
  })(language);

  setCulture(locale);

  L10n.load({
    [locale]: {
      gantt: {
        baselineEndDate: tProjectSchedule("Real Finish Date"),
        baselineStartDate: tProjectSchedule("Real Start Date"),
        duration: tProjectSchedule("Duration"),
        endDate: tProjectSchedule("Scheduled End Date"),
        progress: tProjectSchedule("Progress"),
        startDate: tProjectSchedule("Scheduled Start Date"),
      },
      grid: {
        EmptyRecord: tProjectSchedule("No records to display"),
      },
    },
  });

  // eslint-disable-next-line
  const gregorian = require(`cldr-data/main/${folder}/ca-gregorian.json`);
  // eslint-disable-next-line
  const numbers = require(`cldr-data/main/${folder}/numbers.json`);
  // eslint-disable-next-line
  const timeZoneNames = require(`cldr-data/main/${folder}/timeZoneNames.json`);

  // eslint-disable-next-line
  const numberingSystems = require("cldr-data/supplemental/numberingSystems.json");
  // eslint-disable-next-line
  const weekData = require("cldr-data/supplemental/weekData.json");

  loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);

  const {
    // @ts-ignore
    client,
    // @ts-ignore
    columns,
    // @ts-ignore
    dateFormat,
    // @ts-ignore
    fullscreenMode,
    // @ts-ignore
    setActivityObject,
    // @ts-ignore
    setActivityOpen,
    // @ts-ignore
    setDataSource,
    // @ts-ignore
    setGroupObject,
    // @ts-ignore
    setGroupOpen,
    // @ts-ignore
    setMilestoneOpen,
    // @ts-ignore
    setMilestoneObject,
    // @ts-ignore
    setRoadblockOpen,
    // @ts-ignore
    setRoadblockObject,
    // @ts-ignore
    timelineViewMode,
    // @ts-ignore
    view,
  } = props;

  const move = async (object, direction) => {
    try {
      Swal.showLoading();
      const response = await client.request({
        data: {
          object,
          direction,
        },
        method: "POST",
        url: "/move",
      });
      setDataSource(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    Swal.close();
  };

  const headerTemplate = useCallback((args) => {
    // @ts-ignore
    const dataSource = ref?.current.dataSource ?? [];
    switch (args.field) {
      case "title": {
        return (
          <Box
            sx={{
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                flex: 1,
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {tProjectSchedule("Activities")}
              <br />
              &nbsp;
            </Box>
            <Box
              onClick={() => {
                setGroupObject({});
                setGroupOpen(true);
              }}
              sx={{
                cursor: "pointer",
                marginTop: "2.5px",
              }}
            >
              <AddBoxIcon color="primary" />
            </Box>
          </Box>
        );
      }
      case "assignees": {
        return (
          <div>
            {tProjectSchedule("Assignees")}
            <br />
            &nbsp;
          </div>
        );
      }
      case "progress": {
        const progresses = dataSource.map((item) => item.progress);
        const total = progresses.reduce((a, b) => a + b, 0);
        const count = progresses.length;
        const progress = count > 0 ? total / count : 0;
        return (
          <div>
            {tProjectSchedule("Progress")}
            <br />
            {renderPercent(progress)}
          </div>
        );
      }
      case "projectCostBudget": {
        const projectCostBudget = dataSource.reduce((a, b) => a + b.projectCostBudget, 0);
        return (
          <div>
            {tProjectSchedule("Budget")}
            <br />
            {renderCurrency(projectCostBudget)}
          </div>
        );
      }
      case "projectCostExpenses": {
        const projectCostExpenses = dataSource.reduce((a, b) => a + b.projectCostExpenses, 0);
        return (
          <div>
            {tProjectSchedule("Expenses")}
            <br />
            {renderCurrency(projectCostExpenses)}
          </div>
        );
      }
      case "projectCostDifference": {
        const projectCostDifference = dataSource.reduce((a, b) => a + b.projectCostDifference, 0);
        return (
          <div>
            {tProjectSchedule("Difference")}
            <br />
            <div
              style={{
                color: buildColor(projectCostDifference),
                fontWeight: "bold",
              }}
            >
              {renderCurrency(projectCostDifference)}
            </div>
          </div>
        );
      }
      case "scheduledTimesDuration": {
        const scheduledTimesDuration = dataSource.reduce((a, b) => a + b.scheduledTimesDuration, 0);
        return (
          <div>
            {tProjectSchedule("Scheduled Duration")}
            <br />
            {scheduledTimesDuration}d
          </div>
        );
      }
      case "scheduledTimesStart": {
        const dates = dataSource.map((item) => item.scheduledTimesStart);
        dates.sort();
        const scheduledTimesStart = dates.reduce((a, b) => (a < b ? a : b), dates[0]);
        return (
          <div>
            {tProjectSchedule("Scheduled Start")}
            <br />
            {renderDate(scheduledTimesStart, dateFormat)}
          </div>
        );
      }
      case "scheduledTimesEnd": {
        const dates = dataSource.map((item) => item.scheduledTimesEnd);
        dates.sort();
        const scheduledTimesEnd = dates.reduce((a, b) => (a > b ? a : b), dates[0]);
        return (
          <div>
            {tProjectSchedule("Scheduled End")}
            <br />
            {renderDate(scheduledTimesEnd, dateFormat)}
          </div>
        );
      }
      case "realTimesDuration": {
        const scheduledTimesDuration = dataSource.reduce((a, b) => a + b.scheduledTimesDuration, 0);
        const realTimesDuration = dataSource.reduce((a, b) => a + b.realTimesDuration, 0);
        return (
          <div>
            {tProjectSchedule("Real Duration")}
            <br />
            <div
              style={{
                color: buildColor(scheduledTimesDuration - realTimesDuration),
                fontWeight: "bold",
              }}
            >
              {realTimesDuration}d
            </div>
          </div>
        );
      }
      case "realTimesStart": {
        const dates = dataSource.map((item) => item.realTimesStart);
        dates.sort();
        const realTimesStart = dates.reduce((a, b) => (a < b ? a : b), dates[0]);
        return (
          <div>
            {tProjectSchedule("Real Start")}
            <br />
            {renderDate(realTimesStart, dateFormat)}
          </div>
        );
      }
      case "realTimesEnd": {
        const dates = dataSource.map((item) => item.realTimesEnd);
        dates.sort();
        const realTimesEnd = dates.reduce((a, b) => (a > b ? a : b), dates[0]);
        return (
          <div>
            {tProjectSchedule("Real Finish")}
            <br />
            {renderDate(realTimesEnd, dateFormat)}
          </div>
        );
      }
      default:
        break;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFormat]);

  const template = useCallback(
    function template(field) {
      return function template(args) {
        if (args.taskData.type === "milestone") {
          args.taskData.dateFormat = dateFormat;
        }
        switch (field) {
          case "title":
            if (args.taskData.type === "group") {
              return (
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "space-between",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    onClick={() => {
                      setGroupObject(args.taskData);
                      setGroupOpen(true);
                    }}
                    sx={{
                      cursor: "pointer",
                      flex: 1,
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <Tooltip title={args.taskData.title}>{args.taskData.title}</Tooltip>
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      gap: "1px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <ArrowDropUpIcon
                      fontSize="small"
                      onClick={() => move(args.taskData, "up")}
                      sx={{
                        cursor: "pointer",
                        // @ts-ignore
                        opacity: isFirst(ref?.current.dataSource ?? [], args.taskData.id) ? 0.1 : 1.0,
                      }}
                    />
                    <ArrowDropDownIcon
                      fontSize="small"
                      onClick={() => move(args.taskData, "down")}
                      sx={{
                        cursor: "pointer",
                        // @ts-ignore
                        opacity: isLast(ref?.current.dataSource ?? [], args.taskData.id) ? 0.1 : 1.0,
                      }}
                    />
                    <Group
                      handleCreateGroup={() => {
                        setGroupObject({ idGroup: args.taskData.id });
                        setGroupOpen(true);
                      }}
                      handleCreateActivity={() => {
                        setActivityObject({ idGroup: args.taskData.id });
                        setActivityOpen(true);
                      }}
                      handleCreateMilestone={() => {
                        setMilestoneObject({ idGroup: args.taskData.id });
                        setMilestoneOpen(true);
                      }}
                      handleDeleteGroup={async () => {
                        const isConfirm = await confirm(
                          tProjectSchedule("Are you sure?"),
                          `${tProjectSchedule("Are you sure you want to delete the group")} ${args.taskData.title}?`,
                          tProjectSchedule("Yes"),
                          tProjectSchedule("No"),
                        );
                        if (isConfirm) {
                          try {
                            const response = await client.request({
                              method: "DELETE",
                              url: `/groups/${args.taskData.id}`,
                            });
                            setDataSource(response.data);
                            const Toast = Swal.mixin({
                              position: "center",
                              showConfirmButton: false,
                              timer: 1500,
                              timerProgressBar: true,
                              toast: true,
                            });
                            Toast.fire({
                              icon: "success",
                              title: tProjectSchedule("Success"),
                            });
                          } catch (error) {
                            const Toast = Swal.mixin({
                              position: "center",
                              showConfirmButton: false,
                              timer: 1500,
                              timerProgressBar: true,
                              toast: true,
                            });
                            Toast.fire({
                              icon: "error",
                              title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
                            });
                            // eslint-disable-next-line no-console
                            console.error(error);
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>
              );
            }
            if (args.taskData.type === "activity") {
              const numerator = args.taskData.roadblocks.filter((roadblock) => roadblock.status !== "close").length;
              const denomintor = args.taskData.roadblocks.filter((roadblock) => roadblock.status === "close").length;
              return (
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "space-between",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    onClick={() => {
                      setActivityObject(args.taskData);
                      setActivityOpen(true);
                    }}
                    sx={{
                      cursor: "pointer",
                      flex: 1,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <Tooltip title={args.taskData.title}>{args.taskData.title}</Tooltip>
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      gap: "1px",
                      justifyContent: "flex-end",
                    }}
                  >
                    {numerator === 0 ? (
                      <ScienceIcon color="disabled" fontSize="small" />
                    ) : (
                      <>
                        <small>
                          {numerator}/{denomintor}
                        </small>
                        <ScienceIcon color="error" fontSize="small" />
                      </>
                    )}
                    <ArrowDropUpIcon
                      fontSize="small"
                      onClick={() => move(args.taskData, "up")}
                      sx={{
                        cursor: "pointer",
                        // @ts-ignore
                        opacity: isFirst(ref?.current.dataSource ?? [], args.taskData.id) ? 0.1 : 1.0,
                      }}
                    />
                    <ArrowDropDownIcon
                      fontSize="small"
                      onClick={() => move(args.taskData, "down")}
                      sx={{
                        cursor: "pointer",
                        // @ts-ignore
                        opacity: isLast(ref?.current.dataSource ?? [], args.taskData.id) ? 0.1 : 1.0,
                      }}
                    />
                    <Activity
                      handleCreateRoadblock={() => {
                        setRoadblockObject({
                          activity: args.taskData,
                          idActivity: args.taskData.id,
                          dateFormat,
                        });
                        setRoadblockOpen(true);
                      }}
                      handleDeleteActivity={async () => {
                        const isConfirm = await confirm(
                          tProjectSchedule("Are you sure?"),
                          `${tProjectSchedule("Are you sure you want to delete the activity")} ${args.taskData.title}?`,
                          tProjectSchedule("Yes"),
                          tProjectSchedule("No"),
                        );
                        if (isConfirm) {
                          try {
                            const response = await client.request({
                              method: "DELETE",
                              url: `/activities/${args.taskData.id}`,
                            });
                            setDataSource(response.data);
                            const Toast = Swal.mixin({
                              position: "center",
                              showConfirmButton: false,
                              timer: 1500,
                              timerProgressBar: true,
                              toast: true,
                            });
                            Toast.fire({
                              icon: "success",
                              title: tProjectSchedule("Success"),
                            });
                          } catch (error) {
                            const Toast = Swal.mixin({
                              position: "center",
                              showConfirmButton: false,
                              timer: 1500,
                              timerProgressBar: true,
                              toast: true,
                            });
                            Toast.fire({
                              icon: "error",
                              title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
                            });
                            // eslint-disable-next-line no-console
                            console.error(error);
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>
              );
            }
            if (args.taskData.type === "milestone") {
              return (
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "space-between",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    onClick={() => {
                      setMilestoneObject(args.taskData);
                      setMilestoneOpen(true);
                    }}
                    sx={{
                      cursor: "pointer",
                      flex: 1,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {args.taskData.title}
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      gap: "1px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <ArrowDropUpIcon
                      fontSize="small"
                      onClick={() => move(args.taskData, "up")}
                      sx={{
                        cursor: "pointer",
                        // @ts-ignore
                        opacity: isFirst(ref?.current.dataSource ?? [], args.taskData.id) ? 0.1 : 1.0,
                      }}
                    />
                    <ArrowDropDownIcon
                      fontSize="small"
                      onClick={() => move(args.taskData, "down")}
                      sx={{
                        cursor: "pointer",
                        // @ts-ignore
                        opacity: isLast(ref?.current.dataSource ?? [], args.taskData.id) ? 0.1 : 1.0,
                      }}
                    />
                    <Milestone
                      handleDeleteMilestone={async () => {
                        const isConfirm = await confirm(
                          tProjectSchedule("Are you sure?"),
                          `${tProjectSchedule("Are you sure you want to delete the milestone")} ${
                            args.taskData.title
                          }?`,
                          tProjectSchedule("Yes"),
                          tProjectSchedule("No"),
                        );
                        if (isConfirm) {
                          try {
                            const response = await client.request({
                              method: "DELETE",
                              url: `/milestones/${args.taskData.id}`,
                            });
                            setDataSource(response.data);
                            const Toast = Swal.mixin({
                              position: "center",
                              showConfirmButton: false,
                              timer: 1500,
                              timerProgressBar: true,
                              toast: true,
                            });
                            Toast.fire({
                              icon: "success",
                              title: tProjectSchedule("Success"),
                            });
                          } catch (error) {
                            const Toast = Swal.mixin({
                              position: "center",
                              showConfirmButton: false,
                              timer: 1500,
                              timerProgressBar: true,
                              toast: true,
                            });
                            Toast.fire({
                              icon: "error",
                              title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
                            });
                            // eslint-disable-next-line no-console
                            console.error(error);
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>
              );
            }
            break;
          case "assignees":
            if (args.taskData.type === "group") {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <AvatarGroup
                    max={3}
                    sx={{
                      "& .MuiAvatar-root": {
                        justifyContent: "center",
                        fontSize: 10,
                        height: 20,
                        width: 20,
                      },
                    }}
                  >
                    {args.taskData.assignees.map((assignee) => {
                      return (
                        <Avatar
                          key={assignee.id}
                          src={undefined}
                          sx={{
                            backgroundColor: assignee.backgroundColor,
                          }}
                        >
                          {assignee.initials}
                        </Avatar>
                      );
                    })}
                  </AvatarGroup>
                </Box>
              );
            }
            if (args.taskData.type === "activity") {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <AvatarGroup
                    max={3}
                    sx={{
                      "& .MuiAvatar-root": {
                        justifyContent: "center",
                        fontSize: 10,
                        height: 20,
                        width: 20,
                      },
                    }}
                  >
                    {args.taskData.assignees.map((assignee) => {
                      return (
                        <Avatar
                          key={assignee.id}
                          src={undefined}
                          sx={{
                            backgroundColor: assignee.backgroundColor,
                          }}
                        >
                          {assignee.initials}
                        </Avatar>
                      );
                    })}
                  </AvatarGroup>
                </Box>
              );
            }
            if (args.taskData.type === "milestone") {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <AvatarGroup
                    max={3}
                    sx={{
                      "& .MuiAvatar-root": {
                        justifyContent: "center",
                        fontSize: 10,
                        height: 20,
                        width: 20,
                      },
                    }}
                  >
                    {args.taskData.assignees.map((assignee) => {
                      return (
                        <Avatar
                          key={assignee.id}
                          src={undefined}
                          sx={{
                            backgroundColor: assignee.backgroundColor,
                          }}
                        >
                          {assignee.initials}
                        </Avatar>
                      );
                    })}
                  </AvatarGroup>
                </Box>
              );
            }
            break;
          case "progress":
            if (args.taskData.type === "group") {
              return renderPercent(args[args.column.field]);
            }
            if (args.taskData.type === "activity") {
              return renderPercent(args[args.column.field]);
            }
            if (args.taskData.type === "milestone") {
              return (
                <Checkbox
                  checked={args.taskData.bComplete}
                  onClick={async (event) => {
                    try {
                      const response = await client.request({
                        data: {
                          idGroup: args.taskData.idGroup,
                          title: args.taskData.title,
                          date: args.taskData.date,
                          metadata: args.taskData.metadata,
                          // @ts-ignore
                          bComplete: event.target.checked,
                        },
                        method: "PUT",
                        url: `/milestones/${args.taskData.id}`,
                      });
                      setDataSource(response.data);
                      const Toast = Swal.mixin({
                        position: "center",
                        showConfirmButton: false,
                        timer: 1500,
                        timerProgressBar: true,
                        toast: true,
                      });
                      Toast.fire({
                        icon: "success",
                        title: tProjectSchedule("Success"),
                      });
                    } catch (error) {
                      const Toast = Swal.mixin({
                        position: "center",
                        showConfirmButton: false,
                        timer: 1500,
                        timerProgressBar: true,
                        toast: true,
                      });
                      Toast.fire({
                        icon: "error",
                        title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
                      });
                      // eslint-disable-next-line no-console
                      console.error(error);
                    }
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 20,
                    },
                    padding: "0 5px",
                  }}
                />
              );
            }
            break;
          case "projectCostBudget":
            if (args.taskData.type === "group") {
              return (
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {renderCurrency(args[args.column.field])}
                </div>
              );
            }
            if (args.taskData.type === "activity") {
              return renderCurrency(args[args.column.field]);
            }
            if (args.taskData.type === "milestone") {
              return null;
            }
            break;
          case "projectCostExpenses":
            if (args.taskData.type === "group") {
              return (
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {renderCurrency(args[args.column.field])}
                </div>
              );
            }
            if (args.taskData.type === "activity") {
              return renderCurrency(args[args.column.field]);
            }
            if (args.taskData.type === "milestone") {
              return null;
            }
            break;
          case "projectCostDifference":
            if (args.taskData.type === "group") {
              return (
                <div
                  style={{
                    color: buildColor(args[args.column.field]),
                    fontWeight: "bold",
                  }}
                >
                  {renderCurrency(args[args.column.field])}
                </div>
              );
            }
            if (args.taskData.type === "activity") {
              return renderCurrency(args[args.column.field]);
            }
            if (args.taskData.type === "milestone") {
              return null;
            }
            break;
          case "scheduledTimesDuration":
            if (args.taskData.type === "group") {
              return (
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {args[args.column.field]}d
                </div>
              );
            }
            if (args.taskData.type === "activity") {
              return `${args[args.column.field]}d`;
            }
            if (args.taskData.type === "milestone") {
              return null;
            }
            break;
          case "scheduledTimesStart":
            if (args.taskData.type === "group") {
              return (
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {renderDate(args[args.column.field], dateFormat)}
                </div>
              );
            }
            if (args.taskData.type === "activity") {
              return renderDate(args[args.column.field], dateFormat);
            }
            if (args.taskData.type === "milestone") {
              return null;
            }
            break;
          case "scheduledTimesEnd":
            if (args.taskData.type === "group") {
              return (
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {renderDate(args[args.column.field], dateFormat)}
                </div>
              );
            }
            if (args.taskData.type === "activity") {
              return renderDate(args[args.column.field], dateFormat);
            }
            if (args.taskData.type === "milestone") {
              return null;
            }
            break;
          case "realTimesDuration":
            if (args.taskData.type === "group") {
              return (
                <div
                  style={{
                    color: buildColor(args.scheduledTimesDuration - args.realTimesDuration),
                    fontWeight: "bold",
                  }}
                >
                  {args[args.column.field]}d
                </div>
              );
            }
            if (args.taskData.type === "activity") {
              return `${args[args.column.field]}d`;
            }
            if (args.taskData.type === "milestone") {
              return null;
            }
            break;
          case "realTimesStart":
            if (args.taskData.type === "group") {
              return (
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {renderDate(args[args.column.field], dateFormat)}
                </div>
              );
            }
            if (args.taskData.type === "activity") {
              return renderDate(args[args.column.field], dateFormat);
            }
            if (args.taskData.type === "milestone") {
              return null;
            }
            break;
          case "realTimesEnd":
            if (args.taskData.type === "group") {
              return (
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {renderDate(args[args.column.field], dateFormat)}
                </div>
              );
            }
            if (args.taskData.type === "activity") {
              return renderDate(args[args.column.field], dateFormat);
            }
            if (args.taskData.type === "milestone") {
              return null;
            }
            break;
          default:
            break;
        }
        return null;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateFormat],
  );

  const handleDataBound = useCallback(
    (args) => {
      if (ref && ref.current) {
        ref.current.scrollToDate(new Date());
      }
    },
    [ref],
  );

  const settings = useMemo(function settings() {
    return {
      allowSelection: false,
      baselineColor: "red",
      columns: [
        {
          autoFit: true,
          field: "title",
          headerTemplate,
          isPrimaryKey: true,
          template: template("title"),
          visible: true,
          width: "500px",
        },
        {
          autoFit: true,
          field: "assignees",
          headerTemplate,
          template: template("assignees"),
          textAlign: "Center",
          visible: true,
        },
        {
          autoFit: true,
          field: "progress",
          headerTemplate,
          template: template("progress"),
          textAlign: "Right",
          visible: true,
        },
        {
          autoFit: true,
          field: "projectCostBudget",
          headerTemplate,
          template: template("projectCostBudget"),
          textAlign: "Right",
          visible: columns.includes("projectCost"),
        },
        {
          autoFit: true,
          field: "projectCostExpenses",
          headerTemplate,
          template: template("projectCostExpenses"),
          textAlign: "Right",
          visible: columns.includes("projectCost"),
        },
        {
          autoFit: true,
          field: "projectCostDifference",
          headerTemplate,
          template: template("projectCostDifference"),
          textAlign: "Right",
          visible: columns.includes("projectCost"),
        },
        {
          autoFit: true,
          field: "scheduledTimesDuration",
          headerTemplate,
          template: template("scheduledTimesDuration"),
          textAlign: "Right",
          visible: columns.includes("scheduledTimes"),
        },
        {
          autoFit: true,
          field: "scheduledTimesStart",
          headerTemplate,
          template: template("scheduledTimesStart"),
          textAlign: "Center",
          visible: columns.includes("scheduledTimes"),
        },
        {
          autoFit: true,
          field: "scheduledTimesEnd",
          headerTemplate,
          template: template("scheduledTimesEnd"),
          textAlign: "Center",
          visible: columns.includes("scheduledTimes"),
        },
        {
          autoFit: true,
          field: "realTimesDuration",
          headerTemplate,
          template: template("realTimesDuration"),
          textAlign: "Right",
          visible: columns.includes("realTimes"),
        },
        {
          autoFit: true,
          field: "realTimesStart",
          headerTemplate,
          template: template("realTimesStart"),
          textAlign: "Center",
          visible: columns.includes("realTimes"),
        },
        {
          autoFit: true,
          field: "realTimesEnd",
          headerTemplate,
          template: template("realTimesEnd"),
          textAlign: "Center",
          visible: columns.includes("realTimes"),
        },
      ],
      connectorLineBackground: "#7e7e7e",
      connectorLineWidth: 3,
      dataBound: handleDataBound,
      eventMarkers: [
        {
          day: new Date(),
          label: tProjectSchedule("Today"),
        },
      ],
      gridLines: "Both",
      highlightWeekends: true,
      labelSettings: {
        leftLabel: "",
        rightLabel: function rightLabel(data) {
          return data.taskData.assignees.map((assignee) => assignee.name).join(", ");
        },
        // eslint-disable-next-line no-template-curly-in-string
        taskLabel: "${taskData.progressTaskLabel}%",
      },
      locale,
      milestoneTemplate: () => {
        return (
          <div
            className="e-gantt-milestone"
            style={{
              height: "16px",
              left: "1px",
              position: "absolute",
              transform: "rotate(45deg)",
              width: "16px",
            }}
          />
        );
      },
      onTaskbarClick: (event) => {
        if (event.data.taskData.type === "group") {
          setGroupObject(event.data.taskData);
          setGroupOpen(true);
        }
        if (event.data.taskData.type === "activity") {
          setActivityObject(event.data.taskData);
          setActivityOpen(true);
        }
        if (event.data.taskData.type === "milestone") {
          setMilestoneObject(event.data.taskData);
          setMilestoneOpen(true);
        }
        return false;
      },
      queryTaskbarInfo,
      ref,
      renderBaseline: true,
      rowDataBound,
      splitterSettings: computeSplitterSettings(fullscreenMode, columns, view),
      taskFields: {
        baselineEndDate: "baselineEndDate",
        baselineStartDate: "baselineStartDate",
        child: "child",
        dependency: "dependency",
        duration: "duration",
        id: "id",
        indicators: "indicators",
        name: "name",
        progress: "progress",
        startDate: "startDate",
      },
      taskMode: "Manual",
      timelineSettings: computeTimelineSettings(timelineViewMode),
      tooltipSettings: {
        showTooltip: true,
      },
      width: "100%",
      workWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFormat]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        overflow: "hidden",
        width: "100%",
      }}
    >
      <GanttComponent {...settings}>
        <Inject services={[DayMarkers]} />
      </GanttComponent>
    </Box>
  );
});

export default Gantt;
