const templates = {
  createTemplates: "Criar modelos",
  createTemplate: "Criar modelo",
  templateInstructions: "Complete as etapas a seguir para começar",
  templateTitle: "Título do modelo",
  selectDisciplines: "Adicionar disciplinas",
  saveTemplate: "Salvar modelo",
  weighting: "Ponderação",
  checklist: "Checklist",
  designTemplates: "Modelos de design",
  addSubitem: "Adicionar subitem",
  designDescription: "Crie modelos para acompanhar o departamento de design",
  selectModule: "Selecione um módulo para criar seu modelo",
  templates: "Modelos",
};

export { templates };
