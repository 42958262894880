/* eslint-disable react/prop-types */

import { datadogRum } from "@datadog/browser-rum";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";

import TaskCategorySelect from "../../components/projectDashboard/Tasks/TaskCategorySelect";
import { FormHeader, ModalWithAction } from "../../components/shared";
import { useAppTourContext } from "../../components/supportButton/context/context";
import {
  useCreateInspectionTemplateMutation,
  useEditInspectionTemplateMutation,
  useGetInspectionTemplateByIdQuery,
} from "../../features/project/modules/inspectionsTemplates/apiSlice";
import { selectCurrentInspectionTemplate } from "../../features/project/modules/inspectionsTemplates/slice";
import { selectCurrentGuideMe } from "../../features/project/projectSlice";
import Can from "../../utils/can";
import TextFieldWrapper from "./Shared/TextFieldWrapper";

function InspectionsTemplatesFormSection({
  control,
  errors,
  field,
  getValues,
  index,
  isTour = false,
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tInspections } = useTranslation("inspections");

  const [addItemTitle, setAddItemTitle] = useState("");
  const [addItemDescription, setAddItemDescription] = useState("");
  const [addItemStatus, setAddItemStatus] = useState(false);

  const { append, fields, move, remove, update } = useFieldArray({
    control,
    name: `sections.${index}.items`,
  });
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 10 &&
      currentGuideMe === "inspections-add-template" &&
      isTour
    ) {
      if (!addItemStatus) {
        setAddItemStatus(true);
      }
      setTimeout(() => {
        setState({ run: true });
      });
    }

    if (
      tourActive &&
      stepIndex === 9 &&
      currentGuideMe === "inspections-add-template" &&
      isTour
    ) {
      if (addItemStatus) {
        setAddItemStatus(false);
      }
    }
  }, [tourActive, stepIndex, currentGuideMe, addItemStatus, isTour, setState]);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 1 &&
      currentGuideMe === "inspections-templates-edit"
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, setState]);

  return (
    <Box display={field.isExpanded ? "block" : "none"} pl={4}>
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          if (destination) {
            move(source.index, destination.index);
          }
        }}
      >
        <Droppable droppableId="inspections-templates-form-items">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              display="flex"
              flexDirection="column"
              ref={provided.innerRef}
              width={1}
            >
              {fields.map((f, i) => {
                return (
                  <Draggable
                    draggableId={`sections.${index}.items.${i}`}
                    index={i}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`sections.${index}.items.${i}`}
                  >
                    {(provided) => {
                      return f.isEditable ? (
                        <Box
                          {...provided.draggableProps}
                          alignItems="center"
                          backgroundColor="#f8f8f8"
                          borderRadius="5px"
                          borderTop="1px solid #E1E1E1"
                          display="flex"
                          flexDirection="row"
                          gap={2}
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          pb={2}
                          pt={2}
                          ref={provided.innerRef}
                          textAlign="center"
                          width={1}
                        >
                          <Box
                            alignItems="stretch"
                            backgroundColor="#f8f8f8"
                            borderRadius="5px"
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            textAlign="center"
                            width={1}
                          >
                            <Box alignItems="center" display="flex" gap={1}>
                              <Box
                                fontSize="14px"
                                fontWeight="bold"
                                textAlign="right"
                                width={100}
                              >
                                {index + 1}.{i + 1}
                              </Box>
                              <Controller
                                control={control}
                                name={`sections.${index}.items.${i}.title`}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    defaultValue={getValues(
                                      `sections.${index}.items.${i}.title`,
                                    )}
                                    error={
                                      errors.sections?.[index]?.items?.[i]
                                        ?.title
                                    }
                                    fullWidth
                                    helperText={
                                      errors.sections?.[index]?.items?.[i]
                                        ?.title && tGeneral("fieldRequired")
                                    }
                                    label={tGeneral("title")}
                                    size="small"
                                    sx={{
                                      backgroundColor: "#fff",
                                      flex: 1,
                                      m: 0,
                                    }}
                                    type="text"
                                    variant="outlined"
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </Box>
                            <Box alignItems="center" display="flex" gap={1}>
                              <Box
                                fontSize="14px"
                                fontWeight="bold"
                                textAlign="right"
                                width={100}
                              >
                                {tGeneral("description")}
                              </Box>
                              <Controller
                                control={control}
                                name={`sections.${index}.items.${i}.description`}
                                render={({ field }) => (
                                  <TextFieldWrapper
                                    {...field}
                                    defaultValue={getValues(
                                      `sections.${index}.items.${i}.description`,
                                    )}
                                    error={
                                      errors.sections?.[index]?.items?.[i]
                                        ?.description
                                    }
                                    fullWidth
                                    helperText={
                                      errors.sections?.[index]?.items?.[i]
                                        ?.description &&
                                      tGeneral("fieldRequired")
                                    }
                                    label={tGeneral("description")}
                                    size="small"
                                    sx={{
                                      backgroundColor: "#fff",
                                      flex: 1,
                                      m: 0,
                                    }}
                                    type="text"
                                    variant="outlined"
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </Box>
                          </Box>
                          <Button
                            onClick={() =>
                              update(i, {
                                ...f,
                                isEditable: !f.isEditable,
                                title: getValues(
                                  `sections.${index}.items.${i}.title`,
                                ),
                                description: getValues(
                                  `sections.${index}.items.${i}.description`,
                                ),
                              })
                            }
                            sx={{ alignSelf: "flex-start", p: 1 }}
                            type="button"
                            variant="contained"
                          >
                            {tGeneral("save")}
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          {...provided.draggableProps}
                          backgroundColor="#f8f8f8"
                          borderRadius="5px"
                          borderTop="1px solid #E1E1E1"
                          display="flex"
                          flexDirection="column"
                          gap={2}
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          ref={provided.innerRef}
                          py={2}
                          width={1}
                        >
                          <Box
                            alignItems="flex-start"
                            display="flex"
                            flexDirection="row"
                            gap={2}
                            width={1}
                          >
                            <Box
                              {...provided.dragHandleProps}
                              alignItems="center"
                              display="flex"
                              flexDirection="column"
                              cursor="pointer"
                            >
                              <DragIndicatorIcon color="disabled" />
                            </Box>
                            <Box
                              alignItems="flex-start"
                              display="flex"
                              flexDirection="row"
                              gap={2}
                              width={1}
                            >
                              <Box
                                alignItems="flex-start"
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                width="70px"
                              >
                                <Typography
                                  align="right"
                                  sx={{ flex: 1, width: "100%" }}
                                >
                                  {index + 1}.{i + 1}
                                </Typography>
                              </Box>
                              <Box
                                alignItems="flex-start"
                                display="flex"
                                flexDirection="column"
                                gap={0}
                                width={1}
                              >
                                <Typography align="left" sx={{ flex: 1 }}>
                                  {getValues(
                                    `sections.${index}.items.${i}.title`,
                                  )}
                                </Typography>
                                <Typography
                                  align="left"
                                  sx={{ flex: 1, fontSize: "12px" }}
                                >
                                  {getValues(
                                    `sections.${index}.items.${i}.description`,
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            <EditIcon
                              onClick={() =>
                                update(i, { ...f, isEditable: !f.isEditable })
                              }
                              sx={{ cursor: "pointer" }}
                            />
                            <DeleteIcon
                              color="error"
                              onClick={() => remove(i)}
                              sx={{ cursor: "pointer" }}
                            />
                            <KeyboardArrowDownIcon sx={{ opacity: 0 }} />
                          </Box>
                        </Box>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      {addItemStatus && (
        <Box
          alignItems="flex-start"
          backgroundColor="#f8f8f8"
          borderRadius="5px"
          borderTop="1px solid #E1E1E1"
          display="flex"
          flexDirection="row"
          gap={2}
          pb={2}
          pt={2}
          textAlign="center"
          width={1}
        >
          <Box
            alignItems="stretch"
            backgroundColor="#f8f8f8"
            borderRadius="5px"
            display="flex"
            flexDirection="column"
            gap={1}
            textAlign="center"
            width={1}
          >
            <Box alignItems="center" display="flex" gap={1}>
              <Box
                fontSize="14px"
                fontWeight="bold"
                textAlign="right"
                width={100}
              >
                {index + 1}.{fields.length + 1}
              </Box>
              <TextFieldWrapper
                fullWidth
                showCharCount
                label={tGeneral("title")}
                onChange={(value) => setAddItemTitle(value)}
                size="small"
                sx={{ backgroundColor: "#fff", flex: 1, m: 0 }}
                type="text"
                value={addItemTitle}
                variant="outlined"
                data-tour={isTour ? "add-inspection-templates-8_1_2" : ""}
              />
            </Box>
            <Box alignItems="center" display="flex" gap={1}>
              <Box
                fontSize="14px"
                fontWeight="bold"
                textAlign="right"
                width={100}
              >
                {tGeneral("description")}
              </Box>
              <TextFieldWrapper
                fullWidth
                showCharCount
                label={tGeneral("description")}
                onChange={(value) => setAddItemDescription(value)}
                size="small"
                sx={{ backgroundColor: "#fff", flex: 1, m: 0 }}
                type="text"
                value={addItemDescription}
                variant="outlined"
                data-tour={isTour ? "add-inspection-templates-8_1_3" : ""}
              />
            </Box>
          </Box>
          <Button
            disabled={addItemTitle === ""}
            onClick={() => {
              append({
                title: addItemTitle,
                description: addItemDescription,
              });
              setAddItemStatus(false);
              setAddItemTitle("");
              setAddItemDescription("");
            }}
            sx={{ alignSelf: "flex-start", px: 2, py: 1 }}
            type="button"
            variant="contained"
            data-tour={isTour ? "add-inspection-templates-9" : ""}
          >
            {tGeneral("add")}
          </Button>
          <Button
            color="error"
            onClick={() => {
              setAddItemStatus(false);
              setAddItemTitle("");
              setAddItemDescription("");
            }}
            sx={{ alignSelf: "flex-start", px: 2, py: 1 }}
            type="button"
            variant="outlined"
          >
            {tGeneral("cancel")}
          </Button>
        </Box>
      )}
      <Box
        backgroundColor="#f8f8f8"
        borderRadius="5px"
        borderTop="1px solid #E1E1E1"
        color="#009dff"
        onClick={() => setAddItemStatus(true)}
        pt={2}
        sx={{ cursor: "pointer" }}
        width={1}
        data-tour={isTour ? "add-inspection-templates-8_1_1" : ""}
      >
        + {tInspections("addItem")}
      </Box>
    </Box>
  );
}

function InspectionsTemplatesForm() {
  const navigate = useNavigate();

  const { t: tGeneral } = useTranslation("general");
  const { t: tInspectionsTemplates } = useTranslation("inspectionsTemplates");
  const { t: tInspections } = useTranslation("inspections");

  const { idProject, idEnterprise } = useParams();

  const current = useSelector(selectCurrentInspectionTemplate);
  const { data: inspectionTemplate } = useGetInspectionTemplateByIdQuery(
    { idInspectionTemplate: current?.id, idProject },
    {
      skip: !current,
    },
  );
  const [createInspectionTemplate] = useCreateInspectionTemplateMutation();
  const [editInspectionTemplate] = useEditInspectionTemplateMutation();

  const [addSectionTitle, setAddSectionTitle] = useState("");
  const [addSectionStatus, setAddSectionStatus] = useState(false);

  const [inProcess, setInProcess] = useState(false);
  const [cancel, setCancel] = useState(false);

  const alert = (message, type) => {
    const Toast = Swal.mixin({
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      toast: true,
    });
    Toast.fire({
      icon: type,
      title: message,
    });
  };

  const onSubmit = async (data) => {
    setInProcess(true);
    try {
      if (!data.id) {
        const inspectionTemplateInput = {
          idProject,
          title: data.title,
          code: data.code,
          category: data.category,
          description: data.description,
          sections: data.sections,
        };
        const inspectionTemplateOutput = await createInspectionTemplate(
          inspectionTemplateInput,
        ).unwrap();
        if (!inspectionTemplateOutput) {
          throw new Error("!inspectionTemplateOutput");
        }
        alert(`Template ${tGeneral("createdSuccessfully")}`, "success");
        navigate(-1);
      } else {
        const body = {
          id: data.id,
          idProject,
          title: data.title,
          code: data.code,
          category: data.category,
          description: data.description,
          sections: data.sections,
        };
        const response = await editInspectionTemplate(body).unwrap();
        if (!response) {
          throw new Error("!inspectionTemplateOutput");
        }
        alert(`Template ${tGeneral("updatedSuccessfully")}`, "success");
        navigate(-1);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      if (data.id) {
        alert(`${tGeneral("cantCreate")} Template`, "error");
      } else {
        alert(`${tGeneral("cantEdit")} Template`, "error");
      }
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Template - Error",
          stack: error.stack ? error.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
    setInProcess(false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      ...inspectionTemplate,
    },
  });

  const { append, fields, move, remove, update } = useFieldArray({
    control,
    name: "sections",
  });

  useEffect(() => {
    reset(inspectionTemplate);
  }, [reset, inspectionTemplate]);

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const [sectionTourAdded, setSectionTourAdded] = useState(false);

  const addSectionTitleHandler = useCallback(() => {
    if (!sectionTourAdded) {
      append({
        isEditable: false,
        isExpanded: true,
        title: addSectionTitle,
        items: [],
      });
      setAddSectionStatus(false);
      setAddSectionTitle("");
    }
  }, [
    addSectionTitle,
    append,
    setAddSectionStatus,
    setAddSectionTitle,
    sectionTourAdded,
  ]);

  useLayoutEffect(() => {
    if (
      tourActive &&
      stepIndex === 3 &&
      currentGuideMe === "inspections-add-template"
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      stepIndex === 8 &&
      currentGuideMe === "inspections-add-template"
    ) {
      if (!sectionTourAdded) {
        setAddSectionStatus(true);
        setAddSectionTitle("Tour");
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      } else {
        remove();
        setSectionTourAdded(false);
      }
    }

    if (
      tourActive &&
      stepIndex === 9 &&
      currentGuideMe === "inspections-add-template"
    ) {
      setSectionTourAdded(true);
      addSectionTitleHandler();
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [
    tourActive,
    stepIndex,
    currentGuideMe,
    addSectionTitleHandler,
    remove,
    sectionTourAdded,
    setState,
  ]);

  if (current && !inspectionTemplate) {
    return false;
  }

  return (
    <Can I="edit" a="inspections">
      <Box display="flex" flexDirection="column" gap={2} width={1}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormHeader
            title={
              inspectionTemplate
                ? `${tInspectionsTemplates("edit")}`
                : `${tInspectionsTemplates("add")}`
            }
            onBack={() => navigate(-1)}
            isEditing={Boolean(inspectionTemplate)}
            isSubmit
            dataTourNameButton={
              !inspectionTemplate ? "add-inspection-templates-10" : ""
            }
          />

          <Box
            display="flex"
            flexDirection="column"
            width={1}
            px="10px"
            rowGap="1rem"
          >
            <Box
              display="flex"
              flexDirection="column"
              width={1}
              px="10px"
              rowGap="1rem"
              data-tour="inspection-templates-edit-2"
            >
              <Box display="flex" flexDirection="row" gap={2} mt="1rem">
                {/* Title */}
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => (
                    <TextFieldWrapper
                      {...field}
                      error={errors.title}
                      fullWidth
                      helperText={errors.title && tGeneral("fieldRequired")}
                      label={tGeneral("title")}
                      type="text"
                      variant="outlined"
                      data-tour="add-inspection-templates-4"
                    />
                  )}
                  rules={{ required: true }}
                />

                {/* Code */}
                <Controller
                  control={control}
                  name="code"
                  render={({ field }) => (
                    <TextFieldWrapper
                      {...field}
                      error={errors.code}
                      fullWidth
                      helperText={errors.code && tGeneral("fieldRequired")}
                      label={tGeneral("code")}
                      type="text"
                      variant="outlined"
                      data-tour="add-inspection-templates-5"
                    />
                  )}
                  rules={{ required: true }}
                />

                {/* Category */}
                <Controller
                  control={control}
                  name="category"
                  render={({ field }) => (
                    <TaskCategorySelect
                      error={errors.category}
                      helperText={errors.category && tGeneral("fieldRequired")}
                      selectedCategory={field.value}
                      onChange={field.onChange}
                    />
                  )}
                  rules={{ required: true }}
                />
              </Box>

              {/* Description */}
              <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
                width={1}
              >
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <TextFieldWrapper
                      {...field}
                      fullWidth
                      label={tGeneral("description")}
                      multiline
                      rows={1}
                      data-tour="add-inspection-templates-7"
                    />
                  )}
                />
              </Box>
              <Divider />
            </Box>
            <Box
              maxHeight="70vh"
              sx={{ overflowY: "auto" }}
              px="1rem"
              pb="1rem"
              data-tour="add-inspection-templates-8"
            >
              {/* Inspection List */}
              <Box
                alignItems="flex-start"
                display="flex"
                justifyContent="flex-start"
                pt={2}
                width={1}
              >
                <Box display="flex">
                  <Typography
                    fontWeight="bold"
                    variant="h6"
                    textTransform="uppercase"
                  >
                    {tInspectionsTemplates("list")}
                  </Typography>
                </Box>
              </Box>
              <Card>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  p={2}
                  width={1}
                >
                  {fields.length > 0 && (
                    <DragDropContext
                      onDragEnd={({ source, destination }) => {
                        if (destination) {
                          move(source.index, destination.index);
                        }
                      }}
                    >
                      <Droppable droppableId="inspections-templates-form-sections">
                        {(provided) => (
                          <Box
                            {...provided.droppableProps}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            ref={provided.innerRef}
                            width={1}
                          >
                            {fields.map((field, index) => (
                              <Draggable
                                draggableId={`sections.${index}`}
                                index={index}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`sections.${index}`}
                              >
                                {(provided) => {
                                  return (
                                    <>
                                      <Box
                                        {...provided.draggableProps}
                                        backgroundColor="#f8f8f8"
                                        borderRadius="5px"
                                        display={
                                          field.isEditable ? "flex" : "none"
                                        }
                                        flexDirection="column"
                                        gap={2}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${index}-editable`}
                                        p={2}
                                        ref={provided.innerRef}
                                        width={1}
                                      >
                                        <Box
                                          alignItems="center"
                                          backgroundColor="#f8f8f8"
                                          borderRadius="5px"
                                          display="flex"
                                          flexDirection="row"
                                          gap={2}
                                          textAlign="center"
                                          width={1}
                                        >
                                          <Controller
                                            control={control}
                                            name={`sections.${index}.title`}
                                            render={({ field }) => (
                                              <TextFieldWrapper
                                                showCharCount
                                                {...field}
                                                defaultValue={getValues(
                                                  `sections.${index}.title`,
                                                )}
                                                error={
                                                  errors.sections?.[index]
                                                    ?.title
                                                }
                                                fullWidth
                                                helperText={
                                                  errors.sections?.[index]
                                                    ?.title &&
                                                  tGeneral("fieldRequired")
                                                }
                                                label={tGeneral("title")}
                                                size="small"
                                                sx={{
                                                  backgroundColor: "#fff",
                                                  flex: 1,
                                                  m: 0,
                                                }}
                                                type="text"
                                                variant="outlined"
                                              />
                                            )}
                                            rules={{ required: true }}
                                          />
                                          <Button
                                            onClick={() =>
                                              update(index, {
                                                ...getValues(
                                                  `sections.${index}`,
                                                ),
                                                isEditable: !field.isEditable,
                                              })
                                            }
                                            sx={{ alignSelf: "stretch", p: 0 }}
                                            type="button"
                                            variant="contained"
                                          >
                                            {tGeneral("save")}
                                          </Button>
                                        </Box>
                                        <InspectionsTemplatesFormSection
                                          control={control}
                                          errors={errors}
                                          field={field}
                                          getValues={getValues}
                                          index={index}
                                        />
                                      </Box>
                                      <Box
                                        {...provided.draggableProps}
                                        backgroundColor="#f8f8f8"
                                        borderRadius="5px"
                                        display={
                                          field.isEditable ? "none" : "flex"
                                        }
                                        flexDirection="column"
                                        gap={2}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${index}-not-editable`}
                                        p={2}
                                        ref={provided.innerRef}
                                        width={1}
                                      >
                                        <Box
                                          alignItems="center"
                                          display="flex"
                                          flexDirection="row"
                                          gap={2}
                                          width={1}
                                        >
                                          <Box
                                            {...provided.dragHandleProps}
                                            alignItems="center"
                                            display="flex"
                                            flexDirection="column"
                                            cursor="pointer"
                                          >
                                            <DragIndicatorIcon color="disabled" />
                                          </Box>
                                          <Typography
                                            align="left"
                                            fontWeight="bold"
                                            sx={{ flex: 1 }}
                                          >
                                            {field.title}
                                          </Typography>
                                          <EditIcon
                                            onClick={() =>
                                              update(index, {
                                                ...getValues(
                                                  `sections.${index}`,
                                                ),
                                                isEditable: !field.isEditable,
                                              })
                                            }
                                            sx={{ cursor: "pointer" }}
                                          />
                                          <DeleteIcon
                                            color="error"
                                            onClick={() => remove(index)}
                                            sx={{ cursor: "pointer" }}
                                          />
                                          {field.isExpanded ? (
                                            <KeyboardArrowDownIcon
                                              onClick={() => {
                                                update(index, {
                                                  ...getValues(
                                                    `sections.${index}`,
                                                  ),
                                                  isExpanded: !field.isExpanded,
                                                });
                                              }}
                                              sx={{ cursor: "pointer" }}
                                            />
                                          ) : (
                                            <KeyboardArrowUpIcon
                                              onClick={() => {
                                                update(index, {
                                                  ...getValues(
                                                    `sections.${index}`,
                                                  ),
                                                  isExpanded: !field.isExpanded,
                                                });
                                              }}
                                              sx={{ cursor: "pointer" }}
                                            />
                                          )}
                                        </Box>
                                        <InspectionsTemplatesFormSection
                                          control={control}
                                          errors={errors}
                                          field={field}
                                          getValues={getValues}
                                          index={index}
                                          isTour
                                        />
                                      </Box>
                                    </>
                                  );
                                }}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </Box>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                  {addSectionStatus && (
                    <Box
                      alignItems="center"
                      backgroundColor="#f8f8f8"
                      borderRadius="5px"
                      display="flex"
                      flexDirection="row"
                      gap={2}
                      p={2}
                      textAlign="center"
                      width={1}
                      data-tour="add-inspection-templates-8_1"
                    >
                      <TextFieldWrapper
                        showCharCount
                        label={tGeneral("title")}
                        onChange={(value) => setAddSectionTitle(value)}
                        size="small"
                        sx={{ backgroundColor: "#fff", flex: 1 }}
                        type="text"
                        value={addSectionTitle}
                        variant="outlined"
                      />
                      <Button
                        disabled={addSectionTitle === ""}
                        onClick={addSectionTitleHandler}
                        sx={{ alignSelf: "stretch", px: 2, py: 1 }}
                        type="button"
                        variant="contained"
                      >
                        {tGeneral("add")}
                      </Button>
                      <Button
                        color="error"
                        onClick={() => {
                          setAddSectionStatus(false);
                          setAddSectionTitle("");
                        }}
                        sx={{ alignSelf: "stretch", px: 2, py: 1 }}
                        type="button"
                        variant="outlined"
                      >
                        {tGeneral("cancel")}
                      </Button>
                    </Box>
                  )}
                  <Box
                    backgroundColor="#f8f8f8"
                    borderRadius="5px"
                    color="#009dff"
                    onClick={() => setAddSectionStatus(true)}
                    p={2}
                    sx={{ cursor: "pointer" }}
                    textAlign="center"
                    width={1}
                  >
                    + {tInspections("add_section")}
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>
        </form>
        <Modal
          open={inProcess}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" size="100px" />
        </Modal>
        <ModalWithAction
          title={tGeneral("cancel")}
          content={tGeneral("cancelPrompt")}
          action={() => navigate(-1)}
          actionLabel={tGeneral("accept")}
          isOpen={cancel}
          onClose={() => setCancel(false)}
        />
      </Box>
    </Can>
  );
}

export default InspectionsTemplatesForm;
