const templates = {
  createTemplates: "Create templates",
  createTemplate: "Create template",
  templateInstructions: "Complete the following steps to get started",
  templateTitle: "Template title",
  selectDisciplines: "Add disciplines",
  saveTemplate: "Save template",
  weighting: "Weighting",
  checklist: "Checklist",
  designTemplates: "Design templates",
  addSubitem: "Add subitem",
  designDescription: "Create templates to track the design department",
  selectModule: "Select a module to create your template",
  templates: "Templates",
};

export { templates };
