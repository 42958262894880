import { apiSlice } from "../../api/apiSlice";

const designTemplateApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createDesignTemplate: builder.mutation({
      query: (body) => ({
        url: "designTemplates/create",
        body,
        method: "POST",
      }),
      invalidatesTags: ["DesignTemplates"],
    }),
    getDesignTemplates: builder.query({
      query: (idEnterprise) => `designTemplates/${idEnterprise}`,
      providesTags: ["DesignTemplates"],
    }),
    getDesignTemplateDetails: builder.query({
      query: (idDesignTemplate) => `designTemplates/detail/${idDesignTemplate}`,
      providesTags: ["DesignTemplates"],
    }),
    updateDesignTemplate: builder.mutation({
      query: ({ idDesignTemplate, body }) => ({
        url: `designTemplates/${idDesignTemplate}`,
        body,
        method: "PUT",
      }),
      invalidatesTags: ["DesignTemplates"],
    }),
    duplicateDesignTemplate: builder.mutation({
      query: (idDesignTemplate) => ({
        url: `designTemplates/duplicate/${idDesignTemplate}`,
        method: "POST",
      }),
      invalidatesTags: ["DesignTemplates"],
    }),
  }),
});

export const {
  useCreateDesignTemplateMutation,
  useGetDesignTemplatesQuery,
  useGetDesignTemplateDetailsQuery,
  useUpdateDesignTemplateMutation,
  useDuplicateDesignTemplateMutation,
} = designTemplateApiSlice;
