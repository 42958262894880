import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EmptyTemplates from "../../../assets/icons/templates/emptyTemplates.svg";

function TemplatesEmptyState() {
  const { t: tTemplates } = useTranslation("templates");
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100%"
    >
      <img
        src={EmptyTemplates}
        alt="empty state"
        style={{ marginBottom: "2rem" }}
      />
      <Typography
        textAlign="center"
        fontSize="2.125rem"
        color="#4B4B4B"
        fontWeight="700"
      >
        {tTemplates("noTemplates")}
      </Typography>
      <Typography textAlign="center" color="#4B4B4B">
        {tTemplates("createTemplates")}
      </Typography>
    </Box>
  );
}

export { TemplatesEmptyState };
