import { tour } from "./tour";

export const drawings = {
  // general tour
  ...tour,
  moduleElement: "図面",
  exportButtonTour: "ダウンロード",
  reviewAndPublishButtonTour: "レビューと公開",
  // ADD - guide me steps
  step1_addDrawing_title: "1. 追加",
  step1_addDrawing_text:
    "新しい図面を追加するには、+ アイコンをクリックします。",
  step2_addDrawing_title: "2. ファイルを追加",
  step2_addDrawing_text:
    "ファイルを添付するか、点線のボックス内にドラッグアンドドロップします。",
  step3_addDrawing_title: "3. セットの名前",
  step3_addDrawing_text: "セットの名前を選択または入力します。",
  step4_addDrawing_title: "4. 図面の日付",
  step4_addDrawing_text: "図面の日付を選択します。",
  step5_addDrawing_title: "5. 図面の受付日",
  step5_addDrawing_text: "図面の受付日を選択します。",
  step6_addDrawing_title: "6. 保存",
  step6_addDrawing_text: "図面を保存するには、ここをクリックします。",
  step_final_addDrawing: "図面の追加方法を学びました。",
  // REVIEW & PUBLISH - guide me steps
  step1_reviewAndPublishDrawing_title: "1. その他のオプション",
  step1_reviewAndPublishDrawing_text:
    "その他のオプションを表示するには、ここをクリックします。",
  step2_reviewAndPublishDrawing_title: "2. 図面のレビュー",
  step2_reviewAndPublishDrawing_text:
    "レビュー待ちの図面のセクションを開くには、ここをクリックします。",
  step3_reviewAndPublishDrawing_title: "3. 承認",
  step3_reviewAndPublishDrawing_text:
    "図面の情報を表示するには、ここをクリックします。",
  step4_reviewAndPublishDrawing_title: "4. 情報の変更",
  step4_reviewAndPublishDrawing_text: "必要な変更を行います。",
  step5_reviewAndPublishDrawing_title: "5. 承認",
  step5_reviewAndPublishDrawing_text:
    "図面を承認するには、ここをクリックします。",
  step6_reviewAndPublishDrawing_title: "6. 公開タブ",
  step6_reviewAndPublishDrawing_text:
    "公開待ちの図面のリストを表示するには、ここをクリックします。",
  step7_reviewAndPublishDrawing_title: "7. 図面の選択",
  step7_reviewAndPublishDrawing_text: "公開する図面を選択します。",
  step8_reviewAndPublishDrawing_title: "8. 公開",
  step8_reviewAndPublishDrawing_text:
    "選択した図面を公開するには、ここをクリックします。",
  step_final_reviewAndPublishDrawing: "図面のレビューと公開方法を学びました。",
  // VIEW - guide me steps
  step1_viewDrawing_title: "1. 表示",
  step1_viewDrawing_text: "図面を表示するには、ここをクリックします。",
  step2_viewDrawing_title: "2. レビュー",
  step2_viewDrawing_text: "レビューの数を選択します。",
  step3_viewDrawing_title: "3. 図面",
  step3_viewDrawing_text: "図面を変更する必要がある場合は選択します。",
  step4_viewDrawing_title: "4. 注釈",
  step4_viewDrawing_text: "図面に注釈を追加するには、ここをクリックします。",
  step5_viewDrawing_title: "5. 注釈の表示/非表示",
  step5_viewDrawing_text:
    "注釈の表示または非表示を切り替えるには、ここをクリックします。",
  step_final_viewDrawing: "図面の表示方法を学びました。",
  // EDIT - guide me steps
  step1_editDrawing_title: "1. 編集",
  step1_editDrawing_text: "図面を編集するには、ここをクリックします。",
  step2_editDrawing_title: "2. 図面の名前",
  step2_editDrawing_text: "図面の名前を編集します。",
  step3_editDrawing_title: "3. セットの名前",
  step3_editDrawing_text: "セットの名前を選択または編集します。",
  step4_editDrawing_title: "4. ディシプリン",
  step4_editDrawing_text: "ディシプリンを選択します。",
  step5_editDrawing_title: "5. プライバシー",
  step5_editDrawing_text: "図面を誰にでも表示するかどうかを選択します。",
  step6_editDrawing_title: "6. プライバシーレベル",
  step6_editDrawing_text:
    "有効にすると、特定の企業や個人へのアクセスが制限されます。",
  step7_editDrawing_title: "7. 保存",
  step7_editDrawing_text: "変更を保存するには、ここをクリックします。",
  step_final_editDrawing: "図面の編集方法を学びました。",
  // RECYCLE - guide me steps
  step1_recycleDrawing_title: "1. その他のオプション",
  step1_recycleDrawing_text:
    "その他のオプションを表示するには、ここをクリックします。",
  step2_recycleDrawing_title: "2. 図面のごみ箱",
  step2_recycleDrawing_text:
    "図面のごみ箱を表示するには、ここをクリックします。",
  step3_recycleDrawing_title: "3. 図面の選択",
  step3_recycleDrawing_text: "復元する図面を選択します。",
  step4_recycleDrawing_title: "4. 復元",
  step4_recycleDrawing_text:
    "選択した図面を復元するには、ここをクリックします。",
  step_final_recycleDrawing: "図面の復元方法を学びました。",
  // DOWNLOAD - guide me steps
  step1_exportDrawing_title: "1. その他のアクション",
  step1_exportDrawing_text:
    "3点リーダーをクリックして、その他のアクションを表示します。",
  step2_exportDrawing_title: "2. ダウンロード",
  step2_exportDrawing_text:
    "図面をダウンロードするには、ここをクリックします。",
  step3_exportDrawing_title: "3. 代替ダウンロード",
  step3_exportDrawing_text:
    "列 #Rev の上にカーソルを置くと、リビジョンのリストが表示されます。",
  step4_exportDrawing_title: "4. リビジョンのダウンロード",
  step4_exportDrawing_text: "ダウンロードしたいファイルをクリックします。",
  step_final_exportDrawing: "図面のダウンロード方法を学びました。",
  // SETTINGS - guide me steps
  step1_settingsDrawing_title: "1. その他のオプション",
  step1_settingsDrawing_text:
    "その他のオプションを表示するには、ここをクリックします。",
  step2_settingsDrawing_title: "2. 設定",
  step2_settingsDrawing_text: "設定モーダルを開くには、ここをクリックします。",
  step3_settingsDrawing_title: "3. 配布リスト",
  step3_settingsDrawing_text:
    "図面モジュールへのデフォルトアクセス権を持つ人々を追加または削除します。",
  step4_settingsDrawing_title: "4. ディシプリン",
  step4_settingsDrawing_text:
    "ディシプリンタブにアクセスするには、ここをクリックします。",
  step5_settingsDrawing_title: "5. ディシプリンの追加",
  step5_settingsDrawing_text: "追加するディシプリンの名前を入力します。",
  step_final_settingsDrawing: "モジュール図面の設定方法を学びました。",

  addDrawing: "図面を追加",
  reviewDrawings: "図面のレビュー",
  drawingsToCheck: "確認する図面",
  drawingsToPublish: "公開する図面",
  discipline: "ディシプリン",
  disciplines: "ディシプリン",
  addNewSet: "新しいセットを追加",
  setName: "セット名",
  drawingDate: "図面の日付",
  deletingDrawingMessage:
    "この図面を削除してもよろしいですか？この操作は元に戻すことはできません",
  deleteDrawing: "図面を削除",
  drawingsDeletedMessage: "図面が正常に削除されました。",
  drawingsPublishedMessage: "図面が正常に公開されました。",
  drawingsRestoredMessage: "図面が正常に復元されました。",
  recycleBin: "図面のごみ箱",
  drawingName: "図面名",
  deleteDrawingsMessage: "これらの図面を削除してもよろしいですか？",
  deleteLastRevision: "最後のリビジョンを削除",
  revision: "リビジョン",
  noDrawingsCreated: "作成された図面はありません",
  addDrawingsValidation:
    "追加する場合は、プロセスを開始するために追加ボタンをクリックしてください",
  line: "線",
  rectangle: "長方形",
  ellipse: "楕円",
  arrow: "矢印",
  polyline: "ポリライン",
  annotationPublishedMessage: "注釈が正常に公開されました。",
  freetext: "フリーテキスト",
  annotationAssignedMessage: "注釈が正常に割り当てられました。",
  annotationLinkedMessage: "注釈が正常にリンクされました。",
  approveDrawing: "図面を承認",
  drawingId: "図面ID",
  approve: "承認",
  publishAnnotations: "注釈を公開",
  deleteAnnotations: "注釈を削除",
  deleteAnnotationsContent: "注釈を${1}個削除してもよろしいですか？",
  annotationDeletedMessage: "注釈が正常に削除されました。",
  drawingsAddedTitle: "図面セットが追加されました",
  drawingsAddedContent:
    "図面が正常に追加されました。システムにアップロードされたときに通知します。",
  reviseDrawings: "図面の修正",
  area: "面積",
  calibrate: "キャリブレーション",
  distance: "距離",
  includeAnnotations: "注釈を含む",
  excludeAnnotations: "注釈を除外する",
  editDrawing: "図面を編集",
  addDiscipline: "ディシプリンを追加",
  editDrawingId: "図面IDを編集",
  editedDrawingMessage: "図面が正常に更新されました。",
  compareWith: "比較する",
  selectDrawgingsToCompare: "比較する図面を選択",
  revisionsDeletedSuccessfully: "リビジョンが正常に削除されました",
  links: "リンク",
  showAnnotations: "注釈を表示",
  hideAnnotations: "注釈を非表示",
  measure: "測定",
  generated: "生成された",
  comparisionGeneratedSuccessfully: "比較が正常に生成されました",
  setCalibration: "キャリブレーションを調整",
  calibrateDescription:
    "この平面のすべての測定は、以下に示すキャリブレーションを参照します。",
  calibrationNote: "注: これらの測定値は正確ではありません。",
  calibrateInstructions:
    "キャリブレーションするために平面上の2点を選択してください",
  fileSizeLimit: "最大ファイルサイズ: 10GB (合計)",
  uploadingQtyDrawings: "図面{{qty}}枚をアップロードしています",
  uploadDrawings: "図面をアップロード",
  comment: "コメントする",
  commentDrawing: "図面にコメントを追加する",
  commentPlaceholder: "図面に関するコメントを書いてください",
  drawingViewer: "図面ビューア",
  uploadedMessage:
    "図面のアップロードに成功しました。ファイルは処理され、まもなく利用可能になります。",
  dwgUploadedTitle: "アップロード中",
  dwgUploadedMessage:
    "DWGファイルは現在PDFに変換されており、まもなく編集のために公開されます。",
  dwgUploadedAction: "閲覧を続ける",
};
