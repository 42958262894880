import {
  CheckBox,
  DeleteOutline,
  Loop,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Search,
  Upload,
} from "@mui/icons-material";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { CheckListDrawer } from "../../../../components/drawings";
import {
  ModalWithAction,
  TitleWidgets,
  TooltipIconButton,
} from "../../../../components/shared";
import {
  useDeleteRevisionsMutation,
  useGetToCheckDrawingsQuery,
  usePublishRevisionsMutation,
} from "../../../../features/drawings";
import { setUploadingFiles } from "../../../../features/drawings/drawingsSlice";
import { UploadDrawingsTable } from "./UploadDrawingsTable";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function UploadDrawings() {
  const { idProject } = useParams();
  const { t: tDrawings } = useTranslation("drawings");
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawingsChecklist } = useTranslation("drawingsChecklist");

  const { data, refetch, isLoading, isFetching } = useGetToCheckDrawingsQuery({
    idProject,
  });
  const [publisRevisions, { isLoading: isPublishLoading }] =
    usePublishRevisionsMutation();
  const [deleteRevisions, { isLoading: isDeleteLoading }] =
    useDeleteRevisionsMutation();

  const drawings = data ?? [];

  const dispatch = useDispatch();

  const [selectedDrawings, setSelectedDrawings] = useState([]);
  const [isCheckListOpen, setIsCheckListOpen] = useState(false);
  const [showUploadWarning, setShowUploadWarning] = useState(false);

  const handleUploadFiles = (e) => {
    const { files } = e.target;
    const newDrawingFiles = Object.keys(files).map((index) => ({
      file: files[index],
      type: "drawing",
    }));
    dispatch(setUploadingFiles(newDrawingFiles));

    if (newDrawingFiles.some((file) => file.file.name.endsWith(".dwg"))) {
      setShowUploadWarning(true);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedDrawings(drawings.map((drawing) => drawing.id));
    } else {
      setSelectedDrawings([]);
    }
  };

  const handlePublish = () => {
    publisRevisions({
      ids: drawings
        .filter((d) => selectedDrawings.indexOf(d.id) > -1 && !!d.drawingId)
        .map((d) => d.id),
    });
  };

  const handleDelete = () => {
    deleteRevisions({ ids: selectedDrawings });
  };

  return (
    <Box>
      <TitleWidgets
        backEnabled
        title={tDrawings("uploadDrawings")}
        showMoreOptions={false}
        customElement={
          <Box display="flex" alignItems="center" columnGap="0.5rem">
            <TooltipIconButton icon={<Search />} label={tGeneral("search")} />
            <TooltipIconButton
              icon={<CheckBox />}
              label={tDrawingsChecklist("checkList")}
              onClick={() => setIsCheckListOpen(true)}
            />
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<Upload />}
              sx={{
                borderRadius: "1.5rem",
                px: "1.5rem",
                textTransform: "none",
                background: "linear-gradient(90deg, #FBCB04 0%, #E5E900 100%)",
              }}
            >
              {tGeneral("upload")}
              <VisuallyHiddenInput
                type="file"
                onChange={handleUploadFiles}
                multiple
              />
            </Button>
          </Box>
        }
      />
      <Box pt="0.5rem" pl="1rem" pr="2.5rem">
        <Box display="flex" columnGap="1rem" alignItems="center" py="0.5rem">
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={
                drawings.length === selectedDrawings.length &&
                drawings.length > 0
              }
              icon={<RadioButtonUnchecked />}
              checkedIcon={<RadioButtonChecked />}
              onChange={handleSelectAll}
            />
            <Typography color="#C68D08">{tGeneral("selectAll")}</Typography>
          </Box>
          <Box flex={1} />
          <TooltipIconButton icon={<Loop />} onClick={() => refetch()} />
          {selectedDrawings.length > 0 && (
            <>
              <TooltipIconButton
                icon={<DeleteOutline />}
                label={tGeneral("delete")}
                color="#F49898"
                onClick={handleDelete}
              />
              <Button
                variant="contained"
                sx={{
                  borderRadius: "1rem",
                  textTransform: "none",
                  backgroundColor: "#FCF18C",
                  color: "#F6CE19",
                  px: "2rem",
                }}
                onClick={handlePublish}
              >
                <Typography>{tGeneral("publish")}</Typography>
              </Button>
            </>
          )}
        </Box>
        <UploadDrawingsTable
          selectedDrawings={selectedDrawings}
          setSelectedDrawings={setSelectedDrawings}
          drawings={drawings}
          isLoading={isLoading || isFetching}
        />
        {isCheckListOpen && (
          <CheckListDrawer
            isOpen={isCheckListOpen}
            onClose={() => setIsCheckListOpen(false)}
          />
        )}
      </Box>
      <ModalWithAction
        showCancel={false}
        title={tDrawings("dwgUploadedTitle")}
        content={tDrawings("dwgUploadedMessage")}
        action={() => setShowUploadWarning(false)}
        onClose={() => setShowUploadWarning(false)}
        actionLabel={tDrawings("dwgUploadedAction")}
        isOpen={showUploadWarning}
        icon="drawingDwg"
        iconStyles={{ width: "300px", height: "200px" }}
      />
    </Box>
  );
}

export { UploadDrawings };
