import { Add, ArrowBack, Tune } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { TooltipIconButton } from "../../../components/shared";
import {
  DesignTemplatesTable,
  TemplatesEmptyState,
} from "../../../components/enterpriseDashboard/Templates";
import { useGetDesignTemplatesQuery } from "../../../features/designTemplates";

function DesignTemplates() {
  const { idEnterprise } = useParams();
  const { t: tTemplates } = useTranslation("templates");
  const { t: tGeneral } = useTranslation("general");

  const navigate = useNavigate();

  const [showFilters, setShowFilters] = useState(false);

  const { data, isLoading } = useGetDesignTemplatesQuery(idEnterprise);

  const templates = data ?? [];

  return (
    <Box p="1rem" display="flex" flexDirection="column" height="100%">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <TooltipIconButton
            icon={<ArrowBack />}
            label={tGeneral("black")}
            onClick={() => navigate(-1)}
            color="#8E8E8E"
          />
          <Typography color="#1F1F1F" fontSize="1.25rem" fontWeight="700">
            {tTemplates("designTemplates")}
          </Typography>
        </Box>
        <Box display="flex">
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => navigate("form")}
            variant="contained"
          >
            <Add sx={{ color: "black" }} />
            <Typography>{tTemplates("createTemplate")}</Typography>
          </Button>
          <TooltipIconButton
            icon={<Tune />}
            label={tGeneral("filters")}
            onClick={() => setShowFilters(true)}
          />
        </Box>
      </Box>
      <Box flex={1}>
        {templates.length === 0 && !isLoading ? (
          <TemplatesEmptyState />
        ) : (
          <DesignTemplatesTable data={templates} isLoading={isLoading} />
        )}
      </Box>
    </Box>
  );
}

export { DesignTemplates };
