// @ts-check
/* eslint react/prop-types: 0 */

import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CloseIcon from "@mui/icons-material/Close";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ScienceIcon from "@mui/icons-material/Science";
import SubjectIcon from "@mui/icons-material/Subject";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import { buildColor, renderCurrency, renderPercent } from "../../../../utils/projectSchedule";
import { AssignedUsersTooltip } from "../../../shared/AssignedUsersTooltip";
import Comments from "../Comments";
import Histories from "../Histories";
import TransitionComponent from "../TransitionComponent";

export default function Group(props) {
  const { assignees, client, dateFormat, object, open, setDataSource, setOpen, users } = props;

  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(true);
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const [titleCharCount, setTitleCharCount] = useState(0);

  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const formContext = useForm({
    defaultValues: {
      title: object.title || "",
      description: object.description || "",
    },
  });
  const { control, formState, handleSubmit, reset } = formContext;

  const toggleHistory = () => {
    setShowHistory((prevShowHistory) => !prevShowHistory);
  };

  useEffect(() => {
    reset({
      title: object.title || "",
      description: object.description || "",
    });
  }, [object, reset]);

  const comments = (object.child || [])
    .flatMap((activity) => {
      return activity.comments;
    })
    .filter((comment) => {
      return !!comment;
    });

  comments.sort((a, b) => {
    if (a.createdDate < b.createdDate) {
      return -1;
    }
    if (a.createdDate > b.createdDate) {
      return 1;
    }
    return 0;
  });

  const histories = (object.child || [])
    .flatMap((activity) => {
      return activity.histories;
    })
    .filter((history) => {
      return !!history;
    });

  const handleClose = () => {
    setOpen(false);
  };

  const process = async (data) => {
    setLoading(true);
    try {
      if (!object.id) {
        const response = await client.request({
          data: {
            idGroup: object.idGroup,
            title: data.title,
            description: data.description,
            metadata: {},
          },
          method: "POST",
          url: "/groups",
        });
        setDataSource(response.data);
      } else {
        const response = await client.request({
          data: {
            idGroup: object.idGroup,
            title: data.title,
            description: data.description,
            metadata: object.metadata,
          },
          method: "PUT",
          url: `/groups/${object.id}`,
        });
        setDataSource(response.data);
      }
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "success",
        title: tProjectSchedule("Success"),
      });
    } catch (error) {
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "error",
        title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
      });
      // eslint-disable-next-line no-console
      console.error(error);
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={open}
      PaperProps={{
        className: "project-schedule",
        sx: {
          borderRadius: "20px",
        },
      }}
      scroll="body"
      // @ts-ignore
      TransitionComponent={TransitionComponent}
    >
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(process)}>
          <DialogTitle
            component="div"
            sx={{
              padding: 0,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#d7d7de",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#c2c2c2",
                  bottom: 0,
                  left: 0,
                  position: "absolute",
                  top: 0,
                  width: `${object?.progress ?? 0}%`,
                  zIndex: 1,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  padding: "30px",
                  position: "relative",
                  zIndex: 2,
                }}
              >
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      flexGrow: 1,
                      gap: "20px",
                    }}
                  >
                    <Controller
                      control={control}
                      name="title"
                      render={({ field }) => {
                        field.value ? setTitleCharCount(field.value.length) : setTitleCharCount(0);
                        return (
                          <>
                            <TextField
                              {...field}
                              error={formState.errors.title !== undefined}
                              fullWidth
                              helperText={formState.errors.title && tGeneral("fieldRequired")}
                              label={tProjectSchedule("Title")}
                              type="text"
                              onChange={(e) => {
                                setTitleCharCount(e.target.value.length);
                                field.onChange(e);
                              }}
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                            <FormHelperText
                              style={{
                                color: titleCharCount > 200 ? "red" : "inherit",
                              }}
                            >
                              {`${titleCharCount} / ${255}`}
                            </FormHelperText>
                          </>
                        );
                      }}
                      rules={{ required: true }}
                    />
                    <AssignedUsersTooltip
                      users={assignees
                        .filter((assignee) => object?.assignees?.map((a) => a.id).includes(assignee.id))
                        .map((option) => {
                          return {
                            bActive: true,
                            id: option.id,
                            name: option.name,
                            urlAvatar: option.urlAvatar,
                          };
                        })}
                    />
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <Box
                      onClick={handleClose}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      fontSize="md"
                      fontWeight="bold"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {tProjectSchedule("% Complete")}:
                    </Typography>
                    <Typography
                      fontSize="md"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {(object.progress || 0).toFixed(0)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      fontSize="md"
                      fontWeight="bold"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {tProjectSchedule("Scheduled Times")}:{" "}
                    </Typography>
                    <Typography
                      fontSize="md"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {moment(object?.scheduledTimesStart?.slice(0, 10)).format(dateFormat)} {tProjectSchedule("to")}{" "}
                      {moment(object?.scheduledTimesEnd?.slice(0, 10)).format(dateFormat)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      fontSize="md"
                      fontWeight="bold"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {tProjectSchedule("Real Times")}:{" "}
                    </Typography>
                    <Typography
                      fontSize="md"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {moment(object?.realTimesStart?.slice(0, 10)).format(dateFormat)} {tProjectSchedule("to")}{" "}
                      {moment(object?.realTimesEnd?.slice(0, 10)).format(dateFormat)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              padding: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <SubjectIcon />
                  <strong>{tProjectSchedule("Description")}</strong>
                </Box>
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => {
                    field.value ? setDescriptionCharCount(field.value.length) : setDescriptionCharCount(0);
                    return (
                      <>
                        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                          <TextField
                            {...field}
                            error={formState.errors.description !== undefined}
                            fullWidth
                            helperText={formState.errors.description && tGeneral("fieldRequired")}
                            label={tProjectSchedule("Description")}
                            multiline
                            rows={2}
                            type="text"
                            onChange={(e) => {
                              setDescriptionCharCount(e.target.value.length);
                              field.onChange(e);
                            }}
                            inputProps={{
                              maxLength: 255,
                            }}
                          />
                          <FormHelperText
                            style={{
                              position: 'absolute',
                              right: 0,
                              bottom: '-20px',
                              color: descriptionCharCount > 200 ? 'red' : 'inherit',
                            }}
                          >
                            {`${descriptionCharCount} / ${255}`}
                          </FormHelperText>
                        </div>
                      </>
                    );
                  }}
                  rules={{ required: true }}
                />
              </Box>
              <table className="project-schedule project-schedule-project-cost">
                <tbody>
                  <tr>
                    <td className="center" colSpan={3}>
                      <strong>{tProjectSchedule("$ Project Cost")}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="center">{tProjectSchedule("Budget")}</td>
                    <td className="center">{tProjectSchedule("Expenses")}</td>
                    <td className="center">{tProjectSchedule("Total")}</td>
                  </tr>
                  <tr className="special">
                    <td className="center">
                      <strong>{renderCurrency(object.projectCostBudget ?? 0)}</strong>
                    </td>
                    <td className="center">
                      <strong>{renderCurrency(object.projectCostExpenses ?? 0)}</strong>
                    </td>
                    <td className="center">
                      <strong
                        style={{
                          color: buildColor(object.projectCostDifference ?? 0),
                        }}
                      >
                        {renderCurrency(object.projectCostDifference ?? 0)}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <AssignmentTurnedInIcon />
                  <strong>{tProjectSchedule("Activities")}</strong>
                </Box>
                <table className="project-schedule project-schedule-activities">
                  <tbody>
                    <tr>
                      <td>
                        <strong>{tProjectSchedule("Title")}</strong>
                      </td>
                      <td className="center">
                        <strong>{tProjectSchedule("Assigned")}</strong>
                      </td>
                      <td className="center">
                        <strong>{tProjectSchedule("Progress")}</strong>
                      </td>
                      <td className="right">
                        <strong>{tProjectSchedule("Budget")}</strong>
                      </td>
                      <td className="right">
                        <strong>{tProjectSchedule("Expenses")}</strong>
                      </td>
                      <td className="right">
                        <strong>{tProjectSchedule("Total")}</strong>
                      </td>
                    </tr>
                    {object?.child
                      ?.filter((c) => c.type === "activity")
                      .map((c) => {
                        const numerator = c.roadblocks.filter((roadblock) => roadblock.status !== "close").length;
                        const denomintor = c.roadblocks.length;
                        return (
                          <tr key={c.id}>
                            <td>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  gap: "5px",
                                }}
                              >
                                {c.title}
                                <Box
                                  sx={{
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    gap: "5px",
                                  }}
                                >
                                  {numerator === 0 ? (
                                    <ScienceIcon color="disabled" fontSize="small" />
                                  ) : (
                                    <>
                                      <small>
                                        {numerator}/{denomintor}
                                      </small>
                                      <ScienceIcon color="error" fontSize="small" />
                                    </>
                                  )}
                                </Box>
                              </Box>
                            </td>
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <td className="center">
                              <Box
                                sx={{
                                  transform: "scale(0.75)",
                                }}
                              >
                                <AssignedUsersTooltip
                                  users={c.assignees.map((assignee) => {
                                    return {
                                      bActive: true,
                                      id: assignee.id,
                                      name: assignee.name,
                                      urlAvatar: assignee.urlAvatar,
                                    };
                                  })}
                                />
                              </Box>
                            </td>
                            <td className="center">{renderPercent(c.progress)}</td>
                            <td className="right">{renderCurrency(c.projectCostBudget)}</td>
                            <td className="right">{renderCurrency(c.projectCostExpenses)}</td>
                            <td className="right">
                              <Box
                                sx={{
                                  color: buildColor(c.projectCostDifference),
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {renderCurrency(c.projectCostDifference)}
                              </Box>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <QuestionAnswerIcon />
                  <strong>{tProjectSchedule("Comments")}</strong>
                </Box>
                <Box
                  sx={{
                    marginBottom: "-15px",
                  }}
                >
                  <Comments
                    activity={undefined}
                    client={client}
                    comment={undefined}
                    comments={comments}
                    dateFormat={dateFormat}
                    level={0}
                    readOnly
                    setDataSource={setDataSource}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#cccccc",
                  height: "1px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <WorkHistoryIcon />
                    <strong>{tProjectSchedule("History")}</strong>
                  </Box>
                  {histories.length ? <Button onClick={toggleHistory}>{showHistory ? tProjectSchedule("Hide") : tProjectSchedule("View")}</Button> : null}
                </Box>
                {showHistory && <Histories dateFormat={dateFormat} histories={histories} users={users} />}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            disableSpacing
            sx={{
              gap: "30px",
              padding: "30px",
            }}
          >
            {loading && <CircularProgress size={20} />}
            <Button color="secondary" onClick={handleClose} variant="outlined">
              {tProjectSchedule("Cancel")}
            </Button>
            <Button color="primary" type="submit" variant="contained">
              {tProjectSchedule("Send")}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
