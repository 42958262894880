import React, { useEffect, useState } from "react";
// MUI Components
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// MUI Icons
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Clear from "@mui/icons-material/Clear";
// Assets
// Hooks
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
} from "@mui/material";
import logo from "../../assets/logo-box.png";
// Redux: reducers
import { useGetUserQuery } from "../../features/enterprise/enterpriseApiSlice";
import {
  useGetProjectUsersQuery,
  useGetUserRoleQuery,
} from "../../features/project/projectApiSlice";
import {
  setCurrentProject,
  setCurrentProjectUsers,
  setCurrentRole,
} from "../../features/project/projectSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";

import {
  useGetVisiblesMutation,
  useGetOrderMutation,
} from "../../features/project/projectSettingsApiSlice";
import {
  setVisibles,
  setOrder,
} from "../../features/project/projectSettingsSlice";

function ProjectDescriptionModal({ title, description, open, handleClose }) {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="xs"
      onClick={(e) => e.stopPropagation()}
      PaperProps={{ sx: { borderRadius: "16px", p: "12px 24px" } }}
    >
      <DialogTitle
        sx={{ p: 0, mb: 1 }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {title}
        <IconButton onClick={handleClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ py: 0 }} />
      <DialogContent sx={{ p: 0, pt: 2 }}>
        <DialogContentText whiteSpace="pre-wrap" sx={{ p: 0 }}>
          {description}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

function ProjectCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: tGeneral } = useTranslation("general");

  const [modalOpen, setModalOpen] = useState(false);

  const [projectSelected, setProjectSelected] = useState(false);

  const { data: enterpriseUser, isLoading: isLoadingEnterpriseUser } =
    useGetUserQuery();

  const currentUser = useSelector(selectCurrentUser);
  const { data: projectUsers } = useGetProjectUsersQuery(props?.project?.id);

  const [idCardProject, setIdProjectCard] = useState(null);

  const { data: roleData } = useGetUserRoleQuery(
    {
      idUser: enterpriseUser?.id,
      idProject: idCardProject,
    },
    { skip: idCardProject === null && isLoadingEnterpriseUser },
  );

  const setProjectUsers = async () => {
    if ((projectUsers || []).length > 0) {
      const usersList = projectUsers
        .filter((obj) => {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.user.id,
          name: `${user.user.firstName} ${user.user.lastName}`,
          urlAvatar: user.user.urlAvatar,
        }));
      dispatch(setCurrentProjectUsers(usersList));
    }
  };

  const getRole = async () => {
    if (roleData) {
      dispatch(setCurrentRole(roleData[0].role));
    }
  };

  const [getVisibleModules] = useGetVisiblesMutation();

  const [modulesV] = React.useState([
    { id: "1", name: "Drawings", visible: true },
    { id: "2", name: "Punch List", visible: true },
    { id: "3", name: "Directory", visible: true },
    { id: "4", name: "RFIs", visible: true },
    { id: "5", name: "Submittals", visible: true },
    { id: "6", name: "Deliveries", visible: true },
    { id: "7", name: "Hours", visible: true },
    { id: "8", name: "Meetings", visible: true },
    { id: "9", name: "Photos", visible: true },
    { id: "10", name: "Tasks", visible: true },
    { id: "11", name: "Inspections", visible: true },
    { id: "12", name: "Issues", visible: true },
    { id: "13", name: "Daily Reports", visible: true },
    { id: "14", name: "Documents", visible: true },
    { id: "15", name: "Delays", visible: true },
    { id: "16", name: "Announcements", visible: true },
    { id: "17", name: "KPIs", visible: true },
  ]);

  const getVisiblesFromDatabase = async (project) => {
    try {
      const body = {
        idProject: project?.id,
        idUser: enterpriseUser?.id,
      };
      const response = await getVisibleModules(body).unwrap();

      if (response) {
        if (response[0]?.visibleModules?.visibleModules?.length > 0) {
          dispatch(setVisibles(response[0].visibleModules.visibleModules));
        } else {
          dispatch(setVisibles(modulesV));
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const [getOrderModules] = useGetOrderMutation();

  const [modulesO] = React.useState([
    { id: "1", name: "Drawings", order: 0 },
    { id: "2", name: "Punch List", order: 1 },
    { id: "3", name: "Directory", order: 2 },
    { id: "4", name: "RFIs", order: 3 },
    { id: "5", name: "Submittals", order: 4 },
    { id: "6", name: "Deliveries", order: 5 },
    { id: "7", name: "Hours", order: 6 },
    { id: "8", name: "Meetings", order: 7 },
    { id: "9", name: "Photos", order: 8 },
    { id: "10", name: "Tasks", order: 9 },
    { id: "11", name: "Inspections", order: 9.1 },
    { id: "12", name: "Issues", order: 11 },
    { id: "13", name: "Daily Reports", order: 12 },
    { id: "14", name: "Documents", order: 13 },
    { id: "15", name: "Delays", order: 14 },
    { id: "16", name: "Announcements", order: 15 },
    { id: "17", name: "KPIs", order: 16 },
  ]);

  const getOrderFromDatabase = async (project) => {
    try {
      const body = {
        idProject: project?.id,
        idUser: enterpriseUser?.id,
      };
      const response = await getOrderModules(body).unwrap();

      if (response) {
        if (response[0]?.orderModules?.orderModules?.length > 0) {
          dispatch(setOrder(response[0].orderModules.orderModules));
        } else {
          dispatch(setOrder(modulesO));
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    if (props?.project) {
      setIdProjectCard(props?.project?.id);
    }
  }, [props]);

  // If the user entered a route via direct navigation (browser URL), we need to set the url projectId
  useEffect(() => {
    if (projectSelected && !isLoadingEnterpriseUser) {
      dispatch(setCurrentProject(props?.project));
      getVisiblesFromDatabase(props?.project);
      getOrderFromDatabase(props?.project);
      getRole();

      // TODO: implement ability
      setProjectUsers();

      navigate(`../project/${props?.project.id}/dashboard`);
    }
  }, [projectSelected, isLoadingEnterpriseUser]);

  return (
    <Card
      onClick={() => setProjectSelected(true)}
      sx={{
        // TODO: in dark mode, hover should make card color clearer to increase elevation
        borderRadius: 5,
        boxShadow: 3,
        ":hover": {
          transform: "scale3d(1.01, 1.01, 1)",
          boxShadow: 5,
          transition: "transform 0.25s ease-in-out",
        },
      }}
    >
      <CardContent>
        {/* Card content */}
        <Grid
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          {/* Managers */}
          <Grid item xs={12} md={8} sx={{ marginBottom: 1 }}>
            {/* Card Title */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              {/* title */}
              <Tooltip title={props?.project?.projectName}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  noWrap
                  textOverflow="ellipsis"
                >
                  {props?.project?.projectName}
                </Typography>
              </Tooltip>

              {/* bActive */}
              <Chip
                size="small"
                label={tGeneral(
                  props?.project?.bActive ? "active" : "inactive",
                )}
                sx={{
                  fontWeight: "600",
                  marginLeft: 1,
                  backgroundColor: props?.project?.bActive
                    ? "#44b663"
                    : "#b1b7c0",
                  fontSize: 13,
                  color: "white",
                }}
              />
            </Box>

            <Typography fontSize="0.8rem" marginBottom={1}>
              <span style={{ fontWeight: "600" }}>{tGeneral("manager")}: </span>
              {`${
                props?.project?.constructionManager || tGeneral("noManager")
              }`}
            </Typography>

            <Typography fontSize="0.8rem" marginBottom={1}>
              <span style={{ fontWeight: "600" }}>
                {tGeneral("constructionDirector")}:{" "}
              </span>
              {`${
                props?.project?.constructionDirector ||
                tGeneral("noConstructionDirector")
              }`}
            </Typography>

            <Typography fontSize="0.8rem" marginBottom={1}>
              <span style={{ fontWeight: "600" }}>
                {tGeneral("architect")}:{" "}
              </span>
              {`${props?.project?.architect || tGeneral("noArchitect")}`}
            </Typography>

            <Typography fontSize="0.8rem" marginBottom={1}>
              <span style={{ fontWeight: "600" }}>
                {tGeneral("projectEngineer")}:{" "}
              </span>
              {`${
                props?.project?.projectEngineer || tGeneral("noProjectEngineer")
              }`}
            </Typography>

            <Box minHeight="25px">
              {props?.project?.description && (
                <>
                  <Button
                    onClick={(e) => {
                      setModalOpen(true);
                      e.stopPropagation();
                    }}
                    sx={{
                      p: 0,
                      textTransform: "capitalize",
                      display: "flex",
                      justifyItems: "center",
                      textDecoration: "underline",
                      color: "#C68D08",
                      fontSize: "14px",
                    }}
                  >
                    <InfoOutlined sx={{ marginRight: 1 }} />
                    {tGeneral("projectDescription")}
                  </Button>
                  <ProjectDescriptionModal
                    open={modalOpen}
                    handleClose={(e) => {
                      setModalOpen(false);
                      e.stopPropagation();
                    }}
                    title={props?.project?.projectName}
                    description={props?.project?.description}
                  />
                </>
              )}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            sx={{
              height: 160,
              overflow: "hidden",
              alignContent: "center",
              borderRadius: 3,
            }}
          >
            <img
              src={props?.project?.image || logo}
              alt="project-logo"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ProjectCard;
