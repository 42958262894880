import {
  ArrowBack,
  CloudDownload,
  Email,
  Folder,
  Lock,
  Star,
  StarBorder,
} from "@mui/icons-material";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import JSZip from "jszip";
import MaterialTable, { MTableToolbar } from "material-table";
import {
  createRef,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants/projectDashboard";
import { PopUpAlert } from "../../../components/PopUpAlert";
import {
  FileIcon,
  ModalDistributionList,
  TitleWidgets,
} from "../../../components/shared";
import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import {
  useDeleteDocumentMutation,
  useGetDocumentsQuery,
  useToggleFavoriteMutation,
} from "../../../features/project/modules/documents/documentsApiSlice";
import {
  useDeleteProjectFolderMutation,
  useGetProjectFolderDownloadMutation,
  useGetTreeViewQuery,
  useLazyGetFolderPathQuery,
} from "../../../features/project/modules/documents/projectFolderApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
} from "../../../features/project/projectSlice";
import { useDistributionList } from "../../../hooks";
import Can from "../../../utils/can";
import noDocuments from "../../../assets/noDocuments.png";
import DirTree from "./components/DirTree";
import DocumentFilters from "./components/DocumentFilters";
import InfoDrawer from "./components/InfoDrawer";
import ActionMenu from "./components/Menus/ActionMenu";
import AddActionMenu from "./components/Menus/AddActionMenu";
import DeleteModal from "./components/Modals/DeleteModal";
import EditModal from "./components/Modals/EditModal";
import EmailModal from "./components/Modals/EmailModal";
import NewModal from "./components/Modals/NewModal";
import { isVideo } from "../../../utils/video";
import { ModalVideoViewer } from "../../../components/shared/ModalVideoViewer";
import { useSendToEmailFileMutation } from "../../../features/ses/sesApiSlice";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import CaslContext from "../../../utils/caslContext";

function Documents() {
  const addButtonRef = useRef();
  const {
    state: { tourActive, steps, stepIndex, run },
    setState,
  } = useAppTourContext();
  const ability = useContext(CaslContext);
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tDocuments } = useTranslation("documents");
  // Theme / Styling
  const darkMode = useSelector(selectTheme);

  function formatDateNew(utcTime) {
    const months = [
      tGeneral("january"),
      tGeneral("february"),
      tGeneral("march"),
      tGeneral("april"),
      tGeneral("may"),
      tGeneral("june"),
      tGeneral("july"),
      tGeneral("august"),
      tGeneral("september"),
      tGeneral("october"),
      tGeneral("november"),
      tGeneral("december"),
    ];

    const temp = new Date(utcTime);
    // Get the day, month, and year from the date object
    const day = temp.getDate();
    const month = months[temp.getMonth()];
    const year = temp.getFullYear();

    // Get the hour and minute from the date object
    let hour = temp.getHours();
    let minute = temp.getMinutes();

    // Determine whether it is AM or PM
    const ampm = hour >= 12 ? "pm" : "am";

    // Convert to 12-hour time format
    hour %= 12;
    hour = hour ?? 12; // 0 should be converted to 12

    // Add leading zero to minutes if necessary
    minute = minute < 10 ? `0${minute}` : minute;

    // Format the date string
    const formattedDate = `${day} ${month} ${year}, ${hour}:${minute} ${ampm}`;

    // Return the formatted date string
    return formattedDate;
  }

  const navigate = useNavigate();

  // Project and user settings
  const [showFav, setShowFav] = useState(false);
  const [path, setPath] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [video, setVideo] = useState(null);
  const [userList, setUserList] = useState([]);
  const [deleteMultiple, setDeleteMultiple] = useState(false);
  const currentProject = useSelector(selectCurrentProject);
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const { projectUsers, handleCloseDistributionList, isDistributionListOpen } =
    useDistributionList(idProject);
  // Filters
  const [paramsDocs, setParamsDocs] = useState(null);
  const [searchValue] = useState("");

  const [downloadFolder] = useGetProjectFolderDownloadMutation();
  const [getFolderPath] = useLazyGetFolderPathQuery();
  const [
    sendMailFile,
    {
      isLoading: isSendMailLoading,
      isSuccess: isSendEmailSuccess,
      isError: isSendEmailError,
      error: sendEmailError,
    },
  ] = useSendToEmailFileMutation();
  // Set project config and project users
  useEffect(() => {
    if (projectUsers) {
      const usersList = projectUsers
        .filter((obj) => {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.id,
          name: `${user.user.firstName} ${user.user.lastName}`,
          urlAvatar: user.user.urlAvatar,
          idUser: user.user.id,
          email: user.user.email,
        }));
      setUserList(usersList);
    }
    return () => {};
  }, [projectUsers]);

  // - Add Folder Modal
  const [openAddFolder, setOpenAddFolder] = useState(false);
  const handleOpenAddFolder = () => {
    setOpenAddFolder(true);
  };
  const handleCloseAddFolder = () => {
    setOpenAddFolder(false);
  };
  // - Add File Modal
  const [openAddFile, setOpenAddFile] = useState(false);
  const handleOpenAddFile = () => {
    setOpenAddFile(true);
  };
  const handleCloseAddFile = () => {
    setOpenAddFile(false);
  };

  // Handle favorite
  const [
    toggleFavorite,
    { isSuccess: isEditDocumentSuccess, isError: isEditDocumentError },
  ] = useToggleFavoriteMutation();
  useEffect(() => {
    if (isEditDocumentSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDocuments("fileUpdatedSuccessfully"),
      );
    }
    if (isEditDocumentError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorOccurred"));
    }
  }, [isEditDocumentSuccess, isEditDocumentError, tDocuments, tGeneral]);
  // Handle favorite
  const handleFavorite = async (rowData) => {
    const body = {
      idObject: rowData.id,
      module: "documents",
    };

    toggleFavorite(body);
  };

  // Handle Edit/Rename Modal
  const [openEdit, setOpenEdit] = useState(false);
  const [addAnchorEl, setAddAnchorEl] = useState(null);
  const [oldDataToEdit, setOldDataToEdit] = useState({});
  const handleRename = (rowData) => {
    setOpenEdit(true);
    setOldDataToEdit(rowData);
  };

  // Handle Email
  const [openEmail, setOpenEmail] = useState(false);
  const [dataToEmail, setDataToEmail] = useState({});
  const handleEmail = (rowData) => {
    setOpenEmail(true);
    setDataToEmail(rowData);
  };

  const handleDownloadFolder = async (requiredFolder) => {
    const { data } = await downloadFolder({ idFolder: requiredFolder.id });
    const zip = new JSZip();
    async function createZip(documents, folders, zipObject) {
      await Promise.all(
        documents.map(async (doc) => {
          const urlWithCacheBuster = `${
            doc.url
          }?cache_buster=${new Date().getTime()}`;
          const res = await fetch(urlWithCacheBuster, { cache: "no-store" });
          const data = await res.blob();
          const fileName =
            doc.name ?? doc.url.substring(doc.url.lastIndexOf("/") + 1);
          zipObject.file(fileName, data);
        }),
      );
      await Promise.all(
        folders.map(async (folder) => {
          const zipFolder = zip.folder(folder.name);
          await createZip(folder.documents, folder.folders, zipFolder);
        }),
      );
    }
    await createZip(data?.documents, data?.folders, zip);

    zip.generateAsync({ type: "blob" }).then((content) => {
      const downloadLink = document.createElement("a");
      downloadLink.download = `${requiredFolder.name}.zip`;
      downloadLink.href = URL.createObjectURL(content);
      downloadLink.click();
    });
  };

  const handleDownloadFile = (data) => {
    fetch(data.url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = data.name;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const handleAddPath = (newFolder, replace = false) => {
    if (!newFolder) {
      setPath([]);
      return;
    }
    const newPath = [...path];
    const index = newPath.findIndex((p) => p.id === newFolder.id);
    if (replace) {
      newPath.splice(newPath.length - 1, 1, newFolder);
    } else if (index === -1) {
      newPath.push(newFolder);
    }
    setPath(newPath);
  };

  // Table
  const tableRef = createRef();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const columns = [
    {
      title: tGeneral("name"),
      field: "name",
      render: (rowData) => {
        const filetype = rowData.name
          .substring(rowData.name.indexOf(".") + 1)
          .toLowerCase();
        return (
          <Box
            display="flex"
            component="div"
            columnGap="5px"
            sx={{ cursor: "pointer" }}
            onClick={async () => {
              if (rowData.isFolder) {
                const newPath = await getFolderPath(rowData.id);
                setPath(newPath.data);
                setParamsDocs({ ...paramsDocs, search: "" });
              } else {
                const tempFile = {
                  ...rowData,
                  type: filetype,
                };
                if (isVideo(tempFile)) {
                  setVideo(tempFile);
                } else {
                  setPdfUrl(rowData.url);
                }
              }
            }}
          >
            <FileIcon extension={rowData.isFolder ? "folder" : filetype} />
            {rowData.lock && <Lock />}
            <Tooltip title={rowData.name}>
              <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {rowData.name}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "300px",
        maxWidth: "300px",
      },
    },
    {
      title: tGeneral("createdOn"),
      field: "createDate",
      type: "date",
      editable: "never",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
        overflow: "hidden",
      },
      render: (rowData) => {
        return rowData.createDate ? formatDateNew(rowData.createDate) : "N/A";
      },
    },
    {
      title: tGeneral("lastEdit"),
      field: "lastEdit",
      editable: "never",
      type: "date",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
        overflow: "hidden",
      },
      render: (rowData) =>
        rowData.lastEdit ? formatDateNew(rowData.lastEdit) : "N/A",
    },
    {
      hidden: false,
      title: tGeneral("actions"),
      editable: "never",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
        overflow: "hidden",
      },
      render: (rowData) => (
        <ButtonGroup data-tour="docs-actions-1">
          <Box sx={{ minWidth: "200px" }}>
            {/* Favorite */}
            <IconButton
              type="submit"
              title={tGeneral("favorite")}
              onClick={() => handleFavorite(rowData)}
              sx={{
                // eslint-disable-next-line no-nested-ternary
                color: rowData.favorite
                  ? "#FBCB04"
                  : darkMode
                    ? "white"
                    : "black",
                opacity: rowData.favorite ? 1 : 0.1,
                ":hover": {
                  color: "#FBCB04",
                  opacity: 1,
                },
                m: [0, 0, 0, 0],
              }}
              data-tour="docs-actions-2"
            >
              <Star />
            </IconButton>

            {/* Download */}
            <IconButton
              type="submit"
              title={tGeneral("download")}
              onClick={() => {
                if (rowData.isFolder) {
                  handleDownloadFolder(rowData);
                } else {
                  handleDownloadFile(rowData);
                }
              }}
              sx={{
                color: darkMode ? "white" : "black",
                opacity: 0.1,
                ":hover": {
                  color: "#FBCB04",
                  opacity: 1,
                },
                m: [0, 0, 0, 0],
              }}
              data-tour="docs-actions-3"
            >
              <CloudDownload />
            </IconButton>

            {/* Email */}
            {!rowData.isFolder && (
              <IconButton
                // disabled
                type="submit"
                title={tGeneral("email")}
                onClick={() => {
                  handleEmail(rowData);
                }}
                sx={{
                  color: darkMode ? "white" : "black",
                  opacity: 0.1,
                  ":hover": {
                    color: "#FBCB04",
                    opacity: 1,
                  },
                  m: [0, 0, 0, 0],
                }}
                data-tour="docs-actions-4"
              >
                <Email />
              </IconButton>
            )}

            {/* Edit */}
            <Can I="edit" a="documents">
              <IconButton
                type="submit"
                title={tGeneral("edit")}
                onClick={() => handleRename(rowData)}
                sx={{
                  color: darkMode ? "white" : "black",
                  opacity: 0.1,
                  ":hover": {
                    color: "#FBCB04",
                    opacity: 1,
                  },
                  m: [0, 0, 0, 0],
                }}
                data-tour="docs-actions-5"
              >
                <Edit />
              </IconButton>
            </Can>
          </Box>
          <ActionMenu
            project={currentProject}
            rowData={rowData}
            view="general"
            path={path}
          />
        </ButtonGroup>
      ),
    },
  ];
  // Fetch Tree View
  const {
    data: treeView,
    isSuccess: isSuccessTreeView,
    isLoading: isLoadingTreeView,
    isFetching: isFetchingTreeView,
    refetch: refetchTreeView,
  } = useGetTreeViewQuery(idProject);
  useEffect(() => {
    if (!isSuccessTreeView) {
      refetchTreeView();
    }
    return () => {};
  }, [isSuccessTreeView, refetchTreeView]);

  // Fetch Root folders and docs
  //  Fetch Documents
  const { data: documents, isLoading: isLoadingDocuments } =
    useGetDocumentsQuery({
      idProject,
      params: {
        idFolder: path.length > 0 ? path[path.length - 1]?.id : null,
        favorite: showFav,
        createdBy: paramsDocs?.createdBy ?? null,
        startDate: paramsDocs?.createdOn ? paramsDocs?.createdOn[0] : null,
        endDate: paramsDocs?.createdOn ? paramsDocs?.createdOn[0] : null,
        recent: paramsDocs?.recent ?? null,
      },
      search: paramsDocs?.search ?? null,
    });

  // Handle multiple row delete
  const [selectedRows, setSelectedRows] = useState([]);
  const [
    deleteProjectFolder,
    {
      isSuccess: isDeleteProjectFolderSuccess,
      isError: isDeleteProjectFolderError,
      isLoading: isDeleteProjectFolderLoading,
    },
  ] = useDeleteProjectFolderMutation();
  useEffect(() => {
    if (isDeleteProjectFolderSuccess && !deleteMultiple) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDocuments("folderDeletedSuccessfully"),
      );
    }
    if (isDeleteProjectFolderError && !deleteMultiple) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorOccurred"));
    }
  }, [
    isDeleteProjectFolderSuccess,
    isDeleteProjectFolderError,
    deleteMultiple,
    tDocuments,
    tGeneral,
  ]);
  const [
    deleteDocument,
    {
      isSuccess: isDeleteDocumentSuccess,
      isError: isDeleteDocumentError,
      isLoading: isDeleteDocumentLoading,
    },
  ] = useDeleteDocumentMutation();
  useEffect(() => {
    if (isDeleteDocumentSuccess && !deleteMultiple) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDocuments("fileDeletedSuccessfully"),
      );
    }
    if (isDeleteDocumentError && !deleteMultiple) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorOccurred"));
    }
  }, [
    isDeleteDocumentSuccess,
    isDeleteDocumentError,
    deleteMultiple,
    tDocuments,
    tGeneral,
  ]);

  useEffect(() => {
    if (isSendEmailSuccess) {
      PopUpAlert("success", tGeneral("sent"), tGeneral("sentMessage"));
      setOpenEmail(false);
    }
    if (isSendEmailError) {
      PopUpAlert("error", tGeneral("error"), sendEmailError.message);
      setOpenEmail(false);
    }
  }, [isSendEmailError, isSendEmailSuccess, sendEmailError, tGeneral]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };
  const handleMultiDelete = async () => {
    setDeleteMultiple(true);
    const folders = selectedRows.reduce((folders, item) => {
      if (item.isFolder) folders.push(item.id);
      return folders;
    }, []);
    const docs = selectedRows.reduce((docs, item) => {
      if (!item.isFolder) docs.push(item.id);
      return docs;
    }, []);
    let deletedFolders;
    let deletedFiles;
    if (folders.length > 0) {
      deletedFolders = await deleteProjectFolder({ ids: folders }).unwrap();
    }
    if (docs.length > 0) {
      deletedFiles = await deleteDocument({ ids: docs }).unwrap();
    }

    if (deletedFolders || deletedFiles) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDocuments("fileDeletedSuccessfully"),
      );
    } else {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorOccurred"));
    }

    setSelectedRows([]);
    setDeleteModalOpen(false);
    setDeleteMultiple(false);
  };

  const documentsData = useMemo(() => {
    if (documents) {
      return documents
        .filter(
          (doc) =>
            Boolean(
              doc.name
                .toLowerCase()
                .match(new RegExp(searchValue.toLowerCase(), "g")),
            ) || searchValue === "",
        )
        .map((doc) => ({ ...doc }))
        .sort((a, b) => {
          return a.name.localeCompare(b.name, "en", {
            numeric: true,
            ignorePunctuation: true,
          });
        });
    }
    return [];
  }, [documents, searchValue]);

  const handleBackPath = () => {
    const currPath = [...path];
    currPath.pop();
    setPath(currPath);
  };

  const isMobile = useMediaQuery("(max-width:820px)");

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  const handleNavigateTree = (dir) => {
    const index = path.findIndex((folder) => folder.id === dir.id);
    if (index < path.length - 1) {
      const newPath = path.filter((_, i) => i <= index);
      setPath(newPath);
    }
  };

  const handleOpenAddMenu = (e) => {
    setAddAnchorEl(e.currentTarget);
  };

  const handleSendEmail = async (selectedUsers, emailBody) => {
    const body = {
      message: emailBody.body,
      subject: emailBody.subject,
      fileLink: emailBody.url,
    };
    if (selectedUsers?.length > 0) {
      body.recipients = selectedUsers;
    }
    sendMailFile(body);
  };
  useEffect(() => {
    if (!tourActive || !steps || !addButtonRef.current) return;

    if (
      (stepIndex === 0 || stepIndex === 1) &&
      (currentGuideMe === "docs-add-file" ||
        currentGuideMe === "docs-add-folder")
    ) {
      setAddAnchorEl(addButtonRef.current);

      if (!run) {
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    } else {
      setAddAnchorEl(null);
    }

    if (stepIndex >= 2 && stepIndex < 5 && currentGuideMe === "docs-add-file") {
      handleOpenAddFile();
    } else {
      handleCloseAddFile();
    }

    if (steps.length - 1 === stepIndex && currentGuideMe === "docs-add-file") {
      handleCloseAddFolder();
    }

    if (
      stepIndex >= 2 &&
      stepIndex < 5 &&
      currentGuideMe === "docs-add-folder"
    ) {
      handleOpenAddFolder();
    } else {
      handleCloseAddFolder();
    }

    if (
      steps.length - 1 === stepIndex &&
      currentGuideMe === "docs-add-folder"
    ) {
      handleCloseAddFolder();
    }
  }, [
    stepIndex,
    tourActive,
    steps,
    addButtonRef,
    run,
    currentGuideMe,
    setState,
  ]);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 2 &&
      documentsData &&
      currentGuideMe === "docs-file-info" &&
      steps
    ) {
      if (documentsData.length > 0) {
        navigate(`./${documentsData[0].id}`);
      }
    }
  }, [tourActive, stepIndex, currentGuideMe, documentsData, steps, navigate]);

  return (
    <>
      <TitleWidgets
        moduleName="documents"
        title={tGeneral("documents")}
        supportDisabled
        addFunction={handleOpenAddMenu}
        customActions={[
          {
            label: tGeneral("onlyFavorites"),
            icon: showFav ? <Star /> : <StarBorder />,

            onClick: () => {
              setShowFav((prev) => !prev);
            },
          },
        ]}
        distributionListName={DISTRIBUTION_LIST_NAMES.documents}
        enableSupport
        addNameTour="docs-add-1"
        ref={addButtonRef}
        showDelete={
          Object.keys(selectedRows).length > 0 &&
          ability.can("delete", "documents")
        }
        onDelete={() => setDeleteModalOpen(true)}
      />
      <AddActionMenu
        isOpen={Boolean(addAnchorEl)}
        anchorEl={addAnchorEl}
        handleClose={() => setAddAnchorEl(null)}
        handleNewFolder={handleOpenAddFolder}
        handleNewFile={handleOpenAddFile}
      />
      {/* Filters */}
      <DocumentFilters
        paramsDocs={paramsDocs}
        setParamsDocs={setParamsDocs}
        userList={userList}
      />
      {/* File Manager */}
      <Box display="flex" height="78vh" mt="10px">
        <Box flex={1} sx={{ overflowY: "auto", height: "100%" }}>
          <DirTree
            addPath={handleAddPath}
            selectedFolders={path}
            search={paramsDocs?.search ?? null}
          />
        </Box>
        <Box flex={4}>
          <Box display="flex" alignItems="center" height="50px">
            {path.length > 0 && (
              <IconButton onClick={handleBackPath}>
                <ArrowBack />
              </IconButton>
            )}
            <Folder /> /{" "}
            <Button
              variant="text"
              color="secondary"
              sx={{
                textTransform: "none",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => setPath([])}
            >
              {currentProject?.projectName}
            </Button>
            {path.length > 0 &&
              path?.map((dir) => (
                <Box display="flex" alignItems="center">
                  /
                  <Button
                    variant="text"
                    color="secondary"
                    sx={{
                      textTransform: "none",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={() => handleNavigateTree(dir)}
                  >
                    {dir.name}
                  </Button>
                </Box>
              ))}
          </Box>
          {documentsData?.length > 0 ? (
            <MaterialTable
              tableRef={tableRef}
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      height: "0px",
                    }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              icons={tableIcons}
              columns={columns}
              data={documentsData}
              localization={tableLocalization}
              options={{
                toolbar: false,
                selection: true,
                showTitle: false,
                actionsColumnIndex: -1,
                search: false,
                pageSize: 100,
                pageSizeOptions: [10, 20, 30, 100],
                emptyRowsWhenPaging: false,
                headerStyle: {
                  height: "60.5px",
                },
                rowStyle: {
                  height: "60.5px",
                  width: "auto",
                },
                // actionsCellStyle: { direction: "rtl" },
                maxBodyHeight: "65vh",
                padding: "dense",
                overflowY: "auto",
              }}
              style={{ borderRadius: "10px", padding: 0, margin: 0 }}
              isLoading={
                isLoadingDocuments ||
                isLoadingTreeView ||
                isFetchingTreeView ||
                isDeleteDocumentLoading ||
                isDeleteProjectFolderLoading
              }
              onSelectionChange={(rows) => setSelectedRows(rows)}
            />
          ) : (
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 5,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: darkMode,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {tDocuments("noDocumentsCreated")}
                </Typography>
                <Can I="add" a="documents">
                  <>
                    <Typography
                      sx={{
                        fontSize: 15,
                        color: darkMode ? "white" : "#B0B7C1",
                        textAlign: "center",
                        mt: 2,
                        width: "100%",
                      }}
                    >
                      {tDocuments("addDocumentsPrompt")}
                    </Typography>
                    {/* <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        my="15px"
                        width="100%"
                      >
                        <Box sx={{
                          color: "black",
                          py: "0.5rem",
                        }}>
                          {button}
                        </Box>
                      </Box> */}
                  </>
                </Can>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "right",
                    margin: 0,
                  }}
                >
                  <img
                    src={noDocuments}
                    alt="emptyImage"
                    style={{ width: isMobile ? "90%" : "30%" }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {/* DistList modal */}
      {isDistributionListOpen && (
        <ModalDistributionList
          isOpen={isDistributionListOpen}
          onClose={handleCloseDistributionList}
          idProject={idProject}
          distributionListName={DISTRIBUTION_LIST_NAMES.documents}
        />
      )}
      {openAddFolder && (
        <NewModal
          isOpen={openAddFolder}
          onClose={handleCloseAddFolder}
          action="folder"
          path={path}
        />
      )}
      {openAddFile && (
        <NewModal
          isOpen={openAddFile}
          allowFolders
          onClose={handleCloseAddFile}
          action="file"
          path={path}
        />
      )}
      <EditModal
        isOpen={openEdit}
        onClose={() => setOpenEdit(false)}
        oldData={oldDataToEdit}
      />
      {deleteModalOpen && (
        <DeleteModal
          isOpen={deleteModalOpen}
          onClose={handleCloseDelete}
          handleDelete={handleMultiDelete}
          action="multi"
        />
      )}
      <EmailModal
        isOpen={openEmail}
        data={dataToEmail}
        onClose={() => setOpenEmail(false)}
        users={userList}
        onSend={(selectedUsers, emailBody) => {
          handleSendEmail(selectedUsers, emailBody);
        }}
        isLoading={isSendMailLoading}
      />
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
          file={{ url: pdfUrl }}
        />
      )}
      {Boolean(video) && (
        <ModalVideoViewer
          isOpen={Boolean(video)}
          onClose={() => setVideo(null)}
          file={video}
        />
      )}
      <InfoDrawer closeInfoDrawer={() => navigate("./..")} path={path} />
    </>
  );
}

export default Documents;
