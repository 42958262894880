import { datadogRum } from "@datadog/browser-rum";
import CircleSharpIcon from "@mui/icons-material/CircleSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Box,
  Button,
  Card,
  CardActions,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { v4 } from "uuid";
import TaskCategorySelect from "../../components/projectDashboard/Tasks/TaskCategorySelect";
import { CreateFormUsersSide, FormHeader } from "../../components/shared";
import { NestedLocationSelect } from "../../components/shared/NestedLocationSelect";
import { useAppTourContext } from "../../components/supportButton/context/context";
import { DISTRIBUTION_LIST_NAMES } from "../../constants";
import {
  useCreateInspectionMutation,
  useEditInspectionMutation,
  useGetInspectionByIdQuery,
} from "../../features/project/modules/inspections/apiSlice";
import { selectCurrentInspection } from "../../features/project/modules/inspections/slice";
import {
  useCreateInspectionTemplateMutation,
  useGetActiveInspectionsTemplatesQuery,
} from "../../features/project/modules/inspectionsTemplates/apiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProjectConfig,
} from "../../features/project/projectSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
import Can from "../../utils/can";
import TextFieldWrapper from "./Shared/TextFieldWrapper";

const alert = (message, type) => {
  const Toast = Swal.mixin({
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    toast: true,
  });
  Toast.fire({
    icon: type,
    title: message,
  });
};

function SaveAsTemplate({ sections, handleClose, status }) {
  const { t: tGeneral } = useTranslation("general");

  const { idProject, idEnterprise } = useParams();

  const [inProcess, setInProcess] = useState(false);
  const [createInspectionTemplate] = useCreateInspectionTemplateMutation();

  const formContext = useForm({
    defaultValues: {
      title: "",
      code: "",
      category: "",
      description: "",
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formContext;

  const onSubmit = async (data) => {
    setInProcess(true);
    try {
      const inspectionTemplateInput = {
        idProject,
        title: data.title,
        code: data.code,
        category: data.category,
        description: data.description,
        sections,
      };
      const inspectionTemplateOutput = await createInspectionTemplate(
        inspectionTemplateInput,
      ).unwrap();
      if (!inspectionTemplateOutput) {
        throw new Error("!inspectionTemplateOutput");
      }
      alert(`Template ${tGeneral("createdSuccessfully")}`, "success");
      handleClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      alert(`${tGeneral("cantCreate")} Template`, "error");
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Template Create - Error",
          stack: error.stack ? error.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
    setInProcess(false);
  };

  return (
    <Dialog fullWidth maxWidth="xl" onClose={handleClose} open={status}>
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              backgroundColor: "#ffc900",
              color: "white",
              m: 0,
              p: 2,
            }}
          >
            {tGeneral("saveAsTemplate")}
          </DialogTitle>
          <DialogContent dividers>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" flexDirection="row" gap={2}>
                {/* Title */}
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.title}
                      fullWidth
                      helperText={errors.title && tGeneral("fieldRequired")}
                      label={tGeneral("title")}
                      type="text"
                      variant="outlined"
                    />
                  )}
                  rules={{ required: true }}
                />

                {/* Code */}
                <Controller
                  control={control}
                  name="code"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.code}
                      fullWidth
                      helperText={errors.code && tGeneral("fieldRequired")}
                      label={tGeneral("code")}
                      type="text"
                      variant="outlined"
                    />
                  )}
                  rules={{ required: true }}
                />

                {/* Category */}
                <Controller
                  control={control}
                  name="category"
                  render={({ field }) => (
                    <TaskCategorySelect
                      error={errors.category}
                      helperText={errors.category && tGeneral("fieldRequired")}
                      selectedCategory={field.value}
                      onChange={field.onChange}
                    />
                  )}
                  rules={{ required: true }}
                />
              </Box>

              {/* Description */}
              <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
                width={1}
              >
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.description}
                      fullWidth
                      helperText={
                        errors.description && tGeneral("fieldRequired")
                      }
                      label={tGeneral("description")}
                      multiline
                      rows={1}
                    />
                  )}
                  rules={{ required: true }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Box display="flex" justifyContent="flex-end" gap={2} width={1}>
              <Button
                color="secondary"
                onClick={() => handleClose()}
                px={6}
                py={2}
                size="small"
                variant="outlined"
              >
                {tGeneral("cancel")}
              </Button>
              <Button
                disabled={inProcess}
                px={6}
                py={2}
                type="submit"
                size="small"
                variant="contained"
              >
                {tGeneral("save")}
              </Button>
            </Box>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}

function Cancel({ status, handleClose }) {
  const { t: tGeneral } = useTranslation("general");

  return (
    <div>
      <Dialog open={status} onClose={() => handleClose(false)}>
        <DialogTitle
          sx={{
            backgroundColor: "#ffc900",
            color: "white",
          }}
        >
          {tGeneral("cancel")}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{tGeneral("cancelPrompt")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{tGeneral("No")}</Button>
          <Button autoFocus onClick={() => handleClose(true)}>
            {tGeneral("Yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function InspectionsFormSection({ control, errors, field, getValues, index }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tInspections } = useTranslation("inspections");

  const [addItemTitle, setAddItemTitle] = useState("");
  const [addItemDescription, setAddItemDescription] = useState("");
  const [addItemStatus, setAddItemStatus] = useState(false);

  const { append, fields, move, remove, update } = useFieldArray({
    control,
    name: `sections[${index}].items`,
  });

  if (!field.isExpanded) {
    return null;
  }

  return (
    <Box pl={4}>
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          if (destination) {
            move(source.index, destination.index);
          }
        }}
      >
        <Droppable droppableId="inspections-form-items">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              display="flex"
              flexDirection="column"
              ref={provided.innerRef}
              width={1}
            >
              {fields.map((f, i) => {
                return (
                  <Draggable
                    draggableId={`sections.${index}.items.${i}`}
                    index={i}
                    key={f.id}
                  >
                    {(provided) => {
                      return f.isEditable ? (
                        <Box
                          {...provided.draggableProps}
                          alignItems="center"
                          backgroundColor="#f8f8f8"
                          borderRadius="5px"
                          borderTop="1px solid #E1E1E1"
                          display="flex"
                          flexDirection="row"
                          gap={2}
                          key={`draggable-${f.id}`}
                          pb={2}
                          pt={2}
                          ref={provided.innerRef}
                          textAlign="center"
                          width={1}
                        >
                          <Box
                            alignItems="stretch"
                            backgroundColor="#f8f8f8"
                            borderRadius="5px"
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            textAlign="center"
                            width={1}
                          >
                            <Box alignItems="center" display="flex" gap={1}>
                              <Box
                                fontSize="14px"
                                fontWeight="bold"
                                textAlign="right"
                                width={100}
                              >
                                {index + 1}.{i + 1}
                              </Box>
                              <Controller
                                control={control}
                                name={`sections.${index}.items.${i}.title`}
                                render={({ field }) => (
                                  <TextFieldWrapper
                                    showCharCount
                                    {...field}
                                    defaultValue={getValues(
                                      `sections.${index}.items.${i}.title`,
                                    )}
                                    error={
                                      errors.sections?.[index]?.items?.[i]
                                        ?.title
                                    }
                                    fullWidth
                                    helperText={
                                      errors.sections?.[index]?.items?.[i]
                                        ?.title && tGeneral("fieldRequired")
                                    }
                                    label={tGeneral("title")}
                                    size="small"
                                    sx={{
                                      backgroundColor: "#fff",
                                      flex: 1,
                                      m: 0,
                                    }}
                                    type="text"
                                    variant="outlined"
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </Box>
                            <Box alignItems="center" display="flex" gap={1}>
                              <Box
                                fontSize="14px"
                                fontWeight="bold"
                                textAlign="right"
                                width={100}
                              >
                                {tGeneral("description")}
                              </Box>
                              <Controller
                                control={control}
                                name={`sections.${index}.items.${i}.description`}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    defaultValue={getValues(
                                      `sections.${index}.items.${i}.description`,
                                    )}
                                    error={
                                      errors.sections?.[index]?.items?.[i]
                                        ?.description
                                    }
                                    fullWidth
                                    helperText={
                                      errors.sections?.[index]?.items?.[i]
                                        ?.description &&
                                      tGeneral("fieldRequired")
                                    }
                                    label={tGeneral("description")}
                                    size="small"
                                    sx={{
                                      backgroundColor: "#fff",
                                      flex: 1,
                                      m: 0,
                                    }}
                                    type="text"
                                    variant="outlined"
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </Box>
                          </Box>
                          <Button
                            onClick={() =>
                              update(i, {
                                ...f,
                                isEditable: !f.isEditable,
                                title: getValues(
                                  `sections.${index}.items.${i}.title`,
                                ),
                                description: getValues(
                                  `sections.${index}.items.${i}.description`,
                                ),
                              })
                            }
                            sx={{ alignSelf: "flex-start", p: 1 }}
                            type="button"
                            variant="contained"
                          >
                            {tGeneral("save")}
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          {...provided.draggableProps}
                          backgroundColor="#f8f8f8"
                          borderRadius="5px"
                          borderTop="1px solid #E1E1E1"
                          display="flex"
                          flexDirection="column"
                          gap={2}
                          key={`draggable-${f.id}`}
                          ref={provided.innerRef}
                          py={2}
                          width={1}
                        >
                          <Box
                            alignItems="flex-start"
                            display="flex"
                            flexDirection="row"
                            gap={2}
                            width={1}
                          >
                            <Box
                              {...provided.dragHandleProps}
                              alignItems="center"
                              display="flex"
                              flexDirection="column"
                              cursor="pointer"
                            >
                              <DragIndicatorIcon color="disabled" />
                            </Box>
                            <Box
                              alignItems="flex-start"
                              display="flex"
                              flexDirection="row"
                              gap={2}
                              width={1}
                            >
                              <Box
                                alignItems="flex-start"
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                width="70px"
                              >
                                <Typography
                                  align="right"
                                  sx={{ flex: 1, width: "100%" }}
                                >
                                  {index + 1}.{i + 1}
                                </Typography>
                              </Box>
                              <Box
                                alignItems="flex-start"
                                display="flex"
                                flexDirection="column"
                                gap={0}
                                width={1}
                              >
                                <Typography align="left" sx={{ flex: 1 }}>
                                  {getValues(
                                    `sections.${index}.items.${i}.title`,
                                  )}
                                </Typography>
                                <Typography
                                  align="left"
                                  sx={{ flex: 1, fontSize: "12px" }}
                                >
                                  {getValues(
                                    `sections.${index}.items.${i}.description`,
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            <EditIcon
                              onClick={() =>
                                update(i, { ...f, isEditable: !f.isEditable })
                              }
                              sx={{ cursor: "pointer" }}
                            />
                            <DeleteIcon
                              color="error"
                              onClick={() => remove(i)}
                              sx={{ cursor: "pointer" }}
                            />
                            <KeyboardArrowDownIcon sx={{ opacity: 0 }} />
                          </Box>
                        </Box>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      {addItemStatus && (
        <Box
          alignItems="flex-start"
          backgroundColor="#f8f8f8"
          borderRadius="5px"
          borderTop="1px solid #E1E1E1"
          display="flex"
          flexDirection="row"
          gap={2}
          pb={2}
          pt={2}
          textAlign="center"
          width={1}
        >
          <Box
            alignItems="stretch"
            backgroundColor="#f8f8f8"
            borderRadius="5px"
            display="flex"
            flexDirection="column"
            gap={1}
            textAlign="center"
            width={1}
          >
            <Box alignItems="center" display="flex" gap={1}>
              <Box
                fontSize="14px"
                fontWeight="bold"
                textAlign="right"
                width={100}
              >
                {index + 1}.{fields.length + 1}
              </Box>
              <TextFieldWrapper
                fullWidth
                showCharCount
                label={tGeneral("title")}
                onChange={(value) => setAddItemTitle(value)}
                size="small"
                sx={{ backgroundColor: "#fff", flex: 1, m: 0 }}
                type="text"
                value={addItemTitle}
                variant="outlined"
              />
            </Box>
            <Box alignItems="center" display="flex" gap={1}>
              <Box
                fontSize="14px"
                fontWeight="bold"
                textAlign="right"
                width={100}
              >
                {tGeneral("description")}
              </Box>
              <TextField
                fullWidth
                label={tGeneral("description")}
                onChange={(event) => setAddItemDescription(event.target.value)}
                size="small"
                sx={{ backgroundColor: "#fff", flex: 1, m: 0 }}
                type="text"
                value={addItemDescription}
                variant="outlined"
              />
            </Box>
          </Box>
          <Button
            disabled={addItemTitle === ""}
            onClick={() => {
              append({
                title: addItemTitle,
                description: addItemDescription,
                id: v4(),
              });
              setAddItemStatus(false);
              setAddItemTitle("");
              setAddItemDescription("");
            }}
            sx={{ alignSelf: "flex-start", px: 2, py: 1 }}
            type="button"
            variant="contained"
          >
            {tGeneral("add")}
          </Button>
          <Button
            color="error"
            onClick={() => {
              setAddItemStatus(false);
              setAddItemTitle("");
              setAddItemDescription("");
            }}
            sx={{ alignSelf: "flex-start", px: 2, py: 1 }}
            type="button"
            variant="outlined"
          >
            {tGeneral("cancel")}
          </Button>
        </Box>
      )}
      <Box
        backgroundColor="#f8f8f8"
        borderRadius="5px"
        borderTop="1px solid #E1E1E1"
        color="#009dff"
        onClick={() => setAddItemStatus(true)}
        pt={2}
        sx={{ cursor: "pointer" }}
        width={1}
      >
        + {tInspections("addItem")}
      </Box>
    </Box>
  );
}

function InspectionsForm() {
  const navigate = useNavigate();

  const { t: tGeneral } = useTranslation("general");
  const { t: tInspections } = useTranslation("inspections");
  const { t: tStatus } = useTranslation("status");

  const currentUser = useSelector(selectCurrentUser);
  const { idProject, idEnterprise } = useParams();

  const current = useSelector(selectCurrentInspection);
  const projectConfig = useSelector(selectCurrentProjectConfig);

  const [template, setTemplate] = useState("");

  const [addSectionTitle, setAddSectionTitle] = useState("");
  const [addSectionStatus, setAddSectionStatus] = useState(false);

  const [inProcess, setInProcess] = useState(false);

  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [cancel, setCancel] = useState(false);

  const { data: inspectionsTemplates } = useGetActiveInspectionsTemplatesQuery(
    { idProject },
    {},
  );

  const validationSchema = yup.object({
    title: yup.string("Enter a valid title").required("This field is required"),
    assignedTo: yup
      .array()
      .of(yup.string())
      .min(1, "This field is required")
      .required("fieldRequired"),
    deliveryDate: yup.date().required(tGeneral("fieldRequired")),
  });

  const { data: inspection } = useGetInspectionByIdQuery(
    { idInspection: current?.id, idProject },
    {
      skip: !current,
    },
  );
  const formContext = useForm({
    defaultValues: {
      ...inspection,
      title: inspection?.title || "",
      deliveryDate: inspection?.deliveryDate?.slice(0, 10),
      status: inspection?.status || "in_revision",
      bPrivate: inspection?.bPrivate || false,
      location: inspection?.idLocation,
      assignedTo: inspection?.assignedTo || [],
      creators:
        inspection?.creators.length > 0
          ? inspection.creators
          : [currentUser.id],
      distributionList: inspection?.distributionList
        ? inspection?.distributionList
        : projectConfig.inspectionDistributionList,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
  } = formContext;

  const sections = watch("sections") ?? [];

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const [createInspection] = useCreateInspectionMutation();
  const [editInspection] = useEditInspectionMutation();

  const inspectionStatuses = useMemo(() => {
    if (current?.status === "draft") {
      return [
        {
          value: "draft",
          label: tGeneral("draft").toUpperCase(),
          color: "#ffc900",
        },
        {
          value: "in_revision",
          label: tStatus("in_revision").toUpperCase(),
          color: "#03cafc",
        },
      ];
    }
    return [
      {
        value: "draft",
        label: tGeneral("draft").toUpperCase(),
        color: "#ffc900",
      },
      {
        value: "open",
        label: tGeneral("open_action").toUpperCase(),
        color: "#5ce381",
      },
      {
        value: "in_revision",
        label: tStatus("in_revision").toUpperCase(),
        color: "#03cafc",
      },
    ];
  }, [current, tGeneral, tStatus]);

  const onSubmit = async (data) => {
    setInProcess(true);
    try {
      if (!data.id) {
        const inspectionInput = {
          idProject,
          title: data.title,
          deliveryDate: data.deliveryDate,
          status: data.status,
          bPrivate: data.bPrivate,
          location: data.location,
          sections: data.sections.map(({ id, ...s }) => ({
            ...s,
            items: s.items.map(({ id, ...i }) => i),
          })),
          creators: data.creators,
          assignedTo: data.assignedTo,
          distributionList: data.distributionList,
        };
        const inspectionOutput =
          await createInspection(inspectionInput).unwrap();
        if (!inspectionOutput) {
          throw new Error("!inspectionOutput");
        }
        alert(` ${tGeneral("createdSuccessfully")}`, "success");
        navigate(-1);
      } else {
        const body = {
          id: data.id,
          idProject,
          title: data.title,
          deliveryDate: data.deliveryDate,
          status: data.status,
          bPrivate: data.bPrivate,
          location: data.location,
          sections: data.sections,
          creators: data.creators,
          assignedTo: data.assignedTo,
          distributionList: data.distributionList,
        };
        const response = await editInspection(body).unwrap();
        if (!response) {
          throw new Error("!inspectionOutput");
        }
        alert(` ${tGeneral("updatedSuccessfully")}`, "success");
        navigate(-1);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      if (data.id) {
        alert(`${tGeneral("cantCreate")} Inspection`, "error");
      } else {
        alert(`${tGeneral("cantEdit")} Inspection`, "error");
      }
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Create/Update - Error",
          stack: error.stack ? error.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
    setInProcess(false);
  };

  const { append, move, remove, update } = useFieldArray({
    control,
    name: "sections",
  });

  useEffect(() => {
    if (tourActive && stepIndex === 1 && currentGuideMe === "inspections-add") {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, setState]);

  useEffect(() => {
    if (inspection) {
      reset({
        ...inspection,
        title: inspection?.title || "",
        deliveryDate: inspection?.deliveryDate?.slice(0, 10),
        status: inspection?.status || "in_revision",
        bPrivate: inspection?.bPrivate || false,
        location: inspection?.idLocation,
        creators:
          inspection?.creators.length > 0
            ? inspection.creators
            : [currentUser.id],
        distributionList: inspection?.distributionList
          ? inspection?.distributionList
          : projectConfig.inspectionDistributionList,
      });
    }
  }, [reset, currentUser, inspection, projectConfig]);

  if (current && !inspection) {
    return false;
  }

  return (
    <Can I="edit" a="inspections" passThrough>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        width={1}
        sx={{ height: "96vh" }}
        overflow="hidden"
      >
        <FormProvider {...formContext}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              height: "100%",
            }}
          >
            {/* Title */}
            <FormHeader
              isEditing={Boolean(inspection)}
              title={
                inspection
                  ? `${tInspections("edit")}`
                  : `${tInspections("add")}`
              }
              onBack={() => navigate(-1)}
              isSubmit
              dataTourNameButton={inspection ? "" : "add-inspection-11"}
            />
            <Box
              display="flex"
              overflow="hidden"
              columnGap="10px"
              height="100%"
            >
              <Box
                flex={2}
                display="flex"
                flexDirection="column"
                overflow="hidden"
                pl="10px"
                rowGap="10px"
              >
                <Box display="flex" flexDirection="row" gap={2} py="10px">
                  {/* Title */}
                  <Controller
                    control={control}
                    name="title"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={errors.title}
                        fullWidth
                        helperText={errors.title && tGeneral("fieldRequired")}
                        label={tGeneral("title")}
                        type="text"
                        variant="outlined"
                        data-tour="add-inspection-2"
                      />
                    )}
                    rules={{ required: true }}
                  />

                  {/* Delivery Date */}
                  <Controller
                    control={control}
                    name="deliveryDate"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={errors.deliveryDate}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        helperText={
                          errors.deliveryDate && tGeneral("fieldRequired")
                        }
                        label={tGeneral("deliveryDate")}
                        type="date"
                        variant="outlined"
                        data-tour="add-inspection-3"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </Box>

                <Box display="flex" flexDirection="row" gap={2}>
                  {/* Status */}
                  <Controller
                    control={control}
                    name="status"
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="status">
                          {tGeneral("status")}
                        </InputLabel>
                        <Select
                          {...field}
                          label={tGeneral("status")}
                          labelId="status"
                          required
                          select="true"
                          type="text"
                          variant="outlined"
                          data-tour="add-inspection-4"
                        >
                          {inspectionStatuses.map((option) =>
                            !current && option.value === "open" ? null : (
                              <MenuItem key={option.value} value={option.value}>
                                <Box display="flex" alignItems="center">
                                  <CircleSharpIcon
                                    sx={{ color: option.color }}
                                  />
                                  <Typography fontSize="1rem">
                                    {option.label}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    )}
                    rules={{ required: true }}
                  />

                  {/* Public */}
                  <Controller
                    control={control}
                    name="bPrivate"
                    render={({ field }) => (
                      <Card
                        sx={{ width: "100%", height: "100%" }}
                        data-tour="add-inspection-5"
                      >
                        <CardActions sx={{ justifyContent: "space-between" }}>
                          <Box>
                            <IconButton title="Back">
                              {field.value ? <LockIcon /> : <LockOpenIcon />}
                            </IconButton>
                            {field.value
                              ? tGeneral("private")
                              : tGeneral("public")}
                          </Box>
                          <Switch
                            {...field}
                            onChange={(event) =>
                              field.onChange(event.target.checked)
                            }
                            checked={field.value}
                            color="primary"
                          />
                        </CardActions>
                      </Card>
                    )}
                  />
                </Box>

                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  width={1}
                >
                  {/* Location */}
                  <Controller
                    control={control}
                    name="location"
                    render={({ field }) => (
                      <NestedLocationSelect
                        error={errors.location}
                        helperText={
                          errors.location && tGeneral("fieldRequired")
                        }
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        selectedLocation={field.value}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </Box>

                {/* Inspection List */}
                <span data-tour="add-inspection-7">
                  <Box
                    alignItems="flex-start"
                    borderTop="1px solid #E1E1E1"
                    display="flex"
                    justifyContent="flex-start"
                    pt={2}
                    width={1}
                  >
                    <Box display="flex">
                      <Typography fontWeight="bold" variant="h6">
                        {tInspections("inspections")}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    alignItems="flex-start"
                    display="flex"
                    gap={2}
                    justifyContent="flex-start"
                  >
                    <FormControl fullWidth size="small">
                      <InputLabel id="status">
                        {tInspections("select_template")}
                      </InputLabel>
                      <Select
                        label={tInspections("select_template")}
                        labelId="status"
                        onChange={(event) => {
                          setTemplate(event.target.value);
                        }}
                        select="true"
                        type="text"
                        variant="outlined"
                        value={template}
                      >
                        {inspectionsTemplates &&
                          inspectionsTemplates.length > 0 &&
                          inspectionsTemplates.map((inspectionTemplate) => (
                            <MenuItem
                              key={inspectionTemplate.id}
                              value={inspectionTemplate.id}
                            >
                              {inspectionTemplate.title}
                            </MenuItem>
                          ))}
                        <MenuItem value="quick-template">
                          Quick template
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      onClick={() => {
                        const t =
                          inspectionsTemplates &&
                          inspectionsTemplates.length > 0 &&
                          inspectionsTemplates.find(
                            (inspectionTemplate) =>
                              inspectionTemplate.id === template,
                          );
                        if (t) {
                          setTemplate("");
                          setValue("sections", [
                            ...(getValues("sections") || []),
                            ...t.sections,
                          ]);
                        } else {
                          setTemplate("");
                        }
                      }}
                      size="small"
                      sx={{
                        alignSelf: "stretch",
                      }}
                      type="button"
                      variant="contained"
                    >
                      {tGeneral("select")}
                    </Button>
                  </Box>
                  <Card sx={{ flex: 1, overflowY: "auto", maxHeight: "47vh" }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      p={2}
                      width={1}
                    >
                      {sections.length > 0 ? (
                        <DragDropContext
                          onDragEnd={({ source, destination }) => {
                            if (destination) {
                              move(source.index, destination.index);
                            }
                          }}
                        >
                          <Droppable droppableId="inspections-form-sections">
                            {(provided) => (
                              <Box
                                {...provided.droppableProps}
                                display="flex"
                                flexDirection="column"
                                gap={2}
                                ref={provided.innerRef}
                                width={1}
                              >
                                {sections.map((field, index) => (
                                  <Draggable
                                    draggableId={field.id}
                                    index={index}
                                    key={field.id}
                                  >
                                    {(provided) => {
                                      return field.isEditable ? (
                                        <Box
                                          {...provided.draggableProps}
                                          backgroundColor="#f8f8f8"
                                          borderRadius="5px"
                                          display="flex"
                                          flexDirection="column"
                                          gap={2}
                                          key={`draggable-${field.id}`}
                                          p={2}
                                          ref={provided.innerRef}
                                          width={1}
                                        >
                                          <Box
                                            alignItems="center"
                                            backgroundColor="#f8f8f8"
                                            borderRadius="5px"
                                            display="flex"
                                            flexDirection="row"
                                            gap={2}
                                            textAlign="center"
                                            width={1}
                                          >
                                            <Controller
                                              control={control}
                                              name={`sections.${index}.title`}
                                              render={({ field }) => (
                                                <TextField
                                                  {...field}
                                                  defaultValue={getValues(
                                                    `sections.${index}.title`,
                                                  )}
                                                  error={
                                                    errors.sections?.[index]
                                                      ?.title
                                                  }
                                                  fullWidth
                                                  helperText={
                                                    errors.sections?.[index]
                                                      ?.title &&
                                                    tGeneral("fieldRequired")
                                                  }
                                                  label={tGeneral("title")}
                                                  size="small"
                                                  sx={{
                                                    backgroundColor: "#fff",
                                                    flex: 1,
                                                    m: 0,
                                                  }}
                                                  type="text"
                                                  variant="outlined"
                                                />
                                              )}
                                              rules={{ required: true }}
                                            />
                                            <Button
                                              onClick={() =>
                                                update(index, {
                                                  ...field,
                                                  isEditable: !field.isEditable,
                                                  title: getValues(
                                                    `sections.${index}.title`,
                                                  ),
                                                })
                                              }
                                              sx={{
                                                alignSelf: "stretch",
                                                p: 0,
                                              }}
                                              type="button"
                                              variant="contained"
                                            >
                                              {tGeneral("save")}
                                            </Button>
                                          </Box>
                                          <InspectionsFormSection
                                            control={control}
                                            errors={errors}
                                            field={field}
                                            getValues={getValues}
                                            index={index}
                                          />
                                        </Box>
                                      ) : (
                                        <Box
                                          {...provided.draggableProps}
                                          backgroundColor="#f8f8f8"
                                          borderRadius="5px"
                                          display="flex"
                                          flexDirection="column"
                                          gap={2}
                                          key={field.id}
                                          p={2}
                                          ref={provided.innerRef}
                                          width={1}
                                        >
                                          <Box
                                            alignItems="center"
                                            display="flex"
                                            flexDirection="row"
                                            gap={2}
                                            width={1}
                                          >
                                            <Box
                                              {...provided.dragHandleProps}
                                              alignItems="center"
                                              display="flex"
                                              flexDirection="column"
                                              cursor="pointer"
                                            >
                                              <DragIndicatorIcon color="disabled" />
                                            </Box>
                                            <Typography
                                              align="left"
                                              fontWeight="bold"
                                              sx={{ flex: 1 }}
                                            >
                                              {field.title}
                                            </Typography>
                                            <EditIcon
                                              onClick={() =>
                                                update(index, {
                                                  ...field,
                                                  isEditable: !field.isEditable,
                                                })
                                              }
                                              sx={{ cursor: "pointer" }}
                                            />
                                            <DeleteIcon
                                              color="error"
                                              onClick={() => remove(index)}
                                              sx={{ cursor: "pointer" }}
                                            />
                                            {field.isExpanded ? (
                                              <KeyboardArrowDownIcon
                                                onClick={() =>
                                                  update(index, {
                                                    ...field,
                                                    isExpanded:
                                                      !field.isExpanded,
                                                  })
                                                }
                                                sx={{ cursor: "pointer" }}
                                              />
                                            ) : (
                                              <KeyboardArrowUpIcon
                                                onClick={() =>
                                                  update(index, {
                                                    ...field,
                                                    isExpanded:
                                                      !field.isExpanded,
                                                  })
                                                }
                                                sx={{ cursor: "pointer" }}
                                              />
                                            )}
                                          </Box>
                                          <InspectionsFormSection
                                            control={control}
                                            errors={errors}
                                            field={field}
                                            getValues={getValues}
                                            index={index}
                                          />
                                        </Box>
                                      );
                                    }}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </Box>
                            )}
                          </Droppable>
                        </DragDropContext>
                      ) : (
                        <Box
                          backgroundColor="#f8f8f8"
                          borderRadius="5px"
                          color="#000"
                          p={2}
                          textAlign="center"
                          width={1}
                        >
                          {tInspections("no_sections")}
                        </Box>
                      )}
                      {addSectionStatus && (
                        <Box
                          alignItems="center"
                          backgroundColor="#f8f8f8"
                          borderRadius="5px"
                          display="flex"
                          flexDirection="row"
                          gap={2}
                          p={2}
                          textAlign="center"
                          width={1}
                        >
                          <TextField
                            label={tGeneral("title")}
                            onChange={(event) =>
                              setAddSectionTitle(event.target.value)
                            }
                            size="small"
                            sx={{ backgroundColor: "#fff", flex: 1 }}
                            type="text"
                            value={addSectionTitle}
                            variant="outlined"
                          />
                          <Button
                            disabled={addSectionTitle === ""}
                            onClick={() => {
                              append({
                                isEditable: false,
                                isExpanded: true,
                                title: addSectionTitle,
                                items: [],
                                id: v4(),
                              });
                              setAddSectionStatus(false);
                              setAddSectionTitle("");
                            }}
                            sx={{ alignSelf: "stretch", px: 2, py: 1 }}
                            type="button"
                            variant="contained"
                          >
                            {tGeneral("add")}
                          </Button>
                          <Button
                            color="error"
                            onClick={() => {
                              setAddSectionStatus(false);
                              setAddSectionTitle("");
                            }}
                            sx={{ alignSelf: "stretch", px: 2, py: 1 }}
                            type="button"
                            variant="outlined"
                          >
                            {tGeneral("cancel")}
                          </Button>
                        </Box>
                      )}
                      {template === "quick-template" ? (
                        <Box
                          backgroundColor="#f8f8f8"
                          borderRadius="5px"
                          color="#009dff"
                          onClick={() => setAddSectionStatus(true)}
                          p={2}
                          sx={{ cursor: "pointer" }}
                          textAlign="center"
                          width={1}
                        >
                          + {tInspections("add_section")}
                        </Box>
                      ) : null}
                    </Box>
                  </Card>
                </span>
              </Box>

              <CreateFormUsersSide
                distributionListName={DISTRIBUTION_LIST_NAMES.inspections}
                distributionListDataTour="add-inspection-10"
                lists={[
                  {
                    label: tGeneral("creators"),
                    name: "creators",
                    canRemoveSelf: false,
                    currentUserId: currentUser.id,
                    dataTourName: "add-dailyReport-6",
                  },
                  {
                    label: tGeneral("assigned"),
                    name: "assignedTo",
                    dataTourName: "add-dailyReport-7",
                  },
                ]}
              />
            </Box>
          </form>
        </FormProvider>
        <Modal
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={inProcess}
        >
          <CircularProgress color="primary" size="100px" />
        </Modal>
        <SaveAsTemplate
          sections={getValues("sections")}
          status={saveAsTemplate}
          handleClose={() => {
            setSaveAsTemplate(false);
          }}
        />
        <Cancel
          status={cancel}
          handleClose={(status) => {
            if (status) {
              navigate(-1);
            } else {
              setCancel(false);
            }
          }}
        />
      </Box>
    </Can>
  );
}

export default InspectionsForm;
