import { Box, Checkbox, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { datadogRum } from "@datadog/browser-rum";
import { useCheckSubtaskMutation } from "../../../../features/project/modules/tasks/tasksApiSlice";

function SubtaskDetail({ subtasks = [], disable = true, onCheck }) {
  const { t: tTasks } = useTranslation("tasks");

  const [checkSubtask, { isLoading: isCheckLoading }] =
    useCheckSubtaskMutation();

  const [progress, setProgress] = useState(0);

  const { idProject, idEnterprise } = useParams();

  const unity = (
    100 / (subtasks?.length > 0 ? subtasks?.length ?? 0 : 1)
  ).toFixed(0);

  useEffect(() => {
    if (subtasks?.length > 0) {
      const doneSubtasks = subtasks.filter((subtask) => subtask.bChecked);
      if (doneSubtasks.length !== subtasks.length) {
        setProgress(doneSubtasks.length * unity);
      } else {
        setProgress(100);
      }
      onCheck(false);
    }
  }, [subtasks, onCheck, unity]);

  const handleCheck = (idSubtask, checked) => {
    try {
      checkSubtask({
        id: idSubtask,
        bChecked: checked,
      });
      onCheck(true);
    } catch (error) {
      datadogRum.addError({
        message: "Tasks - Subtasks Check - Error",
        stack: error.stack ? error.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: idEnterprise,
          module: "Tasks",
          project: idProject,
        },
        timestamp: Date.now(),
      });
    }
  };

  return subtasks?.length > 0 ? (
    <Box>
      {/* Progress bar */}
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="subtitle1" align="left">
          {progress}%
        </Typography>
        <Box sx={{ width: "100%", ml: 1 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              borderRadius: 5,
              height: 7,
            }}
          />
        </Box>
      </Box>
      {/* Checklist */}
      <Box display="flex" flexDirection="column">
        {subtasks?.map((subtask) => (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Checkbox
              checked={subtask.bChecked}
              onChange={(e) => {
                handleCheck(subtask.id, e.target.checked);
              }}
              disabled={disable || isCheckLoading}
            />
            <Typography>{subtask.description}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  ) : (
    <Typography fontSize="16px" textAlign="center" fontWeight="medium">
      {tTasks("noSubtasks")}
    </Typography>
  );
}

export default SubtaskDetail;
