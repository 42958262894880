import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
// MUI
import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  Checkbox,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// Icons
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import FlagIcon from "@mui/icons-material/Flag";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { useTranslation } from "react-i18next";

// Custom imports
import {
  addSelectedTask,
  removeSelectedTask,
  selectSelectedTasks,
  setEditingTask,
} from "../../../features/project/modules/tasks/tasksSlice";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
// import { formatDate } from "../../../services/helpers/dateFormat";
import taskUtil from "../../../utils/task";
import SendToEmail from "../../shared/SendToEmail";
import { useSendToEmailExportMutation } from "../../../features/ses/sesApiSlice";
import { PopUpAlert } from "../../PopUpAlert";

import CaslContext from "../../../utils/caslContext";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { useAppTourContext } from "../../supportButton/context/context";
import pdf from "../../../utils/pdf";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow followCursor />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 10,
  },
}));

function TaskCard({
  task,
  title,
  status,
  number,
  priority,
  creator,
  location,
  createDate,
  dueDate,
  closedDate,
  assignedTo = [],
  isPrivate = false,
  isDraft = false,
  subtasks = [],
  PaperSxProps,
  ...props
}) {
  const { idEnterprise, idProject } = useParams();

  // Translation
  const { t: tGeneral } = useTranslation("general");

  function formatDate(simpleDate) {
    const date = new Date(simpleDate);

    const month = [
      tGeneral("january"),
      tGeneral("february"),
      tGeneral("march"),
      tGeneral("april"),
      tGeneral("may"),
      tGeneral("june"),
      tGeneral("july"),
      tGeneral("august"),
      tGeneral("september"),
      tGeneral("october"),
      tGeneral("november"),
      tGeneral("december"),
    ];

    const dateBirthFormat = `${date.getDate()} ${
      month[date.getMonth()]
    } ${date.getFullYear()}`;

    return dateBirthFormat;
  }

  const user = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ability = useContext(CaslContext);
  const selectedTasks = useSelector(selectSelectedTasks);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  // Send by email
  const [sendingTasks, setSendingTasks] = useState(null);
  const [
    sendMail,
    {
      isLoading: isSendMailLoading,
      isSuccess: isSendEmailSuccess,
      isError: isSendEmailError,
      error: sendEmailError,
    },
  ] = useSendToEmailExportMutation();

  useEffect(() => {
    if (isSendEmailSuccess) {
      PopUpAlert("success", tGeneral("sent"), tGeneral("sentMessage"));
      setSendingTasks(null);
    }
    if (isSendEmailError) {
      PopUpAlert("error", tGeneral("error"), sendEmailError.message);
      setSendingTasks(null);
    }
  }, [isSendEmailError, isSendEmailSuccess, sendEmailError, tGeneral]);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      // Add task
      dispatch(addSelectedTask(task?.id));
    } else {
      // Remove task
      dispatch(removeSelectedTask(task?.id));
    }
  };

  // Send to email
  const handleSendEmailClick = (rowData) => {
    setSendingTasks([rowData]);
  };

  const handleSendEmail = async (
    selectedTask,
    message,
    subject,
    recipients,
    ccMails,
  ) => {
    const body = {
      message,
      subject,
      module: "task",
      cc: ccMails,
    };
    if (recipients?.length > 0) {
      body.recipients = recipients;
    }
    sendMail({
      idEnterprise,
      idProject,
      idObject: selectedTask[0].id,
      body,
    });
  };

  const [load, setLoad] = React.useState(false);

  const exportPdfDocument = React.useCallback(async () => {
    await pdf({
      data: {},
      token,
      url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/tasks/${task?.id}`,
      download: task?.title,
      tGeneral,
    });
  }, [idEnterprise, idProject, tGeneral, task, token]);

  const onClickToEdit = React.useCallback(() => {
    dispatch(setEditingTask(task));
    navigate("form");
  }, [dispatch, navigate, task]);

  // Menu options
  const menuOptions = React.useMemo(() => {
    const options = [];
    if (!ability) return options;
    if (
      (ability.can("superAdmin", "tasks") ||
        (ability.can("edit", "tasks") && task.createdBy === user.id)) &&
      task.bActive
    ) {
      options.push({
        id: "edit-task",
        title: tGeneral("edit"),
        icon: <EditIcon fontSize="small" />,
        onClick: onClickToEdit,
      });
    }
    options.push({
      id: "view-task",
      title: tGeneral("view"),
      icon: <RemoveRedEyeIcon fontSize="small" />,
      onClick: () => navigate(`./${task?.id}`),
    });
    if (task.bActive) {
      options.push(
        {
          id: "export-task",
          title: "PDF",
          icon: <PictureAsPdfIcon disabled={load} fontSize="small" />,
          onClick: () => {
            setLoad(true);
            exportPdfDocument().then(() => setLoad(false));
          },
        },
        {
          id: "send-task",
          title: tGeneral("send"),
          icon: <EmailIcon fontSize="small" />,
          onClick: () => {
            handleSendEmailClick(task);
          },
        },
      );
    }
    return options;
  }, [
    exportPdfDocument,
    load,
    navigate,
    tGeneral,
    task,
    ability,
    onClickToEdit,
    user.id,
  ]);

  // Determine whether it's open, draft or closed
  const taskStatus = taskUtil.getTaskStatus(status, isDraft);
  // Check if the task has expired
  const isExpired = new Date() > new Date(dueDate);

  const handleOpenTask = () => {
    if (task.bDraft) {
      dispatch(setEditingTask(task));
      navigate("form");
    } else {
      navigate(`./${task?.id}`);
    }
  };

  function SubtaskLabel({ subtasks }) {
    return (
      <Box display="flex" columnGap={1} alignItems="center">
        <Card elevation={1} sx={{ px: 0.5 }}>
          <ChecklistIcon sx={{ mt: 0.5 }} />
        </Card>
        <Typography fontWeight="bold">
          {`${subtasks?.filter((subtask) => subtask.bChecked).length}/${
            subtasks?.length
          }`}
        </Typography>
      </Box>
    );
  }

  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const {
    state: { tourActive, stepIndex, steps },
  } = useAppTourContext();

  useEffect(() => {
    if (tourActive && stepIndex === 1 && currentGuideMe === "tasks-edit") {
      if (selectedTasks.includes(task?.id)) {
        onClickToEdit();
      }
    }
  }, [
    currentGuideMe,
    navigate,
    onClickToEdit,
    selectedTasks,
    stepIndex,
    steps,
    task,
    tourActive,
  ]);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 1 &&
      currentGuideMe === "tasks-view" &&
      selectedTasks.includes(task?.id)
    ) {
      navigate(`./${task?.id}`);
    }
  }, [task, selectedTasks, tourActive, stepIndex, currentGuideMe, navigate]);

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          borderRadius: 2,
          position: "relative",
          width: "100%",
          paddingLeft: 3,
          paddingRight: 3,
          paddingTop: 2,
          ":hover": {
            transform: "scale3d(1.01, 1.01, 1)",
            boxShadow: 3,
            transition: "transform 0.25s ease-in-out",
            cursor: "pointer",
          },
          ...PaperSxProps,
        }}
        {...props}
      >
        {/* Private & title */}
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          marginBottom={1}
          maxWidth={{ xs: "80%", md: "75%" }}
        >
          <Checkbox
            checked={selectedTasks?.includes(task?.id)}
            onChange={handleCheckboxChange}
            size="small"
            color="secondary"
            disableRipple
            sx={{
              borderRadius: 0,
              width: "25px",
              height: "25px",
            }}
            data-tour="recycle-tasks-2"
          />
          {isPrivate ? (
            <LockIcon fontSize="small" />
          ) : (
            <LockOpenIcon fontSize="small" />
          )}
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "11rem",
            }}
          >
            <Typography noWrap fontSize={14}>
              {title}
            </Typography>
          </Box>
        </Box>
        {/* Clickable Content */}
        <Box onClick={handleOpenTask}>
          {/* Draft / Open / Closed & taskNumber */}
          <Box display="flex" gap={1} marginBottom={1}>
            <Chip
              label={
                // eslint-disable-next-line no-nested-ternary
                taskStatus.label === "OPEN"
                  ? tGeneral("open_action").toUpperCase()
                  : // eslint-disable-next-line no-nested-ternary
                    taskStatus.label === "DRAFT"
                    ? tGeneral("draft").toUpperCase()
                    : taskStatus.label === "CLOSED"
                      ? tGeneral("closed").toUpperCase()
                      : "Other"
              }
              sx={{
                color: "white",
                fontWeight: "medium",
                backgroundColor: taskStatus.color,
                fontSize: "1rem",
                width: "130px",
              }}
            />
            <Chip
              label={`#${number}`}
              sx={{
                color: "white",
                fontWeight: "medium",
                backgroundColor: "#ffc42d",
                fontSize: "1rem",
              }}
            />
          </Box>

          {/* Assigned To */}
          <Box display="flex" marginY={1} justifyContent="space-between">
            <LightTooltip
              title={
                !assignedTo.some((user) => !user)
                  ? assignedTo?.map((user) => (
                      <Box
                        key={`tooltip-${task.id}-${user?.id}`}
                        sx={{ display: "flex", marginY: "2px" }}
                      >
                        <Avatar
                          alt={user?.firstName}
                          src={user?.urlAvatar}
                          sx={{
                            bgcolor: "#ffc900",
                            width: 24,
                            height: 24,
                            fontSize: 12,
                            boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                          }}
                          style={{
                            border: "0.8px solid white",
                          }}
                        >
                          {`${user?.firstName[0]}${user?.lastName[0]}`}
                        </Avatar>
                        <Typography sx={{ fontSize: 15 }}>
                          &nbsp;{`${user?.firstName} ${user?.lastName}`}
                        </Typography>
                      </Box>
                    ))
                  : ""
              }
            >
              <AvatarGroup
                max={3}
                sx={{
                  justifyContent: "flex-end",
                  "& .MuiAvatar-root": { width: 32, height: 32, fontSize: 15 },
                  "& .MuiTooltip-tooltip": {
                    border: "solid skyblue 1px",
                    color: "deepskyblue",
                  },
                  "& .MuiAvatarGroup-root": {
                    marginX: "auto",
                    textAlign: "center",
                  },
                }}
              >
                {!assignedTo.some((user) => !user) &&
                  assignedTo.map((user) => (
                    <Avatar
                      key={`avatar-${task.id}-${user.id}`}
                      alt={user?.firstName}
                      src={user?.urlAvatar}
                      sx={{
                        bgcolor: "#ffc900",
                        width: 30,
                        height: 30,
                        fontSize: 12,
                        boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                      }}
                      style={{
                        border: "0.8px solid white",
                      }}
                    >{`${user?.firstName[0]}${user?.lastName[0]}`}</Avatar>
                  ))}
              </AvatarGroup>
            </LightTooltip>
            {subtasks?.length > 0 && <SubtaskLabel subtasks={subtasks} />}
          </Box>

          {/* Date */}
          <Box pb={2}>
            {!isExpired && status !== "done" && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography fontSize={14}>
                  {formatDate(`${dueDate?.split("T")[0]}T09:00:00.000Z`)}
                </Typography>
              </Box>
            )}
            {isExpired && status !== "done" && (
              <Box display="flex" alignItems="center" gap={1}>
                <Typography fontSize={14} color="#e05353">
                  {formatDate(`${dueDate?.split("T")[0]}T09:00:00.000Z`)}
                </Typography>
                <AccessAlarmIcon
                  style={{ color: "#E05353", fontSize: "16px" }}
                />
              </Box>
            )}
            {status === "done" && (
              <Box display="flex" gap={1}>
                <Typography fontSize={14}>
                  {formatDate(`${createDate?.split("T")[0]}T09:00:00.000Z`)}
                </Typography>
                <Typography fontSize={14}>/</Typography>
                <Typography fontSize={14} fontWeight="bold">
                  {formatDate(`${closedDate?.split("T")[0]}T09:00:00.000Z`)}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        {/* Menu dots & priority */}
        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          top={10}
          right={0}
          data-tour={
            selectedTasks.includes(task?.id) ? "edit-tasks-1" : "export-tasks-1"
          }
        >
          {priority === "low" && (
            <FlagIcon fontSize="small" style={{ color: "#009DFF" }} />
          )}
          {priority === "medium" && (
            <FlagIcon fontSize="small" style={{ color: "#FFC52E" }} />
          )}
          {priority === "high" && (
            <FlagIcon fontSize="small" style={{ color: "#E05353" }} />
          )}
          <IconButton
            size="small"
            aria-label="task options"
            aria-haspopup="true"
            color="inherit"
            onClick={handleMenuOpen}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </Box>

        <Menu
          disableScrollLock
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          {menuOptions.map((option) => (
            <MenuItem
              key={option.id}
              onClick={() => {
                option.onClick();
                handleMenuClose();
              }}
            >
              {option.icon}
              <Typography fontSize={12} fontWeight="bold" sx={{ mx: 1 }}>
                {option.title}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Paper>
      {Boolean(sendingTasks) && (
        <SendToEmail
          onClose={() => setSendingTasks(null)}
          isOpen={Boolean(sendingTasks)}
          title={
            sendingTasks?.length > 1
              ? `${tGeneral("tasks")} (${sendingTasks?.length})`
              : sendingTasks[0]?.title
          }
          onSend={(message, subject, recipients, ccMails) => {
            handleSendEmail(
              sendingTasks,
              message,
              subject,
              recipients,
              ccMails,
            );
          }}
          isLoading={isSendMailLoading}
        />
      )}
    </>
  );
}

export default TaskCard;
