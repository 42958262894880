export const general = {
  addFilter: "Add filter",
  settings: "Settings",
  restore: "Restore",
  location: "Location",
  locations: "Locations",
  uploadedBy: "Uploaded By",
  attendance: "Attendance",
  assignees: "Assignees",
  assigned: "Assigneed",
  category: "Category",
  priority: "Priority",
  createdBy: "Created by",
  distributionList: "Distribution List",
  noUsersSelected: "No users selected",
  noAttendanceUsers: "No attendance users",
  actions: "Actions",
  day: "Day",
  month: "Month",
  year: "Year",
  week: "Week",
  rename: "Rename",
  delete: "Delete",
  deleteAll: "Delete All",
  cancel: "Cancel",
  confirm: "Confirm",
  edit: "Edit",
  information: "Information",
  comments: "Comments",
  takenOn: "Taken on",
  uploadedOn: "Uploaded on",
  errorMessage: "An error has occurred, try again later.",
  errorUploadMessage:
    "The files could not be uploaded, please check your internet connection and try again later.",
  errorDownloadMessage:
    "The files could not be downloaded, please check your internet connection and try again later.",
  close: "Close",
  save: "Save",
  logOut: "Log out",
  view: "View",
  seeDetails: "See Details",
  download: "Download",
  add: "Add",
  update: "Update",
  accept: "Accept",
  loading: "Loading",
  loadingDots: "Loading...",
  email: "Email",
  phone: "Phone",
  name: "Name",
  lastName: "Last name",
  position: "Position",
  enterprise: "Enterprise",
  role: "Role",
  module: "Module",
  superAdmin: "Super Admin",
  admin: "Admin",
  viewOnly: "View Only",
  none: "None",
  invalid: "Invalid",
  search: "Search",
  projectSearch: "Project wide search",
  open: "Open",
  open_action: "Open",
  still_open: "Still open",
  public: "Public",
  private: "Private",
  privacy: "Privacy",
  date: "Date",
  closedDate: "Closed Date",
  status: "Status",
  title: "Title",
  draft: "Draft",
  favorite: "Favorite",
  back: "Back",
  configUpdated: "Config updated successfully",
  errorConfigUpdated: "Can't update config",
  hour: "Hour",
  selectStatus: "Select status",
  publish: "Publish",
  attendanceConfirmed: "Attendance confirmed successfully",
  errorAttendanceConfirmed: "Can't confirm attendance",
  copy: "Copy",
  confirmAttendance: "Confirm assistance",
  pdf: "Pdf",
  creator: "Creator",
  lastSeen: "Last Seen",
  lastEdit: "Last Edit",
  systemLanguage: "System language",
  address: "Address",
  street: "Street",
  number: "Number",
  country: "Country",
  state: "State",
  city: "City",
  zipCode: "Zip code",
  next: "Next",
  en: "English",
  es: "Spanish",
  file: "File",
  files: "Files",
  dateReceived: "Received date",
  features: "Features",
  drawings: "Drawings",
  drawing: "Drawing",
  submittals: "Submittals",
  rfis: "RFIs",
  dailyReports: "Reports",
  issues: "Issues",
  photos: "Photos",
  tasks: "Tasks",
  inspections: "Inspections",
  documents: "Documents",
  meetings: "Meetings",
  delay: "Delay",
  directory: "Directory",
  hours: "Hours",
  deliveries: "Deliveries",
  announcements: "Announcements",
  user: "User",
  project: "Project",
  projects: "Projects",
  subscribe: "Subscribe",
  notFound: "No projects found",
  noProjects: "No projects have been created at the moment",
  createButton:
    "If you want to add one, we invite you to click on the create project button to start the process",
  createProject: "Create project",
  loadingEnterprises: "Loading enterprises...",
  generalDirectory: "General Directory",
  superAdmins: "Super Admins",
  roles: "Roles",
  manager: "Manager",
  constructionDirector: "Construction Director",
  architect: "Architect",
  engineer: "Engineer",
  active: "Active",
  inactive: "Inactive",
  generalInfo: "General Information",
  changePlan: "Change Plan",
  billing: "Billing",
  paymentMethod: "Payment Methods",
  updateProfile: "Update Profile Enterprise",
  youSureEnterprise:
    "You sure you want to update the profile enterprise with this data?",
  enterpriseName: "Enterprise name",
  enterpriseType: "Enterprise type",
  enterpriseImage: "Enterprise logo",
  success: "Success",
  done: "Done!",
  enterpriseUpdated: "Enterprise information updated successfully.",
  error: "Error",
  errorBig: "Error!",
  errorOccurred: "An error has occurred, try again later.",
  logout: "Logout",
  profile: "Profile",
  currentPlan: "Plan actual",
  per: "Por",
  activeProjects: "Active projects",
  activeUsers: "Active users",
  annual: "Annual",
  monthly: "Monthly",
  allTheFeatures: "All the features",
  unlimitedProjects: "Unlimited projects",
  unlimitedUsers: "Unlimited users",
  unlimitedStorage: "Unlimited storage",
  translationSpanglish: "Translation Spanish-English",
  companyReports: "Company branded reports",
  customizableReports: "Customizable report templates",
  home: "Home",
  changePassword: "Change password",
  tickets: "Tickets",
  preferences: "Preferences",
  userManual: "User manual",
  notifications: "Notifications",
  male: "Male",
  female: "Female",
  editProfile: "Edit profile",
  firstName: "First name",
  birthDate: "Birth date",
  gender: "Gender",
  inProcess: "In process",
  currentPassword: "Current password",
  newPassword: "New password",
  repeatPassword: "Repeat password",
  passwordNotice:
    "Password must have at least 1 uppercase, 1 lowercase, 1 number, 1 special character (_, #, ?, !, @, $, %, ^, &, * or -) and be 8 characters long.",
  passwordYouSure: "Are you sure you want to change the password?",
  enterPassword: "Enter password",
  changedPassword: "Password changed successfully",
  welcomeTickets: "Welcome! Select the category of the incident found below.",
  mobile: "Mobile",
  browser: "Browser",
  device: "Device",
  details: "Details",
  send: "Send",
  mode: "Mode",
  dark: "Dark",
  light: "Light",
  exportCvs: "Export CVS",
  exportPdf: "Export PDF",
  newSuperAdmin: "New super admin",
  dashboard: "Dashboard",
  adminPanel: "Admin Panel",
  visibleModules: "Visible Modules",
  orderModules: "Order Modules",
  projectAdmin: "Project administration",
  specification: "Specification",
  specifications: "Specifications",
  costCodes: "Cost codes",
  connections: "Connections",
  projectName: "Project name",
  translation: "Translation",
  managers: "Managers",
  constructionManager: "Construction manager",
  projectEngineer: "Project engineer",
  editInfo: "Edit information",
  on: "On",
  off: "Off",
  addSuperAdmin: "Add Super Admin",
  deleteSuperAdmin: "Delete Super Admin",
  areYouAdmin:
    "Are you sure you want to delete this super admin? By performing this action, it cannot be reversed",
  writeDetail: "Write in detail the bug found.",
  dragFiles: "Drag and drop files here, or click to select files",
  dragFile: "Drag and drop file here, or click to select file",
  emails: "Emails",
  subject: "Subject",
  to: "To",
  createdOn: "Created On",
  info: "Info",
  move: "Move",
  favorites: "Favorites",
  generalInformation: "General Information",
  permissions: "Permissions",
  managePermissions: "Manage Permissions",
  changeHistory: "Change History",
  published: "Published",
  deleted: "Deleted",
  noPermission: "You don't have permission to see this page",
  recycleBin: "Recycle Bin",
  not_defined: "Not defined",
  closed: "Closed",
  no_assinged_users: "No assigned users",
  due_date: "Due Date",
  closed_date: "Closed Date",
  approve: "Check and Sign",
  emptyDataSourceMessage: "No records to display",
  of: "of",
  deletedPermanently: "Deleted Permanently",
  deletedSuccessfully: "Deleted Successfully",
  updatedSuccessfully: "Updated Successfully",
  createdSuccessfully: "Created Successfully",
  recoveredSuccessfully: "Recovered Successfully",
  closedSuccessfully: "Closed Successfully",
  approvedSuccessfully: "Approved Successfully",
  cantRestore: "Error Restoring",
  cantClose: "Error Closing",
  cantCreate: "Error Creating",
  cantApprove: "Error Approving",
  cantDelete: "Error Deleting",
  cantEdit: "Error Editing",
  deleteRFI: "Are you sure you want to delete RFI?",
  question: "Question",
  startWriting: "Start writing...",
  additionalInformation: "Additional Information",
  receivedFrom: "Received From",
  company: "Company",
  cost: "Cost",
  price: "Price",
  currency: "Currency",
  mxn: "MXN - MEXICAN PESO",
  usd: "USD - US DOLLAR",
  emptyQuestion: "The question cannot be empty",
  createDate: "Create Date",
  newResponse: "New Response...",
  newComment: "New Comment...",
  newTopic: "New Topic...",
  topics: "Topics",
  showTranslation: "Show Translation",
  closeTranslation: "Close Translation",
  viewFiles: "View Files",
  images: "Images",
  noImages: "No Images",
  noDocuments: "No Documents",
  attachFiles: "Attach file(s) or drag and drop",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  noEnterprises: "Seems like you don't have an enterprise yet!",
  pendingItems: "Pending Items",
  weather: "Weather",
  todoList: "To do List",
  ensureThat:
    "Ensure that your project has a valid address to retrieve the weather or try again later.",
  addItemList: "Add item to your to do list",
  noAnno: "Don’t have announcements to see",
  daysLeft: "Days Left",
  congratulations: "Congratulations",
  noPending: "You don’t have pending items.",
  noFilterableElements: "No elements found that match the search criteria",
  addList: "Add To Do List",
  activityDesc: "Activity description",
  expirationDate: "Expiration date",
  onTime: "On Time",
  overdue: "Overdue",
  areYouTask: "Are you sure you want to mark this task as done?",
  trashCan: "Trash Can",
  newAnnouncement: "New Announcement",
  startDate: "Start Date",
  time: "Time",
  limitDate: "Limit Date",
  cancelled: "Cancelled",
  operations: "Operations was cancelled.",
  task: "Task",
  dailyReport: "D. Report",
  deletedBy: "Deleted By",
  deletedAt: "Deleted At",
  publishedDate: "Published Date",
  moved: "Moved",
  selectAll: "Select All",
  pleaseMakeSureAllDataIsCorrect: "Please make sure all data is correct.",
  addLocation: "Add Location",
  new: "New",
  sendSomeoneComment: "Send @someone a comment",
  endDate: "End Date",
  recent: "Recent",
  thisWeek: "This week",
  thisMonth: "This month",
  lastWeek: "Last week",
  projectConfigurationUpdateSuccess:
    "Project configuration updated successfully",
  description: "Description",
  enterValidTitle: "Enter a valid title",
  enterValidDescription: "Enter a valid description",
  enterValidDate: "Enter a valid date",
  enterValidStatus: "Enter a valid status",
  enterValidPriority: "Enter a valid priority",
  enterValidLocation: "Enter a valid location",
  enterValidCategory: "Enter a valid category",
  fieldRequired: "This field is required",
  cantUndone: "This action cannot be undone. ",
  users: "Users",
  minutes: "Minutes",
  noOne: "No one",
  noComments: "No comments",
  noTasks: "No tasks",
  updated: "Updated",
  restored: "Restored",
  created: "Created",
  successfully: "successfully",
  labelRowsPerPage: "Rows per page:",
  labelRowsSelect: "rows",
  firstAriaLabel: "First page",
  firstTooltip: "First page",
  previousAriaLabel: "Previous page",
  previousTooltip: "Previous page",
  nextAriaLabel: "Next page",
  nextTooltip: "Next page",
  lastAriaLabel: "Last page",
  lastTooltip: "Last page",
  adminEnabledSuccessfully: "Admin user enabled successfully",
  cantEnableAdmin: "Can't enable admin user",
  adminDisabledSuccessfully: "Admin user disabled successfully",
  cantDisableAdmin: "Can't disable admin user",
  emptyFields: "Empty fields",
  cantCreateUser: "Can't create user",
  userCreatedSuccessfully: "User created successfully",
  userEditedSuccessfully: "User edited successfully",
  cantEditUser: "Can't edit user",
  userDeletedSuccessfully: "User deleted successfully",
  cantDeleteUser: "Can't delete user",
  companyCreatedSuccessfully: "Company created successfully",
  cantCreateCompany: "Can't create company",
  totalEnterprises: "Total enterprises",
  totalContacts: "Total contacts",
  enterpriseRoleUpdated: "Enterprise role updated successfully",
  cantUpdateEnterpriseRole: "Can't update enterprise role",
  enterpriseRoleDeleted: "Enterprise role deleted successfully",
  cantDeleteEnterpriseRole: "Can't delete enterprise role",
  newRole: "New role",
  cantEditUnclassifiedRole: "Can't edit unclassified role",
  cantDeleteUnclassifiedRole: "Can't delete unclassified role",
  noUsersRole: "No users with this role",
  deleteRole: "Delete role",
  deleteRoleMessage:
    "Are you sure you want to delete this role? By performing this action, it cannot be reversed.",
  editRole: "Edit role",
  roleName: "Role name",
  invalidName: "Invalid name",
  cantCreateRole: "Can't create role",
  roleCreatedSuccessfully: "Role created successfully",
  roleEditedSuccessfully: "Role edited successfully",
  cantEditRole: "Can't edit role",
  addRole: "Add role",
  roleNameEmpty: "Role name cannot be empty",
  projectCreated: "Project created successfully",
  cantCreateProject: "Can't create project",
  userName: "User name",
  selectUser: "Select user",
  userAlreadyAdded: "User already added",
  newProject: "New project",
  skip: "Skip",
  fillRequiredFields: "Fill required fields",
  exportInfo: "Export info",
  deleteImage: "Delete image",
  clickAddProject: "Click to add a project",
  other: "Other",
  projectTypeCreated: "Project type created successfully",
  cantCreateProjectType: "Can't create project type",
  projectType: "Project type",
  addProjectType: "Add project type",
  cover: "cover",
  logo: "logo",
  recommendedSize: "Recommended size",
  projectManager: "Project manager",
  mexico: "Mexico",
  unitedStates: "United States",
  guatemala: "Guatemala",
  colombia: "Colombia",
  chile: "Chile",
  argentina: "Argentina",
  projectLimitReached: "Project limit reached",
  projectsLimitReached: "Project limit reached",
  projectAddWarning:
    "Are you sure you want to proceed with creating one more project? When performing this action, an extra project will be charged to your next invoice.",
  billingInformation: "Billing information",
  enterpriseInfoUpdated: "Enterprise information updated successfully",
  cantUpdateEnterpriseInfo: "Can't update enterprise information",
  reference: "Reference",
  amount: "Amount",
  paymentDate: "Payment date",
  emailRecipient: "Email recipient",
  cardNumber: "Card number",
  mmaa: "MM/YY",
  addCard: "Add card",
  selectAvatar: "Select avatar",
  userUpdate: "User updated successfully",
  cantUpdateUser: "Can't update user",
  avatarUpdateError: "Avatar update error",
  feelsLike: "Feels like",
  weatherValidation:
    "Ensure that your project has a zip code and a country to retreive the weather or try again later",
  clear: "Clear",
  cloudy: "Cloudy",
  mostlyClear: "Mostly Clear",
  partlyCloudy: "Partly Cloudy",
  mostlyCloudy: "Mostly Cloudy",
  fog: "Fog",
  lightFog: "Light Fog",
  drizzle: "Drizzle",
  rain: "Rain",
  lightRain: "Light Rain",
  heavyRain: "Heavy Rain",
  snow: "Snow",
  flurries: "Flurries",
  lightSnow: "Light Snow",
  heavySnow: "Heavy Snow",
  drizzleFreezing: "Drizzle Freezing",
  freezingRain: "Freezing Rain",
  freezingLightRain: "Freezing Light Rain",
  freezingHeavyRain: "Freezing Heavy Rain",
  icePellets: "Ice Pellets",
  heavyIcePellets: "Heavy Ice Pellets",
  lightIcePellets: "Light Ice Pellets",
  thunderstorm: "Thunderstorm",
  Sun: "Sun",
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  cantUpdateConfig: "Can't update configuration",
  drawingsAdded: "Drawings added successfully",
  modified: "Modified",
  modification: "Modification",
  from: "From",
  moveFolderTo: "Move folder to",
  moveFileTo: "Move file to",
  locationCreated: "Location created successfully",
  cantCreateLocation: "Can't create location",
  locationUpdated: "Location updated successfully",
  cantUpdateLocation: "Can't update location",
  locationDeleted: "Location deleted successfully",
  cantDeleteLocation: "Can't delete location",
  type: "Type",
  addType: "Add type",
  typeAdded: "Type added successfully",
  editType: "Edit type",
  typeUpdated: "Type updated successfully",
  deleteType: "Delete type",
  typeDeleted: "Type deleted successfully",
  superLocation: "Super location",
  sureDelete: "Are you sure you want to delete",
  specificationAdded: "Specification added successfully",
  costCodeAdded: "Cost code added successfully",
  specificationUpdated: "Specification updated successfully",
  costCodeUpdated: "Cost code updated successfully",
  specificationDeleted: "Specification deleted successfully",
  costCodeDeleted: "Cost code deleted successfully",
  addSpecification: "Add specification",
  addCostCode: "Add cost code",
  editSpecification: "Edit specification",
  editCostCode: "Edit cost code",
  code: "Code",
  deleteSpecification: "Delete specification",
  deleteCostCode: "Delete cost code",
  deleteGeneric:
    "Are you sure to delete this element? By performing this action, it cannot be reversed",
  seen: "Seen",
  create: "Create",
  rfi: "RFIs",
  meeting: "Meetings",
  project_directory: "Project directory",
  createuser: "Create user",
  deleteAgendaItem: "Delete agenda item",
  deleteAgendaItemMessage:
    "Are you sure you want to delete this agenda item? By performing this action, it cannot be reversed.",
  viewCurrentFiles: "View current files",
  reportAnIssue: "Report an issue",
  message: "Message",
  sendToEmail: "Send to Email",
  sent: "Sent",
  sentMessage: "Email has been sent successfully.",
  timezone: "Timezone",
  agenda: "Agenda",
  newPasswordTitle: "Please enter your new password",
  assignTo: "Assign user",
  comment: "Comment",
  linkTo: "Link To",
  deliveryDate: "Delivery date",
  responsible: "Responsible",
  invalidEmail: "Invalid Email",
  publishedBy: "Published By",
  privacyOptions: "Privacy options",
  person: "Person",
  companies: "Companies",
  onlyFavorites: "Only Favorites",
  approvedBy: "Approved By",
  approvalDate: "Approval Date",
  externalContacts: "External Contacts",
  retry: "Retry",
  generalErrorMessage: "Something went wrong. Please try again",
  yesDeleteIt: "Yes, delete it",
  creators: "Creators",
  deleteInspection: "Are you sure you want to delete Inspection?",
  deleteInspectionTemplate:
    "Are you sure you want to delete Inspection Template?",
  sections: "Sections",
  items: "Items",
  cancelPrompt: "Are you sure you want to cancel?",
  graph: "Graph",
  quickTemplate: "+ Quick Template",
  saveAsTemplate: "Save as Template",
  createRevision: "Create Revision",
  generate: "Generate",
  videos: "Videos",
  noVideos: "No Videos",
  audios: "Audios",
  noAudios: "No Audios",
  in_revision: "In Revision",
  logoutMessage: "Are you sure you want to logout?",
  exportCsv: "Export CSV",
  compare: "Compare",
  approved: "Approved",
  comparing: "Comparing",
  more: "More",
  noCCSelected: "No CC recipients selected",
  and: "and",
  removeSuperAdminTitle: "Remove super admin role",
  removeSuperAdminDescription:
    "This action removes the super admin role from the user, it does not delete it.",
  noResults: "No results",
  noResultsMessage: "Try to use different filters.",
  assignedProjects: "Assigned projects",
  noAssignedProjects: "No assigned projects",
  userAddedSuccessfully: "User added successfully",
  projectSchedule: "Project Schedule",
  noOptions: "No options",
  noData: "No data has been found",
  order: "Order",
  changeStatus: "Change Status",
  changeStatusContent: "Are you sure you want to change the user status?",
  select: "Select",
  sign: "Sign",
  useExisting: "Use existing",
  reject: "Reject",
  rejectedSuccessfully: "Rejected successfully",
  reviewedBy: "Reviewed by:",
  timeAgo: "{{time}} {{unit}} ago",
  seconds: "Seconds",
  onlyUnread: "Show unread only",
  days: "Days",
  image: "Image",
  reviewDate: "Review Date",
  publicationDate: "Published Date",
  inspection: "Inspection",
  announcement: "Announcement",
  updatedProject: "Project information updated successfully.",
  draw: "Draw",
  history: "History",
  templates: "Templates",
  useFileName: "Use file name",
  progress: "Progress",
  bim: "BIM",
  reports: "Reports",
  report: "Report",
  constants: "Constants",
  importInfoMessage:
    "Here you can import information from another project in order to save time in the creation process.",
  importInfo: "Import info",
  imported: "Imported",
  importedSuccessfully: "Imported Successfully",
  createdDate: "Created date",
  acceptedBy: "Accepted by",
  acceptedDate: "Accepted date",
  projectNetwork: "Proyect network",
  share: "Share",
  shared: "Shared",
  sharedSuccessfully: "Shared Successfully",
  sharedOnly: "Shared only",
  altitude: "Altitude",
  fullscreen: "Full screen",
  metric: "Metric",
  imperial: "Imperial",
  meters: "Meters",
  feet: "Feet",
  inches: "Inches",
  daily_reports: "Reports",
  noManager: "No manager",
  noConstructionDirector: "No director",
  noArchitect: "No architect",
  noProjectEngineer: "No engineer",
  upload: "Upload",
  enterprises: "Enterprises",
  all: "All",
  total: "Total",
  modules: "Modules",
  noElements:
    "No {{moduleName}} have been uploaded. Click on the button in the top bar to create one.",
  sessionDataSent: "Session data has been sent to the new user",
  editUser: "Edit user",
  filters: "Filters",
  filterBy: "Filter by",
  clearFilter: "Clear",
  typeToAdd: "Type to add",
  saveChanges: "Save changes",
  sendEmail: "Send Email",
  format: "Format",
  showResults: "Show results",
  activity: "Activity",
  allProjects: "All projects",
  allModules: "All modules",
  analytics: "Analytics",
  uploadingQtyFiles: "Uploading {{qty}} files",
  technicalIssues: "We’re currently experiencing technical issues",
  comeBackLater: "Please come back in a few minutes.",
  noInternetConnection: "No internet connection",
  checkConnection: "Please check your connection again, or connect to Wi-Fi.",
  charactersLimitError: "Characters limit reached",
  maxCharacters: "Max {{max}} characters",
  sharedBy: "Shared by",
  ccInstruction: "Press Enter or ';' after a valid email to add it",
  exportExcel: "Export to Excel",
  excelSuccessfullyDownloaded:
    "Your Excel document has been downloaded successfully",
  noRfiSelected: "You have not selected any RFIs to Export.",
  selectRfiToContinue: "Select a RFI to continue.",
  noSubmittalSelected: "You have not selected any Submittals to Export.",
  selectSubmittalToContinue: "Select a Submittal to continue.",
  noMoreResults: "No more results",
  projectDescription: "Project Description",
  clickToWrite: "Click here to write",
  fileUploadMaxSizeWarning: "File upload size limit is 5GB",
  errorFileUploadSizeTitle: "One of the files exceeds the limit of 5GB",
  errorFileUploadSizeDescription:
    "Please review your files and try again with a smaller file",
  errorBimUploadLimitTitle: "Project BIM upload limit reached",
  errorBimUploadLimitDescription:
    "You have reached the limit of archives for this project, please contact support if you want to add more.",
  duration: "Duration",
  startsAt: "Starts At",
  weighting: "Weighting",
  document: "Document",
  dependsOn: "Depends On",
  calculate: "Calculate",
  checklist: "Checklist",
  activities: "Activities",
  weeks: "Weeks",
  disciplines: "Disciplines",
  required: "Required",
  notRequired: "Not Required",
  duplicate: "Duplicate",
  design: "Design",
  exportPdfLimitWarning:
    "The maximum number of photos per report is 50, please reduce the selection and try again.",
  exportPdfVideoWarning:
    "Warning, video files are not supported in PDF exports",
  errorExportPDFMessage:
    "There was an error generating the export PDF, please contact support",
  chatSupport: "Support chat",
};
