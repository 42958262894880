const templates = {
  createTemplates: "创建模板",
  createTemplate: "创建模板",
  templateInstructions: "完成以下步骤以开始",
  templateTitle: "模板标题",
  selectDisciplines: "添加学科",
  saveTemplate: "保存模板",
  weighting: "权重",
  checklist: "清单",
  designTemplates: "设计模板",
  addSubitem: "添加子项",
  designDescription: "创建模板以跟踪设计部门",
  selectModule: "选择一个模块以创建您的模板",
  templates: "模板",
};

export { templates };
