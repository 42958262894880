import { useAbility } from "@casl/react";
import { Edit, Email, RemoveRedEye } from "@mui/icons-material";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useExportRfiMutation } from "../../../features/exportPdf";
import { useSendToEmailExportMutation } from "../../../features/ses/sesApiSlice";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import Can from "../../../utils/can";
import CaslContext from "../../../utils/caslContext";
import { PopUpAlert } from "../../PopUpAlert";
import {
  AssignedUsersTooltip,
  StyledMaterialReactTable,
  TooltipIconButton,
} from "../../shared";
import SendToEmail from "../../shared/SendToEmail";
import { RfiFilters } from "./RfiFilters";
import { selectCurrentEnterprise } from "../../../features/enterprise/enterpriseSlice";

function CloseDateChip({ status, dueDate, closedDate, bDraft }) {
  const { t: tGeneral } = useTranslation("general");
  const currentDate = new Date();

  if (status === "closed") {
    return (
      <Typography>{moment.utc(closedDate).format("DD/MM/YYYY")}</Typography>
    );
  }

  let sx = {};
  let label = "";

  if (new Date(dueDate) < currentDate && status !== "closed") {
    label = tGeneral("still_open").toUpperCase();
    sx = {
      backgroundColor: "#e15452",
      color: "white",
      fontSize: "0.6rem",
      width: "100px",
      fontWeight: "medium",
      textOverflow: "break-line",
    };
  } else if (bDraft) {
    label = tGeneral("draft").toUpperCase();
    sx = {
      color: "white",
      backgroundColor: "#ffc900",
      fontSize: 12,
      width: "100px",
      fontWeight: "medium",
      textOverflow: "break-line",
    };
  } else {
    label = tGeneral("still_open").toUpperCase();
    sx = {
      color: "white",
      backgroundColor: "#ffc900",
      fontSize: "0.6rem",
      width: "100px",
      fontWeight: "medium",
      textOverflow: "break-line",
    };
  }

  return <Chip sx={sx} label={label} />;
}

function RFITable({
  rfis,
  selectedRfis,
  setSelectedRfis,
  setFilters,
  isRecycle = false,
  isLoading,
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tRfis } = useTranslation("rfis");
  const navigate = useNavigate();
  const ability = useAbility(CaslContext);
  const currentUser = useSelector(selectCurrentUser);

  const [{ isLoadingPDF }] = useExportRfiMutation();
  const [
    sendMail,
    {
      isLoading: isSendMailLoading,
      isSuccess: isSendEmailSuccess,
      isError: isSendEmailError,
      error: sendEmailError,
    },
  ] = useSendToEmailExportMutation();

  const currentEnterprise = useSelector(selectCurrentEnterprise);
  const { idProject } = useParams();

  const [sendingRfis, setSendingRfis] = useState(null);

  const statusProps = {
    pending: {
      label: tGeneral("open_action").toUpperCase(),
      styles: {
        backgroundColor: "#5ce381",
        color: "white",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    open_past_due: {
      label: tGeneral("open_action").toUpperCase(),
      styles: {
        backgroundColor: "#e15452",
        color: "white",
        fontSize: "0.6rem",
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    closed: {
      label: tGeneral("closed").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#c2c3c3",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    draft: {
      label: tGeneral("draft").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#fe9300",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    stillOpen: {
      label: tGeneral("still_open").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#ffc900",
        fontSize: "0.6rem",
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    draft_closed_date: {
      label: tGeneral("draft").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#ffc900",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    notDefined: {
      label: tGeneral("not_defined").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#2196f3",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
  };

  const columns = [
    // {
    //   accessorKey: "bPrivate",
    //   header: "Priv.",
    //   Cell: ({ cell }) => (cell.getValue() ? <Lock /> : <LockOpen />),
    //   size: 40,
    //   muiTableHeadCellProps: {
    //     sx: { padding: "0 0 0 10px" },
    //     align: "left",
    //   },
    // },
    {
      accessorKey: "number",
      header: "#",
      muiTableHeadCellProps: {
        sx: { padding: 0 },
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 50,
    },
    {
      accessorKey: "title",
      header: tGeneral("title"),
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <Typography
            noWrap
            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >
            {cell.getValue()}
          </Typography>
        </Tooltip>
      ),
      muiTableBodyCellProps: {
        align: "left",
      },
      muiTableHeadCellProps: {
        align: "left",
      },
      size: 120,
    },
    {
      accessorKey: "status",
      header: tGeneral("status"),
      Cell: ({ cell, row }) => (
        <Chip
          sx={
            statusProps[row.original?.bDraft ? "draft" : cell.getValue()].styles
          }
          label={
            statusProps[row.original?.bDraft ? "draft" : cell.getValue()].label
          }
        />
      ),
      size: 120,
    },
    {
      accessorKey: "assignedTo",
      header: tGeneral("assigned"),
      Cell: ({ cell }) => <AssignedUsersTooltip users={cell.getValue()} />,
      size: 120,
    },
    {
      accessorKey: "dueDate",
      header: tGeneral("due_date"),
      Cell: ({ cell }) =>
        cell.getValue()
          ? moment.utc(cell.getValue()).format("DD/MM/YYYY")
          : "N/A",
      size: 120,
    },
    {
      accessorKey: "closedDate",
      header: tDailyReports("closed"),
      Cell: ({ cell, row }) => (
        <CloseDateChip
          bDraft={row.original?.bDraft}
          closedDate={cell.getValue()}
          dueDate={row.original?.dueDate}
          status={row.original?.status}
        />
      ),
      muiTableHeadCellProps: {
        align: "center",
      },
      size: 120,
    },
  ];

  useEffect(() => {
    if (isSendEmailSuccess) {
      PopUpAlert("success", tGeneral("sent"), tGeneral("sentMessage"));
      setSendingRfis(null);
    }
    if (isSendEmailError) {
      PopUpAlert("error", tGeneral("error"), sendEmailError.message);
      setSendingRfis(null);
    }
  }, [isSendEmailError, isSendEmailSuccess, sendEmailError, tGeneral]);

  const handleSendEmail = async (
    selectedRfi,
    message,
    subject,
    recipients,
    cc,
  ) => {
    const body = {
      message,
      subject,
      module: "rfi",
    };
    if (recipients?.length > 0) {
      body.recipients = recipients;
    }
    if (cc.length > 0) {
      body.cc = cc;
    }
    sendMail({
      idEnterprise: currentEnterprise?.id,
      idProject,
      idObject: selectedRfi[0].id,
      body,
    });
  };

  return (
    <>
      <StyledMaterialReactTable
        columns={columns}
        data={rfis}
        isLoading={isLoading}
        selectedRows={selectedRfis}
        setSelectedRows={setSelectedRfis}
        filtersComponent={
          <RfiFilters
            onChange={(val) => setFilters(val)}
            disableSearch={isRecycle}
          />
        }
        enableRowSelection
        muiSelectAllCheckboxProps={{ class: "checkbox-recycle" }}
        renderRowActions={({ row }) =>
          row.original.bDraft &&
          (ability.can("editDraft", "rfis") ||
            row.original.createdBy === currentUser.id) ? (
            <Button
              sx={{
                color: "black",
                backgroundColor: "white",
                border: "1px solid black",
                fontSize: "1rem",
                ":hover": {
                  color: "white",
                  backgroundColor: "#b4b4b4",
                },
              }}
              onClick={() => navigate(`form/${row.id}`)}
              data-tour="edit-rfi-1"
            >
              {tGeneral("edit")}
            </Button>
          ) : (
            <Box display="flex">
              {!isRecycle &&
                (ability.can("superAdmin", "rfis") ||
                  (ability.can("edit", "rfis") &&
                    row.original.createdBy === currentUser.id)) && (
                  <TooltipIconButton
                    size="small"
                    icon={<Edit fontSize="small" />}
                    label={tGeneral("edit")}
                    onClick={() => navigate(`form/${row.id}`)}
                    color="black"
                    data-tour="edit-rfi-1"
                  />
                )}
              <Can I="view" a="rfis">
                <TooltipIconButton
                  size="small"
                  icon={<RemoveRedEye fontSize="small" />}
                  label={tGeneral("seeDetails")}
                  onClick={() => navigate(`./${row.id}`)}
                  color="black"
                  data-tour={isRecycle ? "recycle-rfi-3" : "view-rfi-1"}
                />
              </Can>
              {!isRecycle && (
                <TooltipIconButton
                  size="small"
                  icon={<Email fontSize="small" />}
                  label={tGeneral("email")}
                  onClick={() => setSendingRfis([row.original])}
                  color="black"
                />
              )}
            </Box>
          )
        }
        emptyStateMessage={tGeneral("noElements", {
          moduleName: tRfis("rfi_title_module").toLowerCase(),
        })}
      />
      {Boolean(sendingRfis) && (
        <SendToEmail
          onClose={() => setSendingRfis(null)}
          isOpen={Boolean(sendingRfis)}
          title={
            sendingRfis?.length > 1
              ? `${tGeneral("rfis")} (${sendingRfis?.length})`
              : sendingRfis[0]?.title
          }
          onSend={(message, subject, recipients, cc) => {
            handleSendEmail(sendingRfis, message, subject, recipients, cc);
          }}
          isLoading={isSendMailLoading || isLoadingPDF}
        />
      )}
    </>
  );
}

export { RFITable };
