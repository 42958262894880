import {
  CheckCircle,
  Close,
  ExpandMore,
  InsertDriveFile,
  WarningAmber,
  Autorenew,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { v4 } from "uuid";
import {
  useGetPresignUrlsMutation,
  useAddDrawingV2Mutation,
} from "../../../features/drawings";
import {
  selectUploadingFiles,
  setUploadingFiles,
} from "../../../features/drawings/drawingsSlice";
import { TooltipIconButton } from "../../shared";
import {
  useAbortMultipartUploadMutation,
  useCompleteMultipartUploadMutation,
  useContinueMultipartUploadMutation,
  useCreatePresignedUrlMutation,
  useInitiateMultipartUploadMutation,
} from "../../../features/s3/s3ApiSlice";
import { useCreateDocumentMutation } from "../../../features/project/modules/documents/documentsApiSlice";
import { PopUpAlert } from "../../PopUpAlert";
import {
  useCreateImageFromUrlMutation,
  useUploadThumbnailMutation,
} from "../../../features/project/imageApiSlice";
import { getVideoThumbnail } from "../../../utils/video";

function UploadDrawingsModal() {
  const { idProject, idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errorFiles, setErrorFiles] = useState([]);
  const uploadingDrawings = useSelector(selectUploadingFiles);

  const [createPresignedUrl] = useCreatePresignedUrlMutation();
  const [createDocument] = useCreateDocumentMutation();
  const [getPresignUrls] = useGetPresignUrlsMutation();
  const [addDrawingV2] = useAddDrawingV2Mutation();
  const [createImage] = useCreateImageFromUrlMutation();
  const [uploadThumbnail] = useUploadThumbnailMutation();

  const [initiateMultipartUpload] = useInitiateMultipartUploadMutation();
  const [continueMultipartUpload] = useContinueMultipartUploadMutation();
  const [completeMultipartUpload] = useCompleteMultipartUploadMutation();
  const [abortMultipartUpload] = useAbortMultipartUploadMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadingDrawings) {
      const newFiles = [...uploadingDrawings];
      setFiles((prev) => [
        ...prev.map((f) => ({ ...f, processed: true })),
        ...newFiles.map((file) => ({
          ...file,
          id: v4(),
        })),
      ]);
      dispatch(setUploadingFiles(null));
    }
  }, [uploadingDrawings, dispatch, setFiles]);

  const uploadDrawing = useCallback(
    async (files) => {
      const batchId = v4();
      const newFiles = [...files].filter((f) => !f.processed);

      const timestampStr = new Date().toISOString().replace(/[-:.]/g, "");

      const fileMeta = newFiles.map((file) => ({
        idProject,
        idEnterprise,
        filename: `${timestampStr}___${file.file.name}`,
        id: file.id,
        moduleName: "drawings",
        batchId,
      }));
      const nonDwgFile = [];
      const { uploadUrls } = await getPresignUrls(fileMeta).unwrap();
      newFiles.map(async (file, idx) => {
        try {
          const uploadUrl = uploadUrls[idx];
          const uploadStatus = await fetch(uploadUrl, {
            method: "PUT",
            signal: AbortSignal.timeout(30000),
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: file.file,
          });
          if (!uploadStatus.status === 200) {
            setErrorFiles((prev) => [...prev, file.id]);
          } else {
            if (!file.file.name.endsWith(".dwg")) {
              nonDwgFile.push({
                pdfFile: uploadUrl.split("?")[0],
                drawingFile: null,
                idProject,
              });
            }
            setUploadedFiles((prev) => [...prev, file.id]);
          }
        } catch (e) {
          setErrorFiles((prev) => [...prev, file.id]);
        }
      });

      if (nonDwgFile.length > 0) await addDrawingV2(nonDwgFile);
    },
    [getPresignUrls, addDrawingV2, idProject, idEnterprise],
  );

  const uploadDocument = useCallback(
    async (file) => {
      try {
        const fnCallBack = async (presignedUrl) => {
          const requestBody = {
            idProject,
            url: presignedUrl.split("?")[0],
            name: file.file.name,
            size: (file.file.size / 1000000).toFixed(2),
            versionNo: 1,
            allowedUsers: file.allowedUsers ?? [],
            allowedCompanies: file.allowedCompanies ?? [],
            bActive: true,
            bPrivate: file.bPrivate,
            idProjectFolder: file.idProjectFolder,
          };

          const res = await createDocument(requestBody);
          if (res?.error) {
            setErrorFiles((prev) => [...prev, file.id]);
          } else {
            setUploadedFiles((prev) => [...prev, file.id]);
          }
        };

        if (file.file.size / 1000000 > 100) {
          const partSize = 100 * 1024 * 1024;
          const numParts = Math.ceil(file.file.size / partSize);

          let uploadId;
          let key;
          try {
            const responseS3 = await initiateMultipartUpload({
              idEnterprise,
              idProject,
              moduleName: "Documents",
              name: file.file.name,
            }).unwrap();

            key = responseS3.key;
            uploadId = responseS3.uploadId;

            const parts = [];

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < numParts; i++) {
              const start = i * partSize;
              const end = Math.min(file.file.size, start + partSize);
              const partData = file.file.slice(start, end);
              const partNumber = i + 1;

              // eslint-disable-next-line no-await-in-loop
              const formData = new FormData();
              formData.append("key", key);
              formData.append("uploadId", uploadId);
              formData.append("partNumber", partNumber);
              formData.append("partData", partData);

              // eslint-disable-next-line no-await-in-loop
              const part = await continueMultipartUpload(formData).unwrap();
              parts.push(part);
            }

            const presignedUrl = await completeMultipartUpload({
              key,
              uploadId,
              parts,
            }).unwrap();

            fnCallBack(presignedUrl);
          } catch (error) {
            if (uploadId) {
              await abortMultipartUpload({ key, uploadId });
              throw new Error(error);
            }
          }
        } else {
          const presignedUrl = await createPresignedUrl({
            idEnterprise,
            idProject,
            moduleName: "Documents",
            name: file.file.name,
          }).unwrap();

          await fetch(presignedUrl, {
            method: "PUT",
            signal: AbortSignal.timeout(30000),
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: file.file,
          });

          fnCallBack(presignedUrl);
        }
      } catch (e) {
        setErrorFiles((prev) => [...prev, file.id]);
      }
    },
    [
      createDocument,
      createPresignedUrl,
      idEnterprise,
      idProject,
      abortMultipartUpload,
      completeMultipartUpload,
      initiateMultipartUpload,
      continueMultipartUpload,
    ],
  );

  const uploadImage = useCallback(
    async (file) => {
      try {
        const fnCallBack = async (presignedUrl) => {
          const isVideo = file.file.type.split("/")[0] === "video";
          let thumbnailUrl;
          if (isVideo) {
            const thumbnail = await getVideoThumbnail(file.file);
            const formData = new FormData();
            formData.append("files", thumbnail);
            const thumbnailRes = await uploadThumbnail(formData);
            if (thumbnailRes.error) {
              throw new Error(thumbnailRes.error);
            } else {
              thumbnailUrl = thumbnailRes.data.url;
            }
          }
          const requestBody = {
            idProject: file.idProject,
            location: file.selectedLocation,
            album: file.selectedAlbum,
            name: file.file.name,
            url: presignedUrl.split("?")[0],
            thumbnailUrl,
            size: file.file.size.toString(),
            type: isVideo ? "video" : "image",
          };

          const res = await createImage(requestBody);
          if (res?.error) {
            setErrorFiles((prev) => [...prev, file.id]);
          } else {
            setUploadedFiles((prev) => [...prev, file.id]);
          }
        };

        if (file.file.size / 1000000 > 100) {
          const partSize = 100 * 1024 * 1024;
          const numParts = Math.ceil(file.file.size / partSize);

          let uploadId;
          let key;
          try {
            const responseS3 = await initiateMultipartUpload({
              idEnterprise,
              idProject,
              moduleName: "Image",
              name: file.file.name,
            }).unwrap();

            key = responseS3.key;
            uploadId = responseS3.uploadId;

            const parts = [];

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < numParts; i++) {
              const start = i * partSize;
              const end = Math.min(file.file.size, start + partSize);
              const partData = file.file.slice(start, end);
              const partNumber = i + 1;

              // eslint-disable-next-line no-await-in-loop
              const formData = new FormData();
              formData.append("key", key);
              formData.append("uploadId", uploadId);
              formData.append("partNumber", partNumber);
              formData.append("partData", partData);

              // eslint-disable-next-line no-await-in-loop
              const part = await continueMultipartUpload(formData).unwrap();
              parts.push(part);
            }

            const presignedUrl = await completeMultipartUpload({
              key,
              uploadId,
              parts,
            }).unwrap();

            fnCallBack(presignedUrl);
          } catch (error) {
            if (uploadId) {
              await abortMultipartUpload({ key, uploadId });
              throw new Error(error);
            }
          }
        } else {
          const presignedUrl = await createPresignedUrl({
            idEnterprise,
            idProject,
            moduleName: "Image",
            name: file.file.name,
          }).unwrap();

          await fetch(presignedUrl, {
            method: "PUT",
            signal: AbortSignal.timeout(30000),
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: file.file,
          });

          fnCallBack(presignedUrl);
        }
      } catch (e) {
        setErrorFiles((prev) => [...prev, file.id]);
      }
    },
    [
      createImage,
      uploadThumbnail,
      createPresignedUrl,
      idEnterprise,
      idProject,
      abortMultipartUpload,
      completeMultipartUpload,
      initiateMultipartUpload,
      continueMultipartUpload,
    ],
  );

  const startUpload = useCallback(async () => {
    const newFiles = [...files].filter((f) => !f.processed);
    const drawingFiles = newFiles.filter((f) => f.type === "drawing");
    const documentFiles = newFiles.filter((f) => f.type === "document");
    const imageFiles = newFiles.filter((f) => f.type === "image");
    if (drawingFiles.length > 0) {
      await uploadDrawing(drawingFiles);
    }
    if (documentFiles.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of documentFiles) {
        if (!errorFiles.includes(file.id)) {
          // eslint-disable-next-line no-await-in-loop
          await uploadDocument(file);
        }
      }
    }
    if (imageFiles.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of imageFiles) {
        if (!errorFiles.includes(file.id)) {
          // eslint-disable-next-line no-await-in-loop
          await uploadImage(file);
        }
      }
    }

    if (errorFiles.length > 0) {
      PopUpAlert("error", tGeneral("Error"), tGeneral("errorUploadMessage"));
    }
  }, [errorFiles, files, tGeneral, uploadDocument, uploadDrawing, uploadImage]);

  const allUploaded = useMemo(
    () =>
      files.every(
        (file) =>
          uploadedFiles.indexOf(file.id) > -1 ||
          errorFiles.indexOf(file.id) > -1,
      ),
    [files, uploadedFiles, errorFiles],
  );

  useEffect(() => {
    if (files.length && !allUploaded) {
      startUpload();
    }
  }, [allUploaded, files, startUpload]);

  const handleClose = (e) => {
    e.stopPropagation();
    setFiles([]);
    setErrorFiles([]);
  };

  const getStatusIcon = (id) => {
    if (uploadedFiles.indexOf(id) > -1) {
      return <CheckCircle sx={{ color: "green" }} />;
    }
    if (errorFiles.indexOf(id) > -1) {
      return <WarningAmber sx={{ color: "#FFC62B" }} />;
    }
    return <CircularProgress size={15} />;
  };

  if (files.length === 0) return null;

  return (
    <Accordion
      sx={{
        zIndex: 500,
        position: "absolute",
        bottom: 0,
        right: 0,
        width: "400px",
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
      }}
      defaultExpanded
    >
      <AccordionSummary
        sx={{
          backgroundColor: "#F5F5F5",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
        }}
        expandIcon={<ExpandMore />}
      >
        <Box display="flex" alignItems="center" width="100%">
          <Typography>
            {tGeneral("uploadingQtyFiles", {
              qty: files.length,
            })}
          </Typography>
          <Box flex={1} />
          <TooltipIconButton
            icon={<Close />}
            label={tGeneral("close")}
            onClick={handleClose}
            disabled={!allUploaded}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          flexDirection="column"
          maxHeight="300px"
          sx={{ overflowY: "auto", overflowX: "hidden" }}
          rowGap="0.5rem"
        >
          {files.map((file) => (
            <Box
              display="flex"
              alignItems="center"
              height="1.5rem"
              columnGap="0.5rem"
              p="0.25rem 1rem"
            >
              <InsertDriveFile sx={{ color: "#E6B60C" }} />
              <Tooltip title={file.file.name}>
                <Typography
                  noWrap
                  sx={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {file.file.name}
                </Typography>
              </Tooltip>
              {errorFiles.indexOf(file.id) > -1 && (
                <Tooltip title={tGeneral("retry")}>
                  <IconButton
                    aria-label="retry"
                    size="small"
                    sx={{ height: "20px", width: "20px" }}
                    onClick={() => {
                      setErrorFiles((prev) =>
                        prev.filter((x) => x !== file.id),
                      );
                    }}
                  >
                    <Autorenew />
                  </IconButton>
                </Tooltip>
              )}
              {getStatusIcon(file.id)}
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export { UploadDrawingsModal };
