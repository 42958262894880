import { useEffect, useState } from "react";
// MUI
import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
// Redux & RTK Query
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useGetProjectConfigurationQuery,
  useUpdateProjectConfigMutation,
} from "../../../features/project/projectApiSlice";

function TaskCategorySelect({
  selectedCategory,
  onChange = () => {},
  margin,
  error,
  helperText,
}) {
  // Translation
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");

  const { idProject } = useParams();

  const [newCategory, setNewCategory] = useState(null);
  const [editingCategoryTarget, setEditingCategoryTarget] = useState(null);
  const [editingCategoryNewValue, setEditingCategoryNewValue] = useState(null);
  const [focusedCategory, setFocusedCategory] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = (event, category) => {
    setMenuAnchorEl(event.currentTarget);
    setFocusedCategory(category);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setFocusedCategory(null);
  };

  // Data fetching queries
  const { data: projectConfig } = useGetProjectConfigurationQuery(idProject);
  // Mutations
  const [updateProjectConfig, { isLoading }] = useUpdateProjectConfigMutation();

  const handleAddCategory = async () => {
    await updateProjectConfig({
      idProject,
      taskCategoryList: [
        ...(projectConfig?.taskCategoryList ?? []),
        newCategory,
      ],
    });
    setNewCategory(null);
  };
  const handleEditCategory = async () => {
    await updateProjectConfig({
      idProject,
      taskCategoryList: projectConfig?.taskCategoryList?.map((category) => {
        if (category === editingCategoryTarget) {
          return editingCategoryNewValue;
        }
        return category;
      }),
    });
    setEditingCategoryTarget(null);
    setEditingCategoryNewValue(null);
  };
  const handleDeleteCategory = async () => {
    await updateProjectConfig({
      idProject,
      taskCategoryList: projectConfig?.taskCategoryList?.filter(
        (category) => category !== focusedCategory,
      ),
    });
    setFocusedCategory(null);
  };
  // Menu options
  const menuOptions = [
    {
      title: tGeneral("edit"),
      icon: <Edit fontSize="small" />,
      onClick: () => {
        setEditingCategoryTarget(focusedCategory);
        setEditingCategoryNewValue(focusedCategory);
      },
    },
    {
      title: tGeneral("delete"),
      icon: <Delete fontSize="small" />,
      onClick: handleDeleteCategory,
    },
  ];

  const isCategoryAdding = newCategory !== null;

  useEffect(() => {
    if (
      projectConfig?.taskCategoryList?.indexOf(selectedCategory) === -1 &&
      selectedCategory !== ""
    ) {
      onChange("");
    }
  }, [projectConfig?.taskCategoryList, onChange, selectedCategory]);

  return (
    <FormControl
      error={error}
      fullWidth
      margin={margin || "auto"}
      data-tour="add-inspection-templates-6"
    >
      <InputLabel id="category-select-label">{tTasks("category")}</InputLabel>
      <Select
        labelId="category-select-label"
        MenuProps={{ sx: { maxHeight: "30%" } }}
        id="category-select"
        value={selectedCategory || ""}
        label="Category"
        onClose={() => {
          setEditingCategoryTarget(null);
          setEditingCategoryNewValue(null);
        }}
        onChange={(event) => {
          onChange(event?.target?.value?.toString() ?? "");
        }}
        renderValue={(value) => <Typography>{value}</Typography>}
      >
        {projectConfig?.taskCategoryList?.length > 0 &&
          projectConfig?.taskCategoryList?.map((category) => (
            <MenuItem key={category} value={category}>
              {editingCategoryTarget === category ? (
                <OutlinedInput
                  fullWidth
                  value={editingCategoryNewValue}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => setEditingCategoryNewValue(e.target.value)}
                  size="small"
                  endAdornment={
                    <Button
                      onClick={handleEditCategory}
                      variant="contained"
                      size="small"
                    >
                      {isLoading ? (
                        <CircularProgress color="secondary" size="1.2rem" />
                      ) : (
                        tGeneral("edit")
                      )}
                    </Button>
                  }
                />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  {category}
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMenuOpen(e, category);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Box>
              )}
            </MenuItem>
          ))}
        <Box display="flex" columnGap="10px" px="10px">
          {isCategoryAdding ? (
            <OutlinedInput
              fullWidth
              value={newCategory}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => setNewCategory(e.target.value)}
              size="small"
              autoFocus
              endAdornment={
                <Button
                  onClick={handleAddCategory}
                  variant="contained"
                  size="small"
                >
                  {isLoading ? (
                    <CircularProgress color="secondary" size="1.2rem" />
                  ) : (
                    tGeneral("add")
                  )}
                </Button>
              }
            />
          ) : (
            <Button
              variant="text"
              startIcon={<Add />}
              fullWidth
              style={{
                justifyContent: "flex-start",
                paddingLeft: "3%",
                color: "black",
              }}
              onClick={() => setNewCategory("")}
            >
              {tTasks("addCategory")}
            </Button>
          )}
        </Box>
        <Menu
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          {menuOptions.map((option) => (
            <MenuItem
              key={option.title}
              onClick={() => {
                option.onClick();
                handleMenuClose();
              }}
            >
              {option.icon}
              <Typography fontSize={12} fontWeight="bold" sx={{ mx: 1 }}>
                {option.title}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default TaskCategorySelect;
