import React from "react";
import Swal from "sweetalert2";
// MUI Components

function PopUpAlert(icon, title, text, timer = 3000) {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer,
    timerProgressBar: true,
  });

  Toast.fire({
    icon,
    title,
    text,
  });
  return <></>;
}

export { PopUpAlert };
